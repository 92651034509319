function getStatusDisplay(status) {
    let statusDisplay;
    switch (status) {
        case "CANCELED":
            statusDisplay = "Canceled";
            break;
        case "ACCOUNTS_UPDATED":
            statusDisplay = "Acc Updated";
            break;
        case "INITIATED":
            statusDisplay = "Initiated";
            break;
        case "RECEIVE_CONFIRMED":
            statusDisplay = "Receive Confirmed";
            break;
        case "COMPLETED":
        case "Completed":
            statusDisplay = "Completed";
            break;
        case "DELETED":
            statusDisplay = "Deleted";
            break;
        case "TRUSTEE_SELECTED":
            statusDisplay = "Trustee Selected";
            break;
        case "TRUSTEE_CONFIRMED":
            statusDisplay = "Trustee Confirmed";
            break;
        case "WAITING_FOR_APPROVAL":
            statusDisplay = "Waiting for Approval";
            break;
        case "APPROVED":
            statusDisplay = "Approved";
            break;
        case "ADMIN_APPROVED":
            statusDisplay = "Admin Approved";
            break;
        case "TRANSACTION_REQUESTED":
            statusDisplay = "Transaction Requested";
            break;
        case "FIRST_PARTY_CONFIRMED":
            statusDisplay = "First Party Confirmed";
            break;
        default:
            statusDisplay = status;
    }
    return statusDisplay;
}

export default getStatusDisplay;
