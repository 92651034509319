import axios from "axios";
import { authHeader } from "./auth-header";
import { BASE_URL } from "@/config/endpoints";

class CountryService {
  async getCountries() {
    try {
      return await axios.get(BASE_URL + "country/getAll", {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }
}

export default new CountryService();
