<script>
export default {
  name: "Success_Modal.vue",
  props: {
    message: {
      type: String,
    },
    success: {
      type: Boolean,
    },
  },
  computed:{
    getSuccess(){
      return this.success
    }
  }
}
</script>

<template>
  <v-dialog v-model="getSuccess">
    <div class="success-dialog">
      <div class="success-left">
        <div class="green-circle">
          <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M50 100 L80 130 L150 50"
                stroke="white"
                stroke-width="25"
                fill="transparent"
                class="success-tick"
            />
          </svg>
        </div>
      </div>
      <div class="success-right">
          <span
              class="close-icon"
              @click="this.$emit('closeSuccessModal')"
              style="text-align: right"
          >✖
          </span>
        <h3 class="font-weight-bold">Success</h3>
        <p class="success-message">{{ message }}</p>
        <button class="success-close-button" @click="this.$emit('closeSuccessModal')">
          Close
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped>

</style>