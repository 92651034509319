export const ExchangeRateOption = {
    preProcess: (val) => {
        if (val === "0") {
            return "0";
        } else if (val === ".") {
            return "0.";
        } else {
            val = val.replace(/[^0-9.]/g, "");
            val = val.replace(/^0+(?!\.)/, "");
            return val;
        }
    },
    postProcess: (val) => {
        if (!val) return "";
        const parts = val.toString().split(".");
        if (parts[1]) parts[1] = parts[1].slice(0, 9);
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        let output = parts.join(".");
        if (parts.length > 2) {
            return output.slice(0, output.length - 1);
        }
        return output;
    },
};

export const MoneyOption = (symbol) => {
    return {
        symbol: symbol,
        preProcess: (val) => val.replace(/[^0-9.]/g, "").replace(/^(0+)(?=\d)/, ""),
        postProcess: (val) => {
            if (!val) return "";
            const parts = val.toString().split(".");
            if (parts[1]) parts[1] = parts[1].slice(0, 2);
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            let output = parts.join(".");
            if (parts.length > 2) {
                return output.slice(0, output.length - 1);
            }
            if (symbol) {
                return output + " " + symbol;
            } else {
                return output;
            }
        },
    };
};

export const PercentageOption = {
    preProcess: (val) => {
        if (val === "0") {
            return "0";
        } else if (val === ".") {
            return "0.";
        } else {
            val = val.replace(/[^0-9.]/g, "");
            val = val.replace(/^0+(?!\.)/, "");
            return val;
        }
    },
    postProcess: (val) => {
        if (!val) return "";
        var parts = val.toString().split(".");
        if (parts[1]) parts[1] = parts[1].slice(0, 2);
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let output = parts.join(".");
        if (parts.length > 2) {
            return output.slice(0, output.length - 1);
        }
        return output + "%";
    },
};

