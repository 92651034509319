import axios from "axios";
import {authFormDataHeader, authHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";

class CryptoService {
    async getCryptoDeal(page, size, searchParam) {
        try {
            const response = await axios.get(BASE_URL + "cryptoDeal/getAll", {
                params: {
                    page: page,
                    size: size,
                    accountCofferName: searchParam
                },
                headers: authHeader(),
            });
            return {status: response.status, data: response.data, message: null};
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async createCryptoDeal(form) {
        try {
            return await axios.post(BASE_URL + "cryptoDeal/create", form, {
                headers: authHeader(),
            });
        } catch (error) {
            return {success: false, data: null, message: error.message};
        }
    }

    async setCryptoDealReceiveSide(
        cryptoDealId,
        ellingtonReceiveAccountId,
        payerAccountId,
        ellingtonReceivePAId,
        counterPartyReceivePAId
    ) {
        try {
            return await axios.post(
                BASE_URL + "cryptoDeal/setReceiveSideInfo",
                {
                    cryptoDealId,
                    ellingtonReceiveAccountId,
                    payerAccountId,
                    ellingtonReceivePAId,
                    counterPartyReceivePAId,
                },
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async setCryptoDealSendSide(cryptoDealId, ellingtonSendAccountId, payeeAccountId, ellingtonSendPAId, counterPartySendPAId) {
        try {
            return await axios.post(BASE_URL + "cryptoDeal/setSendSideInfo", {
                    cryptoDealId,
                    ellingtonSendAccountId,
                    payeeAccountId,
                    ellingtonSendPAId,
                    counterPartySendPAId,
                },
                {headers: authFormDataHeader()});
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getCryptoDealById(cryptoDealId) {
        try {
            return await axios.get(BASE_URL + "cryptoDeal/get", {
                params: {cryptoDealId: cryptoDealId},
                headers: authFormDataHeader()
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async completeCryptoDeal(cryptoDealId) {
        try {
            return await axios.post(BASE_URL + "cryptoDeal/complete",
                {cryptoDealId: cryptoDealId},
                {headers: authFormDataHeader()});
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async requestForPastOperation(form) {
        try {
            return await axios.post(BASE_URL + "cryptoDeal/executePastOperation", form, {
                headers: authHeader(),
            });
        } catch (error) {
            return {success: false, data: null, message: error.message};
        }
    }

    async cancelCryptoDeal(cryptoDealId) {
        try {
            return await axios.post(BASE_URL + "cryptoDeal/cancel",
                {cryptoDealId: cryptoDealId},
                {headers: authFormDataHeader()});
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getAllIncomplete(page, size) {
        try {
            return await axios.get(BASE_URL + "cryptoDeal/getAllIncomplete", {
                params: {
                    page: page,
                    size: size,
                },
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }
}

export default new CryptoService();
