<template>
  <v-sheet color="surface" style="width: 500px">
    <v-form ref="new_general_ledger_form" class="adjust-label">
      <v-container>
        <v-row class="mt-4">
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-text-field
              v-model="code"
              :rules="requiredRule"
              label="Code"
              required
            ></v-text-field>
            <v-text-field
              v-model="label"
              label="Label"
              :rules="requiredRule"
            ></v-text-field>
            <v-text-field
              v-model="parentAccount"
              label="Parent Account"
            ></v-text-field>
            <v-text-field
              v-model="accountGroup"
              label="Account Group"
            ></v-text-field>
            <v-text-field
              :rules="positiveIntegerRule"
              v-model="active"
              label="Active"
            ></v-text-field>
            <v-row justify="end">
              <v-btn color="button_color" @click="addGeneralLedger">
                Add General Ledger
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <!-- <v-dialog @click="success = false" v-model="success" width="auto">
      <v-alert type="success"> {{ message }} </v-alert>
    </v-dialog>
    <v-dialog v-model="error" width="auto">
      <v-alert @click="error = false" type="error"> {{ message }} </v-alert>
    </v-dialog> -->
    <v-dialog v-model="success">
        <div class="success-dialog">
          <div class="success-left">
            <div class="green-circle">
              <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 100 L80 130 L150 50" stroke="white" stroke-width="25" fill="transparent" class="success-tick" />
          </svg>
        </div>      
          </div>
          <div class="success-right">
          <span class="close-icon" @click="success = false" style="text-align: right;">✖</span>
          <h4>Success</h4>
        <p class="success-message"> {{ message }}</p>
        <button class="success-close-button" @click="success = false">Close</button>
          </div>
        </div>
    </v-dialog>
    
    <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>      
      </div>
      <div class="error-right">
      <span class="close-icon" @click="error = false" style="text-align: right;">✖</span>
      <h4>Error</h4>
    <p class="error-message">{{ message }}</p>
    <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
</v-dialog>
  </v-sheet>
</template>
<script>
import general_ledgerService from "@/services/general_ledger.service";
import { messageResultHandler } from "@/utils/helpers";
import { requiredRule, positiveIntegerRule } from "@/utils/rules.js";
import emitter from "@/plugins/mitt";

export default {
  name: "new_general_ledger_modal_v01",

  data() {
    return {
      success: false,
      error: false,
      message: null,
      requiredRule: requiredRule,
      code: null,
      label: null,
      parentAccount: null,
      accountGroup: null,
      active: null,
      positiveIntegerRule: positiveIntegerRule,
    };
  },
  methods: {
    async addGeneralLedger() {
      const { valid } = await this.$refs.new_general_ledger_form.validate();
      if (valid) {
        let form = {
          code: this.code,
          label: this.label,
          parentAccount: this.parentAccount,
          accountGroup: this.accountGroup,
          active: this.active,
        };
        let response = messageResultHandler(
          await general_ledgerService.addGeneralLedger(form)
        );
        this.message = response.message;
        if (response.success) {
          emitter.emit("new_general_ledger", response);
        } else {
          this.error = true;
        }
      }
    },
  },
  mounted() {},
  beforeUnmount() {},
  watch: {},
  computed: {},
};
</script>
