import Vuex from "vuex";
import globalStore from "./modules/globalStore.js"
export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        globalStore: globalStore,
    }
})