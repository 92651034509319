<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium">Crypto Report</h3>
    <v-row class="mb-2">
      <v-col cols="12" md="6" sm="8">
        <div class="search-bar">
          <v-text-field
              class="adjust-label search-field"
              label="Search"
              v-model="searchValue"
              @keyup.enter="searchForItem(searchValue)"
          >
            <template v-slot:prepend-inner>
              <v-icon
                  icon="mdi-magnify"
                  variant="tonal"
                  color="button_color"
                  class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
          <v-checkbox
              class="ml-5"
              v-model="openRequests"
              color="button_color"
              label="Open Requests"
              @change="getRequests"
          ></v-checkbox>
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
            color="button_color"
            @click="QCryptoDealModal = true"
            icon="mdi-help"
            class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="cryptoDetails">
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
        :headers="headers"
        :items="tableItems"
        v-model:serverOptions="serverOptions"
        :server-items-length="totalItems"
        :loading="loading"
        body-text-direction="center"
        header-text-direction="center"
        class="adjust-label custom-data-table"
        border-cell
        :body-row-class-name="bodyRowClassNameFunction"
        no-hover
    >
      <template #pagination="{}">
        <v-btn
            density="compact"
            class="ma-4"
            :disabled="serverOptions.page === 1"
            @click="goToPrevPage"
        >prev page
        </v-btn>
        <v-btn
            density="compact"
            class="ma-4"
            :disabled="serverOptions.page === numberOfPages"
            @click="goToNextPage"
        >next page
        </v-btn>
      </template>

      <template #item-id="item">
        <v-icon @click="gotToRequest(item)"
        ><span class="modal-icon">{{ item.id }}</span></v-icon
        >
      </template>

      <template #item-sold="{ sold }">
        <div class="currency-amount">
          <span class="currency">{{ splitCurrencyValue(sold).currency }}</span>
          <span class="text-right">{{ splitCurrencyValue(sold).value }}</span>
        </div>
      </template>

      <template #item-bought="{ bought }">
        <div class="currency-amount">
          <span class="currency">{{
              splitCurrencyValue(bought).currency
            }}</span>
          <span class="text-right">{{ splitCurrencyValue(bought).value }}</span>
        </div>
      </template>

      <template #item-update="item">
        <Toggle_Confirmation
            @editTransfer="goToEdit(item)"
            @acceptTransfer="acceptTransfer(item)"
            @denyTransfer="denyTransfer(item)"
            :item="item"
            :action="action"
        >
        </Toggle_Confirmation>
      </template>
    </EasyDataTable>
  </v-container>

  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>

  <v-dialog v-model="cryptoDealModal" width="auto">
    <Crypto_Deal_Modal_V01></Crypto_Deal_Modal_V01>
  </v-dialog>

  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>

  <v-dialog v-model="QCryptoDealModal" width="auto">
    <Q_CryptoDeal_Modal_V01></Q_CryptoDeal_Modal_V01>
  </v-dialog>
</template>
<script>
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import Q_CryptoDeal_Modal_V01 from "@/components/ModalQ/Q_CryptoDeal_Modal_V01.vue";
import cryptoService from "@/services/crypto.service";
import Crypto_Deal_Modal_V01 from "../../Modals/transfer/Crypto_Deal_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import {
  setPage,
  pageResponseHandler,
  messageResultHandler,
  formatNumber,
} from "@/utils/helpers";
import {mapGetters} from "vuex";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Toggle_Confirmation from "@/components/MoneyTransfer/Toggle_Confirmation.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import getStatusDisplay from "@/utils/NewFunctions/getStatusDisplay";

export default {
  name: "crypto_deal_list_v01",
  components: {
    Error_Modal,
    Toggle_Confirmation,
    Success_Modal,
    Crypto_Deal_Modal_V01,
    Q_CryptoDeal_Modal_V01,
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        {text: "Event", value: "id", width: 50},
        {text: "Third Party", value: "thirdParty"},
        {text: "Bought", value: "bought"},
        {text: "Exchange Rate", value: "exchangeRate"},
        {text: "Sold", value: "sold"},
        {text: "Status", value: "status"},
        {text: "Modified Date", value: "modifiedDate"},
        {text: "User", value: "user"},
        {text: "Update", value: "update"},
      ],
      message: null,
      broken_page: false,
      success: false,
      error: false,
      action: false,
      cryptoItems: [],
      tableItems: [],
      totalItems: 0,
      accountCofferName: null,
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
      loading: false,
      statusCriteria: "ALL",
      showStatusFilter: false,
      cryptoDealModal: false,
      QCryptoDealModal: false,
      showConfirmationModal: false,
      searchField: "",
      searchValue: "",
      openRequests: false,
      debounceTimer: null,
    };
  },

  methods: {
    bodyRowClassNameFunction(item) {
      switch (item.status) {
        case "Completed":
          return "row-completed";
        case "Canceled":
          return "row-canceled";
        case "Initiated":
          return "row-process";
        case "Admin Approved":
        case "Waiting for Approved":
        case "Waiting for Approval":
        case "Commission Add":
        case "Receive Confirmed":
        case "Trustee Selected":
        case "Trustee Confirmed":
        case "Transaction Requested":
          return "row-other-status";
        case "Approved":
          return "row-approved";
        default:
          return "";
      }
    },
    formatNumber,
    splitCurrencyValue(currencyString) {
      const parts = currencyString.split(" ");
      return {
        currency: parts[0],
        value: parts[1],
      };
    },
    goToPrevPage() {
      this.serverOptions.page = this.serverOptions.page - 1;
    },
    goToNextPage() {
      this.serverOptions.page = this.serverOptions.page + 1;
    },
    searchForItem(item) {
      this.accountCofferName = item;
      if (this.serverOptions.page === 1) {
        this.getRequests();
      } else {
        this.serverOptions.page = 1;
      }
    },
    async goToEdit(item) {
      let response = messageResultHandler(
          await cryptoService.getCryptoDealById(item.id)
      );
      if (response.success) {
        let requestToSet = response.data;
        this.$store.dispatch("setChosenCryptoDeal", requestToSet);
        this.$store.dispatch("setIsEditingCryptoDeal", true);
        setPage("crypto deal");
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async gotToRequest(item) {
      let response = messageResultHandler(
          await cryptoService.getCryptoDealById(item.id)
      );
      if (response.success) {
        let requestToSet = response.data;
        this.$store.dispatch("setChosenCryptoDeal", requestToSet);
        this.cryptoDealModal = true;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async getRequests() {
      let response;
      this.loading = true;
      if (!this.openRequests) {
        response = pageResponseHandler(
            await cryptoService.getCryptoDeal(
                this.serverOptions.page - 1,
                this.serverOptions.rowsPerPage,
                this.accountCofferName
            )
        );
      } else {
        response = pageResponseHandler(
            await cryptoService.getAllIncomplete(
                this.serverOptions.page - 1,
                this.serverOptions.rowsPerPage,
                this.accountCofferName
            )
        );
        this.showConfirmationModal = true;
      }
      console.log("response", response.data);
      if (response.success) {
        this.totalItems = response.totalItems;
        this.numberOfPages = response.numberOfPages;
        this.cryptoItems = response.data;
        this.tableItems = this.cryptoDetails;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    async acceptTransfer(item) {
      this.action = true;
      let response = messageResultHandler(
          await cryptoService.completeCryptoDeal(item.id)
      );
      this.action = false;
      if (response.success) {
        item.status = "Acc Updated";
        let index = this.tableItems.findIndex(
            (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async denyTransfer(item) {
      this.action = true;
      let response = messageResultHandler(
          await cryptoService.cancelCryptoDeal(item.id)
      );
      this.action = false;
      if (response.success) {
        item.status = "Canceled";
        let index = this.tableItems.findIndex(
            (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },
  async mounted() {
    emitter.on("crypto_deal_modal_close", (value) => {
      this.cryptoDealModal = false;
      this.message = value;
      this.success = true;
      this.getRequests();
    });
    await this.getRequests();
  },
  computed: {
    ...mapGetters(["page"]),

    cryptoDetails() {
      return this.cryptoItems.map((cryptoItem) => {
        let transactionStatus = getStatusDisplay(cryptoItem.status);
        return {
          id: cryptoItem.id,
          status: transactionStatus,
          exchangeRate: cryptoItem.exchangeRate,
          modifiedDate: new Date(cryptoItem.createDate)
              .toISOString()
              .split("T")[0],
          thirdParty: cryptoItem.thirdParty.fullName,
          bought: cryptoItem.receiveCurrency.symbol + " " + formatNumber(cryptoItem.receiveAmount),
          sold: cryptoItem.sendCurrency.symbol + " " + formatNumber(cryptoItem.sendAmount),
          user: cryptoItem.createdBy,
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
  },
  watch: {
    searchValue(newVal) {
      if (newVal === "") {
        this.accountCofferName = null;
        this.serverOptions.page = 1;
        this.getRequests();
      } else {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.searchForItem(newVal);
        }, 500);
      }
    },
    serverOptions: {
      handler() {
        this.getRequests();
      },
      deep: true,
    },
  },
};
</script>
