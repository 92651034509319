<script>
import {setPage} from "@/utils/helpers";

export default {
  name: "actions-bar",
  props: {
    currentStatus: String,
    error: Boolean,
    step: Number,
  },
  methods: {
    async doRegistrationOrReconciliationOrExit() {
      if (this.currentStatus === "NOT INITIATED")
        this.$emit("register", {exit: true, showSuccess: true});
      else if (this.currentStatus === "ACCOUNTS_UPDATED")
        this.$emit("reconcile", true);
      else
        await setPage("internal report");
    },
    async handleButton(exit = false) {
      switch (this.step) {
        case 1:
          await this.$emit("register", {exit: true, showSuccess: true});
          break;
        case 2:
          await this.$emit("register", {exit: exit, showSuccess: false, generateTransaction: true});
          break;
        case 4:
          await this.$emit("generateTransaction");
          break;
        case 5:
          await this.$emit("firstPartyConfirmed");
          break;
        case 6:
          await this.$emit("requestForCompletion");
          break;
      }
      if (exit) {
        setTimeout(() => {
          if (exit) {
            setPage("internal report");
          }
        }, 1000);
      }
    },
  },
  computed: {
    registerOrExitOrReconcile() {
      return this.currentStatus === 'NOT INITIATED' ? 'REGISTER'
          : this.currentStatus === 'ACCOUNTS_UPDATED' ? 'RECONCILE' : 'EXIT';
    },
  }
}
</script>

<template>
  <v-row class="ma-4" justify="end">
    <v-btn
        elevation="4"
        class="mr-1"
        color="button_color"
        width="100"
        height="70"
        @click="doRegistrationOrReconciliationOrExit"
    >
      {{ registerOrExitOrReconcile }}
    </v-btn>
    <v-btn
        class="mr-1"
        elevation="4"
        :disabled="currentStatus === 'NOT INITIATED' || currentStatus === 'COMPLETED' || currentStatus === 'WAITING_FOR_APPROVAL' || currentStatus === 'ACCOUNTS_UPDATED'"
        width="150"
        height="70"
        color="button_color"
        @click="handleButton()"
    >
      Save And Next
    </v-btn>
    <v-btn
        elevation="4"
        :disabled="currentStatus === 'NOT INITIATED' || currentStatus === 'COMPLETED' || currentStatus === 'WAITING_FOR_APPROVAL' || currentStatus === 'ACCOUNTS_UPDATED'"
        width="150"
        height="70"
        color="button_color"
        @click="handleButton(true)"
    >
      Save And Exit
    </v-btn>
    <v-btn
        class="ml-1"
        v-if="currentStatus === 'ACCOUNTS_UPDATED'"
        elevation="4"
        width="150"
        height="70"
        color="error"
        variant="tonal"
        @click="$emit('delete')">
      Delete Request
    </v-btn>
  </v-row>

</template>

<style scoped>

</style>