// src/utils/utils.js
export function toCamelCase(str) {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
        .join(' ');
}

export function formatString(str) {
    return str
        // Replace underscores with spaces
        .replace(/_/g, ' ')
        // Convert to lowercase, then capitalize the first letter of each word
        .toLowerCase()
        .replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}

export function toTitleCase(str) {
    return str.split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
}

export function getImageUrl(name, ext) {
    return new URL(`../../assets/img/homeImg/${name}.${ext}`, import.meta.url).href;
}

export function shortenStringForTable(s) {
    const words = s.split(' ');
    if (words.length <= 3) {
        return words.join(' ');
    }
    return words.slice(0, 2).join(' ') + ' ' + words[words.length - 1];
}
