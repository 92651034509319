<template>
  <div>
    <v-card v-if="display" color="surface" style="background-color: white">
      <v-container v-if="!broken_page">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
                class="adjust-label"
                label="Search"
                v-model="searchValue"
            ></v-text-field>
          </v-col>
        </v-row>
        <EasyDataTable
            class="datatable-container"
            :headers="headers"
            :items="accountTypeDetails"
            :search-field="searchField"
            :search-value="searchValue"
            alternating
            border-cell
        >
          <template #item-choose="item">
            <v-icon @click="chooseAccountType(item)">mdi-check-circle</v-icon>
          </template>
        </EasyDataTable>
      </v-container>
      <div v-else>
        <Broken_Page_V01></Broken_Page_V01>
      </div>
    </v-card>
  </div>
</template>
<script>
import {httpResponseHandler} from "@/utils/helpers";
import emitter from "@/plugins/mitt";
import account_typeService from "@/services/account_type.service";
import Broken_Page_V01 from "../Broken_Page_V01.vue";

export default {
  props: {
    thirdPartyType: {
      type: String
    },
    currencySymbol: {
      type: String,
      required: false,
    },
    display: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  name: "third_party_list",
  components: {
    Broken_Page_V01,
  },
  data() {
    return {
      headers: [
        {text: "Id", value: "id"},
        {text: "Account Group", value: "accountGroup"},
        {text: "Ledger Code", value: "ledgerCode"},
        {text: "Description", value: "description"},
        {text: "Currency", value: "currency"},
        {text: "Choose", value: "choose"},
      ],
      searchField: "",
      searchValue: "",
      message: null,
      broken_page: false,
      accountTypes: [],
    };
  },

  methods: {
    chooseAccountTypeAutomatically() {
      const matchingAccountType = this.accountTypeDetails.find(accountType =>
          accountType.currency === this.currencySymbol
      );
      emitter.emit("new_account_type", matchingAccountType);

    },
    chooseAccountType(new_account_type) {
      emitter.emit("new_account_type", new_account_type);
    },

    async getAccountTypes() {
      let response = httpResponseHandler(
          await account_typeService.getAccountTypes()
      );

      if (response.success) {
        let filteredAccountTypes = this.filterAccountTypes(response.data);
        this.accountTypes = filteredAccountTypes.sort((a, b) => {
          if (a.generalLedger.code < b.generalLedger.code) return -1;
          if (a.generalLedger.code > b.generalLedger.code) return 1;
          return 0;
        });
      } else {
        this.broken_page = true;
      }
    },
    generateFilterCode() {
      switch (this.thirdPartyType) {
        case "CUSTOMER":
          return "2440"
        case "TRUSTEE":
          return "1010"
        case "SAFE_BOX":
          return "9010"
        case "SECONDARY_THIRDPARTY":
          return "9097"
        default:
          return null
      }
    },
    filterAccountTypes(accountTypes) {
      const filterCode = this.generateFilterCode();
      if (!filterCode) return [];

      return accountTypes.filter(accountType =>
          accountType.generalLedger &&
          accountType.generalLedger.code &&
          accountType.generalLedger.code.startsWith(filterCode)
      );
    }
  },
  async mounted() {
    await this.getAccountTypes();
    if (this.currencySymbol) this.chooseAccountTypeAutomatically();
  },

  computed: {
    accountTypeDetails() {
      return this.accountTypes.map((accountType) => ({
        id: accountType.id,
        accountGroup: accountType.accountGroup,
        description: accountType.description,
        currency: accountType.currency.symbol,
        ledgerCode: accountType.generalLedger.code,
      }));
    },
  },
  watch: {
    currencySymbol() {
      if (this.currencySymbol) this.chooseAccountTypeAutomatically();
    },
  }
};
</script>
