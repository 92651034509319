<template>
  <div>
    <v-container fluid>
      <v-form class="adjust-label" ref="add_account_type_form">
        <v-row>
          <v-col cols="12" md="6" sm="12" xs="12">
            <v-tab
              @click="generalLedgerListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select</v-tab
            >
            <v-text-field
              label="General Ledger"
              v-model="generalLedgerCode"
              @keyup.backspace="clearGeneralLedger"
              @keydown="handleKeyDown"
              :rules="requiredRule"
            ></v-text-field>
            <v-text-field
              label="Description"
              v-model="description"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" sm="12" xs="12">
            <v-autocomplete
              style="margin-top: 23px"
              label="Select Group"
              v-model="selectedAccountGroup"
              :items="accountGroups"
              item-title="name"
              item-value="id"
              filled
              return-object
              :rules="requiredRule"
            ></v-autocomplete>
            <v-autocomplete
              label="Select Currency"
              v-model="selectedCurrency"
              :items="currencies"
              item-title="name"
              item-value="name"
              filled
              return-object
              :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="end">
          <div class="d-flex top-20">
            <v-btn color="button_color" @click="cancel()"> Cancel </v-btn>
            <v-btn
              class="ml-4"
              color="button_color"
              v-if="!is_editing_account_type"
              @click="addAccountType"
            >
              Create Account Type
            </v-btn>
            <v-btn
              class="ml-4"
              v-else
              @click="addAccountType"
              color="button_color"
              >Edit Account Type</v-btn
            >
          </div>
        </v-row>
      </v-form>
      <!-- <v-dialog @click="success = false" v-model="success" width="auto">
        <v-alert type="success"> {{ message }} </v-alert>
      </v-dialog>
      <v-dialog v-model="error" width="auto">
        <v-alert @click="error = false" type="error"> {{ message }} </v-alert>
      </v-dialog> -->
      <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>      
      </div>
      <div class="error-right">
      <span class="close-icon" @click="error = false" style="text-align: right;">✖</span>
      <h4>Error</h4>
    <p class="error-message">{{ message }}</p>
    <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
</v-dialog>

     <v-dialog v-model="success">
        <div class="success-dialog">
          <div class="success-left">
            <div class="green-circle">
              <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 100 L80 130 L150 50" stroke="white" stroke-width="25" fill="transparent" class="success-tick" />
          </svg>
        </div>      
          </div>
          <div class="success-right">
          <span class="close-icon" @click="success = false" style="text-align: right;">✖</span>
          <h4>Success</h4>
        <p class="success-message"> {{ message }}</p>
        <button class="success-close-button" @click="success = false">Close</button>
          </div>
        </div>
    </v-dialog>
      <v-dialog v-model="generalLedgerListModal" width="auto">
        <General_Ledger_List_Modal_V01></General_Ledger_List_Modal_V01>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import {
  messageResultHandler,
  handleKeyDown,
  httpResponseHandler,
  formatCurrencyInfo,
  refreshEditAccountType,
  setPage,
} from "@/utils/helpers";
import { buildAccountGroupObject } from "@/utils/helpers";
import { requiredRule } from "@/utils/rules";
import { mapGetters } from "vuex";
import General_Ledger_List_Modal_V01 from "../../Modals/General_Ledger_List_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import account_typeService from "@/services/account_type.service";
import CurrencyService from "@/services/currency.service";
export default {
  name: "new_account_type_v01",
  components: {
    General_Ledger_List_Modal_V01,
  },
  data() {
    return {
      currencies: [],
      error: false,
      success: false,
      message: null,
      selectedGroup: null,
      generalLedger: null,
      codeGeneralLedger: null,
      selectedCurrency: null,
      generalLedgerListModal: false,
      selectedAccountGroup: null,
      accountGroups: [
        { name: "CURRENT", id: 0 },
        { name: "CREDIT", id: 1 },
        { name: "CASH", id: 2 },
        { name: "CUSTOMER", id: 3 },
      ],
      description: null,
      requiredRule: requiredRule,
      accountTypeListModal: false,
    };
  },
  methods: {
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearGeneralLedger() {
      this.generalLedger = null;
      this.codeGeneralLedger = null;
    },
    cancel() {
      setPage("account types");
    },
    async addAccountType() {
      let { valid } = await this.$refs.add_account_type_form.validate();
      let response = null;
      let form = null;
      if (valid) {
        if (!this.is_editing_account_type) {
          form = {
            Id: this.id,
            description: this.description,
            accountGroup: this.selectedAccountGroup.id,
            generalLedgerId: this.generalLedgerId,
            currencyId: this.selectedCurrency.id,
          };
          console.log("sent form", form);
          response = messageResultHandler(
            await account_typeService.addAccountType(form)
          );
        } else {
          form = {
            Id: this.id,
            description: this.description,
            accountGroup: this.selectedAccountGroup.id,
            generalLedgerId: this.generalLedgerId,
            currencyId: this.selectedCurrency.id,
          };
          console.log("edit form", form);
          response = messageResultHandler(
            await account_typeService.editAccountType(form)
          );
        }
        this.message = response.message;
        if (response.success) {
          this.success = true;
        } else {
          this.error = true;
        }
      }
    },
    clearThirdParty() {
      this.thirdParty = null;
    },
    async getCurrencies() {
      let response = httpResponseHandler(await CurrencyService.getCurrencies());
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },
  async mounted() {
    emitter.on("new_general_ledger", (value) => {
      this.generalLedger = value;
      this.generalLedgerListModal = false;
    });
    if (this.is_editing_account_type) {
      this.id = this.chosen_account_type.id;
      this.description = this.chosen_account_type.description;
      this.codeGeneralLedger = this.chosen_account_type.ledgerCode;
      this.idGeneralLedger = this.chosen_account_type.ledgerId;
      this.selectedAccountGroup = buildAccountGroupObject(
        this.chosen_account_type.accountGroup
        );
      this.selectedCurrency = this.chosen_account_type.currencyId;
      console.log(this.chosen_account_type, this.chosen_account_type.currencyId);
    }

    await this.getCurrencies();
  },
  computed: {
    ...mapGetters([
      "chosen_user",
      "chosen_account_type",
      "is_editing_account_type",
    ]),
    generalLedgerCode() {
      if (this.generalLedger) {
        return this.generalLedger.code;
      } else if (this.codeGeneralLedger) {
        return this.codeGeneralLedger;
      } else {
        return null;
      }
    },
    generalLedgerId() {
      if (this.generalLedger) {
        return this.generalLedger.id;
      } else if (this.idGeneralLedger) {
        return this.idGeneralLedger;
      } else {
        return null;
      }
    },
  },
  beforeUnmount() {
    refreshEditAccountType();
  },
  watch: {
    success(newValue) {
      if (!newValue) {
        setPage("account types");
      }
    },
  },
};
</script>
