<template>
    <div class="paading_info">
      <v-container class="info-box" v-if="!broken_page" fluid>
        <h3>Welcome to the Contacts Page!</h3>
        <p style="margin-left: 15px;">This is the "Contacts" page of the application, and here's what you can do on this page:</p>
        <ul>
    <li>
      <h6>Search & Add New Contact:</h6>
      <p>You can search for specific contacts by entering your search query in the "Search" field. To add a new contact, click on the plus icon (+) to create a new contact record.</p>
    </li>
    <li>
      <h6>Export Contacts Data:</h6>
      <p>Download contacts data in Excel format by clicking the export icon.</p>
    </li>
    <li>
    <h6>Edit & Delete Contact:</h6>
    <p>Edit contact details. Delete contacts.</p>
  </li>
  </ul>

      </v-container>
      <div v-else>
        <Broken_Page_V01></Broken_Page_V01>
      </div>
      <v-dialog v-model="success" width="auto">
        <v-alert type="success">success</v-alert>
      </v-dialog>
  
      <v-dialog v-model="error" width="auto">
        <v-alert type="error">error</v-alert>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import Broken_Page_V01 from "../Broken_Page_V01.vue";
  
  export default {
    name: "Q-Individual-Modal",
    components: {
      Broken_Page_V01,
    },
  };
  </script>

  