<template>
  <v-container fluid>
    <h2 class="ma-4 font-weight-medium">Past Crypto Deal</h2>
    <v-form class="adjust-label mt-11" ref="third_crypto_form">
      <v-row id="general-info" class="ma-5 mb-3">
        <v-col cols="12" md="3" sm="12" xs="12">
          <v-tab
            @click="thirdPartyListModal = true"
            prepend-icon="mdi-magnify"
            color="button_color"
            >select
          </v-tab>
          <v-text-field
            label="Third Party Name"
            v-model="thirdPartyFullName"
            @keyup.backspace="clearThirdParty"
            @keydown="handleKeyDown"
            :rules="requiredRule"
          ></v-text-field>
          <v-autocomplete
            label="How Exchange rate should calc"
            v-model="exchangeRateCalc"
            :items="exchangeRateCalcTypes"
            item-title="name"
            item-value="id"
            filled
            return-object
            :rules="requiredRule"
          ></v-autocomplete>
          <label for="date-picker" style="color: black">Date</label>
          <VueDatePicker
            auto-apply="true"
            id="date-picker"
            v-model="date"
            :rules="requiredRule"
            :show-timepicker="false"
            @input="validateDate"
          ></VueDatePicker>
        </v-col>
        <v-col cols="12" md="3" sm="12" xs="12" class="mt-25px">
          <v-text-field
            label="Reference No."
            v-model="referenceNo"
          ></v-text-field>
          <v-text-field
            v-maska:[exchangeRateOption]
            :label="$t('exchange rate')"
            v-model="exchangeRateFormat"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12" xs="12" class="mt-25px">
          <v-autocomplete
            label="Receive Currency"
            v-model="selectedFirstCurrency"
            :items="currencies"
            item-title="name"
            item-value="name"
            filled
            return-object
            :rules="requiredRule"
          ></v-autocomplete>
          <v-autocomplete
            label="Send Currency"
            v-model="selectedSecondCurrency"
            :items="currencies"
            item-title="name"
            item-value="name"
            filled
            return-object
            :rules="requiredRule"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" sm="12" xs="12" class="mt-25px">
          <v-text-field
            v-maska:[moneyOption]
            label="We bought (Amount)"
            v-model="WeBought"
            :rules="requiredRule"
          ></v-text-field>

          <v-text-field
            v-maska:[moneyOption2]
            label="We sold (Amount)"
            v-model="WeSold"
            :rules="requiredRule"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-card id="receive-side" class="custom-border ma-4" variant="outlined">
        <h3 class="ma-4 font-weight-medium">Receive Side</h3>
        <v-row class="ma-1">
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
              @click="ourAccountReceiveSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              label="Our Account"
              v-model="ourAccountFullNameReceiveSide"
            ></v-text-field>
            <v-tab
              :disabled="thirdParty === null"
              @click="thirdPartyAccountReceiveSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              :disabled="thirdParty === null"
              label="Third Party Account"
              v-model="thirdPartyAccountFullNameReceiveSide"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
              @click="ourPAReceiveSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field label="Our PA" v-model="ourPAFullName">
            </v-text-field>
            <v-tab
              @click="targetPAReceiveSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field label="Target PA" v-model="targetPAFullName">
            </v-text-field>
          </v-col>

          <v-col cols="3" md="3" sm="12" xs="12" class="mt-25px">
            <v-text-field
              readonly
              label="We Bought (Amount)"
              v-model="WeBought"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-card id="send-side" class="custom-border ma-4" variant="outlined">
        <h3 class="ma-4 font-weight-medium">Send Side</h3>
        <v-row class="ma-1">
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
              @click="ourAccountSendSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              label="Our Account"
              v-model="ourAccountFullNameSendSide"
            ></v-text-field>
            <v-tab
              :disabled="thirdParty === null"
              @click="thirdPartyAccountSendSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field
              :disabled="thirdParty === null"
              label="Third Party Account"
              v-model="thirdPartyAccountFullNameSendSide"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
              @click="ourPASendSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field label="Our PA" v-model="ourPASendSideFullName">
            </v-text-field>
            <v-tab
              @click="targetPASendSideListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select
            </v-tab>
            <v-text-field label="Target PA" v-model="targetPASendSideFullName">
            </v-text-field>
          </v-col>

          <v-col cols="3" md="3" sm="12" xs="12" class="mt-25px">
            <v-text-field readonly label="We Sold (Amount)" v-model="WeSold">
            </v-text-field>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="ma-4" justify="end">
        <v-btn class="mr-1" color="button_color" @click="showReport = true">
          Confirm
        </v-btn>
      </v-row>
    </v-form>
  </v-container>

  <div id="dialogs">
    <v-dialog v-model="showReport" max-width="720">
      <v-card style="padding: 20px">
        <div class="border-1">
          <div class="grid-card-2">
            <p>Date:</p>
            <p class="m-l-35">{{ date }}</p>
            <p>Third Party</p>
            <p class="m-l-35">{{ thirdPartyFullName }}</p>
            <p>Reference No</p>
            <p class="m-l-35">{{ referenceNo }}</p>
            <p>Receive Amount</p>
            <p class="m-l-35">{{ WeBought }}</p>
            <p>Send Amount</p>
            <p class="m-l-35">{{ WeSold }}</p>
            <p>Exchange Rate calc</p>
            <p class="m-l-35">{{ exchangeRateCalcTypesReport }}</p>
            <p>Exchange Rate</p>
            <p class="m-l-35">{{ exchangeRateFormat }}</p>
            <p>Receive Our Account</p>
            <p class="m-l-35">{{ ourAccountFullNameReceiveSide }}</p>
            <p>Receive Third Party</p>
            <p class="m-l-35">{{ thirdPartyAccountFullNameReceiveSide }}</p>
            <p>Receive Our PA</p>
            <p class="m-l-35">{{ ourPAFullName }}</p>
            <p>Receive Target PA</p>
            <p class="m-l-35">{{ targetPAFullName }}</p>
            <p>Send Our Account</p>
            <p class="m-l-35">{{ ourAccountFullNameSendSide }}</p>
            <p>Send Third Party</p>
            <p class="m-l-35">{{ thirdPartyAccountFullNameSendSide }}</p>
            <p>Send Our PA</p>
            <p class="m-l-35">{{ ourPASendSideFullName }}</p>
            <p>Send Target PA</p>
            <p class="m-l-35">{{ targetPASendSideFullName }}</p>
          </div>
          <v-row justify="end">
            <div class="d-flex top-20">
              <v-btn
                color="button_color"
                style="margin-right: 10px"
                @click="pastCryptoBtn()"
                >{{ $t("confirm") }}
              </v-btn>
              <v-btn color="button_color" @click="RemoveDataPastCrypto"
                >Cancel</v-btn
              >
            </div>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="thirdPartyListModal" width="auto">
      <Third_Party_List_Modal_V01
        thirdPartyType="ALL"
      ></Third_Party_List_Modal_V01>
    </v-dialog>
    <v-dialog v-model="ourAccountReceiveSideListModal" width="auto">
      <Third_Party_List_Modal_V01
        third-party-type="ACCOUNT"
      ></Third_Party_List_Modal_V01>
    </v-dialog>
    <v-dialog v-model="thirdPartyAccountReceiveSideListModal" width="auto">
      <list_of_accounts_modal_v01
        :thirdPartyId="this.thirdParty.id"
        :third-party-type="this.thirdParty.thirdPartyType"
      >
      </list_of_accounts_modal_v01>
    </v-dialog>
    <v-dialog v-model="ourAccountSendSideListModal" width="auto">
      <Third_Party_List_Modal_V01
        thirdPartyType="ACCOUNT"
      ></Third_Party_List_Modal_V01>
    </v-dialog>
    <v-dialog v-model="thirdPartyAccountSendSideListModal" width="auto">
      <list_of_accounts_modal_v01
        :thirdPartyId="this.thirdParty.id"
        :third-party-type="this.thirdParty.thirdPartyType"
      >
      </list_of_accounts_modal_v01>
    </v-dialog>
    <v-dialog v-model="ourPAReceiveSideListModal" width="auto">
      <Third_Party_List_Modal_V01
        thirdPartyType="SECONDARY_THIRDPARTY"
      ></Third_Party_List_Modal_V01>
    </v-dialog>
    <v-dialog v-model="targetPAReceiveSideListModal" width="auto">
      <Third_Party_List_Modal_V01
        thirdPartyType="SECONDARY_THIRDPARTY"
      ></Third_Party_List_Modal_V01>
    </v-dialog>
    <v-dialog v-model="ourPASendSideListModal" width="auto">
      <Third_Party_List_Modal_V01
        thirdPartyType="SECONDARY_THIRDPARTY"
      ></Third_Party_List_Modal_V01>
    </v-dialog>
    <v-dialog v-model="targetPASendSideListModal" width="auto">
      <Third_Party_List_Modal_V01
        thirdPartyType="SECONDARY_THIRDPARTY"
      ></Third_Party_List_Modal_V01>
    </v-dialog>
  </div>
  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import Third_Party_List_Modal_V01 from "../Modals/Third_Party_List_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import {
  ExchangeRateOption,
  MoneyOption,
} from "@/utils/maska_options";
import currencyService from "@/services/currency.service";
import {
  extractNumber,
  formatCurrencyInfo, formatNumberInternal,
  handleKeyDown,
  httpResponseHandler,
  messageResultHandler,
} from "@/utils/helpers";
import list_of_accounts_modal_v01 from "@/components/Modals/List_Of_Accounts_Modal_V01.vue";
import { requiredRule } from "@/utils/rules";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import cryptoService from "@/services/crypto.service";

export default {
  name: "past-crypto-deal_v01",
  components: {
    Success_Modal,
    Error_Modal,
    list_of_accounts_modal_v01,
    Third_Party_List_Modal_V01,
  },
  data() {
    return {
      message: null,
      success: false,
      error: false,
      thirdPartyListModal: false,
      thirdParty: null,
      WeBought: null,
      currencies: [],
      selectedFirstCurrency: null,
      selectedSecondCurrency: null,
      referenceNo: null,
      exchangeRateOption: ExchangeRateOption,
      requiredRule: requiredRule,
      exchangeRate: null,
      WeSold: null,
      ourAccount: null,
      ourPA: null,
      targetPA: null,
      ourPASendSide: null,
      targetPASendSide: null,
      ourAccountReceiveSideListModal: false,
      thirdPartyAccountReceiveSideListModal: false,
      ourAccountSendSideListModal: false,
      thirdPartyAccountSendSideListModal: false,
      ourPAReceiveSideListModal: false,
      targetPAReceiveSideListModal: false,
      ourPASendSideListModal: false,
      targetPASendSideListModal: false,
      thirdPartyAccount: null,
      thirdPartyAccountSendSide: null,
      ourAccountSendSide: null,
      exchangeRateCalc: null,
      exchangeRateEdit: null,
      showReport: false,
      date: null,
      exchangeRateCalcTypes: [
        { name: "We sold / We Bought", id: 0 },
        { name: "We Bough / We sold", id: 1 },
      ],
    };
  },
  methods: {
    handleKeyDown,
    async getCurrencies() {
      let response = httpResponseHandler(await currencyService.getCurrencies());
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    clearThirdParty() {
      this.thirdParty = null;
    },
    async pastCryptoBtn() {
      let { valid } = await this.$refs.third_crypto_form.validate();
      if (this.date) {
        if (valid) {
          let form = {
            thirdPartyId: this.thirdPartyId,
            receiveAmount: extractNumber(this.WeBought),
            sendAmount: extractNumber(this.WeSold),
            receiveCurrencyId: this.selectedFirstCurrencyId,
            sendCurrencyId: this.selectedSecondCurrencyId,
            referenceNo: this.referenceNo,
            exchangeRate: extractNumber(this.exchangeRateFormat),
            ellingtonReceiveAccountId: this.ourAccountIdReceiveSide,
            ellingtonSendAccountId: this.ourAccountIdSendSide,
            payerAccountId: this.thirdPartyAccountIdReceiveSide,
            payeeAccountId: this.thirdPartyAccountIdSendSide,
            ellingtonReceivePAId: this.ourPAId,
            ellingtonSendPAId: this.ourPASendSideId,
            counterPartyReceivePAId: this.targetPAId,
            counterPartySendPAId: this.targetPASendSideId,
            createDate: this.date,
          };
          console.log("form", form);
          let response = messageResultHandler(
            await cryptoService.requestForPastOperation(form)
          );
          if (response.success) {
            this.success = true;
            this.message = response.message;
            this.RemoveDataPastCrypto();
          } else {
            this.error = true;
            this.message = response.message;
          }
        }
      } else {
        this.error = true;
        this.message = "Date is required";
        this.showReport = false;
        console.log("Date is required");
      }
    },
    RemoveDataPastCrypto() {
      this.showReport = false;
      this.referenceNo = null;
      this.WeBought = null;
      this.WeSold = null;
      this.selectedFirstCurrency = null;
      this.selectedSecondCurrency = null;
      this.exchangeRateFormat = null;
      this.thirdParty = null;
      this.exchangeRateCalc = null;
      this.ourAccount = null;
      this.ourAccountSendSide = null;
      this.ourPA = null;
      this.targetPA = null;
      this.ourPASendSide = null;
      this.targetPASendSide = null;
      this.thirdPartyAccount = null;
      this.thirdPartyAccountSendSide = null;
      this.date = null;
    },
  },
  computed: {
    targetPASendSideFullName() {
      if (this.targetPASendSide) {
        return this.targetPASendSide.fullName;
      } else {
        return null;
      }
    },
    targetPASendSideId() {
      if (this.targetPASendSide) {
        return this.targetPASendSide.id;
      } else {
        return null;
      }
    },
    ourPASendSideFullName() {
      if (this.ourPASendSide) {
        return this.ourPASendSide.fullName;
      } else {
        return null;
      }
    },
    ourPASendSideId() {
      if (this.ourPASendSide) {
        return this.ourPASendSide.id;
      } else {
        return null;
      }
    },
    ourAccountFullNameReceiveSide() {
      if (this.ourAccount) {
        return this.ourAccount.cofferName;
      } else {
        return null;
      }
    },
    ourAccountIdReceiveSide() {
      if (this.ourAccount) {
        return this.ourAccount.id;
      } else {
        return null;
      }
    },
    ourPAFullName() {
      if (this.ourPA) {
        return this.ourPA.fullName;
      } else {
        return null;
      }
    },
    ourPAId() {
      if (this.ourPA) {
        return this.ourPA.id;
      } else {
        return null;
      }
    },
    targetPAFullName() {
      if (this.targetPA) {
        return this.targetPA.fullName;
      } else {
        return null;
      }
    },
    targetPAId() {
      if (this.targetPA) {
        return this.targetPA.id;
      } else {
        return null;
      }
    },
    ourAccountFullNameSendSide() {
      if (this.ourAccountSendSide) {
        return this.ourAccountSendSide.cofferName;
      } else {
        return null;
      }
    },
    ourAccountIdSendSide() {
      if (this.ourAccountSendSide) {
        return this.ourAccountSendSide.id;
      } else {
        return null;
      }
    },
    thirdPartyAccountFullNameSendSide() {
      if (this.thirdPartyAccountSendSide) {
        return this.thirdPartyAccountSendSide.cofferName;
      } else {
        return null;
      }
    },
    thirdPartyAccountIdSendSide() {
      if (this.thirdPartyAccountSendSide) {
        return this.thirdPartyAccountSendSide.id;
      } else {
        return null;
      }
    },
    thirdPartyAccountFullNameReceiveSide() {
      if (this.thirdPartyAccount) {
        return this.thirdPartyAccount.cofferName;
      } else {
        return null;
      }
    },
    thirdPartyAccountIdReceiveSide() {
      if (this.thirdPartyAccount) {
        return this.thirdPartyAccount.id;
      } else {
        return null;
      }
    },

    thirdPartyFullName() {
      if (this.thirdParty) {
        console.log("this.thirdParty.fullName", this.thirdParty.fullName);
        return this.thirdParty.fullName;
      } else {
        return null;
      }
    },

    thirdPartyId() {
      if (this.thirdParty) {
        return this.thirdParty.id;
      } else {
        return null;
      }
    },
    moneyOption() {
      if (this.selectedFirstCurrency) {
        return MoneyOption(this.selectedFirstCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    moneyOption2() {
      if (this.selectedSecondCurrency) {
        return MoneyOption(this.selectedSecondCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    selectedSecondCurrencyId() {
      if (this.selectedSecondCurrency && this.is_editing_crypto_deal) {
        return this.selectedSecondCurrency.symbol;
      } else if (!this.is_editing_crypto_deal && this.selectedSecondCurrency) {
        return this.selectedSecondCurrency.id;
      } else {
        return null;
      }
    },
    selectedFirstCurrencyId() {
      if (this.selectedFirstCurrency && this.is_editing_crypto_deal) {
        return this.selectedFirstCurrency.symbol;
      } else if (!this.is_editing_crypto_deal && this.selectedFirstCurrency) {
        return this.selectedFirstCurrency.id;
      } else {
        return null;
      }
    },
    exchangeRateFormat() {
      if (this.is_editing_crypto_deal) {
        return this.exchangeRateEdit;
      } else if (
        this.WeSold &&
        this.exchangeRateCalc &&
        this.WeBought &&
        !this.is_editing_crypto_deal
      ) {
        if (this.exchangeRateCalc.id === 0) {
          const rate =
            extractNumber(this.WeSold) / extractNumber(this.WeBought);
          return formatNumberInternal(rate);
        } else {
          const rate =
            extractNumber(this.WeBought) / extractNumber(this.WeSold);
          return formatNumberInternal(rate);
        }
      } else {
        return null;
      }
    },
    exchangeRateCalcTypesReport() {
      if (this.exchangeRateCalc) {
        if (this.exchangeRateCalc.id === 0) {
          return "We sold / We Bought";
        } else {
          return "We Bought / We sold";
        }
      } else {
        return null;
      }
    },
  },
  async mounted() {
    emitter.on("new_third_party", (value) => {
      switch (true) {
        case this.thirdPartyListModal:
          this.thirdParty = value;
          this.thirdPartyListModal = false;
          console.log("thirdParty2", this.thirdParty);
          break;
        case this.ourAccountReceiveSideListModal:
          this.ourAccount = value;
          this.ourAccountReceiveSideListModal = false;
          console.log("ourAccount", this.ourAccount);
          break;
        case this.ourAccountSendSideListModal:
          this.ourAccountSendSide = value;
          this.ourAccountSendSideListModal = false;
          console.log("ourAccountSendSide", this.ourAccountSendSide);
          break;
        case this.ourPAReceiveSideListModal:
          this.ourPA = value;
          this.ourPAReceiveSideListModal = false;
          console.log("ourPA", this.ourPA);
          break;
        case this.targetPAReceiveSideListModal:
          this.targetPA = value;
          this.targetPAReceiveSideListModal = false;
          console.log("targetPA", this.targetPA);
          break;
        case this.ourPASendSideListModal:
          this.ourPASendSide = value;
          this.ourPASendSideListModal = false;
          console.log("ourPASendSide", this.ourPASendSide);
          break;
        case this.targetPASendSideListModal:
          this.targetPASendSide = value;
          this.targetPASendSideListModal = false;
          console.log("targetPASendSide", this.targetPASendSide);
          break;
      }
    });
    emitter.on("chosenAccount", (value) => {
      if (this.thirdPartyAccountReceiveSideListModal) {
        this.thirdPartyAccount = value;
        this.thirdPartyAccountReceiveSideListModal = false;
        console.log("thirdPartyAccount", this.thirdPartyAccount);
      } else if (this.thirdPartyAccountSendSideListModal) {
        this.thirdPartyAccountSendSide = value;
        this.thirdPartyAccountSendSideListModal = false;
        console.log(
          "thirdPartyAccountSendSide",
          this.thirdPartyAccountSendSide
        );
      }
    });

    await this.getCurrencies();
  },
};
</script>
<style scoped>
.custom-border {
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}
</style>
