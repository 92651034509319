export function authHeader() {
  let user = JSON.parse(localStorage.getItem("auth/user"));

  if (user) {
    return {
      Authorization: "Bearer " + user.jwtToken, 
    };
  } else {
    return {};
  }
}

export function authFormDataHeader() {
  let user = JSON.parse(localStorage.getItem("auth/user"));
  if (user) {
    return {
      Authorization: "Bearer " + user.jwtToken,
      "Content-Type": "multipart/form-data",
    };
  } else {
    return {};
  }
}

export function authEmail() {
  let user = JSON.parse(localStorage.getItem("auth/user"));
  if (user) {
    return user.email;
  } else {
    return "";
  }
}

export function authRole() {
  let user = JSON.parse(localStorage.getItem("auth/user"));
  if (user) {
    return user.role;
  } else {
    return {};
  }
}
