<script>
import {formatNumber, httpResponseHandler, pageResponseHandler} from "@/utils/helpers.js";
import journalEntryService from "@/services/journal-entry.service.js";
import Broken_Page_V01 from "@/components/Broken_Page_V01.vue";
import transactionService from "@/services/transaction.service.js";
import {useTheme} from "vuetify";

export default {
  name: "journal-entry-report.vue",
  components: {Broken_Page_V01},
  data() {
    return {
      searchValue: null,
      broken_page: false,
      headers: [
        {text: "Event", value: "id"},
        {text: "Third Party Account", value: "cofferName"},
        {text: "Note", value: "note"},
        {text: "Create Date", value: "createDate"},
        {text: "User", value: "createdBy"},
      ],
      journalEntries: [],
      transactionsHeaders: [
        {title: "Ledger Code", key: "ledgerCode"},
        {title: "Ledger Description", key: "ledgerDescription"},
        {title: "Debit", key: "debit"},
        {title: "Credit", key: "credit"},
      ],

      loading: false,
      totalItems: 0,
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
    }
  },
  methods: {
    mapTransactions(transactions) {
      return transactions.map(transaction => {
        return {
          ledgerCode: transaction.ledgerCode,
          ledgerDescription: transaction.ledgerDescription,
          credit: transaction.ledgerDirection === 'CREDIT' ? formatNumber(transaction.amount) : null,
          debit: transaction.ledgerDirection === 'DEBIT' ? formatNumber(transaction.amount) : null,
        }
      })
    },
    async loadTransactions(index) {
      const journalEntryId = this.journalEntryItems[index].id;
      this.journalEntries[index].expandLoading = true;
      const response = httpResponseHandler(await transactionService.getTransactionsForEvent(journalEntryId));
      this.journalEntries[index].expandLoading = false;
      console.log(response);
      if (response.success) {
        this.journalEntries[index].transactions = this.mapTransactions(response.data);
      } else {
        this.broken_page = true
      }
    },
    async getJournalEntries() {
      this.loading = true;
      let response = pageResponseHandler(
          await journalEntryService.getAll(this.serverOptions.page - 1, this.serverOptions.rowsPerPage)
      );
      this.loading = false;
      if (response.success) {
        this.journalEntries = response.data
        this.totalItems = response.totalItems;
      } else {
        this.broken_page = true
      }
    }
  },
  computed: {
    journalEntryItems() {
      return this.journalEntries.map(journalEntry => {
        return {
          id: journalEntry.id,
          cofferName: journalEntry.cofferName,
          note: journalEntry.note,
          createDate: new Date(journalEntry.createDate).toISOString().split("T")[0],
          createdBy: journalEntry.createdBy,
          expandLoading: !!journalEntry.expandLoading,
          transactions: journalEntry.transactions ? journalEntry.transactions : [],
        }
      })
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
    tableColorTheme() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor2;
    },
  },
  async mounted() {
    await this.getJournalEntries();
  },
  watch: {
    serverOptions: {
      handler() {
        this.getManualTransaction();
      },
      deep: true,
    },
  }
}
</script>

<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium">Journal Entry Report</h3>
    <v-row>
      <v-col cols="12" md="3" sm="8">
        <div class="search-bar">
          <v-text-field
              class="adjust-label search-field"
              label="Search"
              v-model="searchValue"
          >
            <template v-slot:prepend-inner>
              <v-icon
                  icon="mdi-magnify"
                  variant="tonal"
                  color="button_color"
                  class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn color="button_color" icon="mdi-help" class="ml-2"></v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel>
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
        :headers="headers"
        :items="journalEntryItems"
        :search-field="searchField"
        :search-value="searchValue"
        body-text-direction="center"
        header-text-direction="center"
        alternating border-cell
        class="custom-data-table"
        :loading="loading"
        :theme-color="tableColorTheme"
        v-model:serverOptions="serverOptions"
        :server-items-length="totalItems"
        @expand-row="loadTransactions"
    >
      <template #expand="item">
        <v-data-table density="compact" class="px-16" v-if="item.transactions.length > 0"
                      :headers="transactionsHeaders"
                      :items="item.transactions"
                      hide-default-footer
        >
        </v-data-table>
      </template>
      <template #pagination="{}">
        <v-btn
            class="ma-4"
            density="compact"
            :disabled="serverOptions.page === 1"
            @click="goToPrevPage"
        >prev page
        </v-btn
        >
        <v-btn
            class="ma-4"
            density="compact"
            :disabled="serverOptions.page === numberOfPages"
            @click="goToNextPage"
        >next page
        </v-btn
        >
      </template>
    </EasyDataTable>
  </v-container>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
</template>

<style scoped>

</style>