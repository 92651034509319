<template>
  <v-sheet max-width="800" min-width="600">
    <div style="padding: 20px">
      <div class="border-1">
        <h4 class="border-h4">Internal Transfer Summary</h4>
        <div
            class="grid-card-2"
            ref="pdfContent"
            style="text-align: left; direction: ltr"
        >
          <p>Event:</p>
          <p class="m-l-35">{{ chosen_internal_transfer.id }}</p>
          <p>Reference No:</p>
          <p class="m-l-35">
            {{ chosen_internal_transfer.referenceNo }}
          </p>
          <p>From:</p>
          <p class="m-l-35">
            {{ chosen_internal_transfer.sourceAccount.cofferName }}
          </p>
          <p>Debit:</p>
          <p class="m-l-35">
            {{ formatNumber(chosen_internal_transfer.amount) }}
            {{ chosen_internal_transfer.sourceAccount.currency.symbol }}
          </p>
          <p>{{ $t("exchange rate") }}:</p>
          <p class="m-l-35">{{ chosen_internal_transfer.exchangeRate }}</p>
          <p>Live XE Rate:</p>
          <p class="m-l-35">{{ chosen_internal_transfer.liveXERate }}</p>
          <p>To:</p>
          <p class="m-l-35">
            {{ chosen_internal_transfer.targetAccount.cofferName }}
          </p>
          <p>Credit:</p>
          <p class="m-l-35">
            {{ formatNumber(chosen_internal_transfer.targetAmount) }}
            {{ chosen_internal_transfer.targetAccount.currency.symbol }}
          </p>
          <p>Status:</p>
          <p class="m-l-35">
            {{ getStatusDisplay(chosen_internal_transfer.status) }}
          </p>
          <p>Source Com Amt:</p>
          <p class="m-l-35">
            {{
              chosen_internal_transfer.withdrawalCommissionAmount ? chosen_internal_transfer.withdrawalCommissionAmount : 0
            }}
          </p>
          <p>Source Com %:</p>
          <p class="m-l-35">
            {{
              chosen_internal_transfer.withdrawalCommissionPercent ? chosen_internal_transfer.withdrawalCommissionPercent : 0
            }}%
          </p>
          <p>Target Com Amt:</p>
          <p class="m-l-35">
            {{
              chosen_internal_transfer.depositCommissionAmount ? chosen_internal_transfer.depositCommissionAmount : 0
            }}
          </p>
          <p>Target Com %:</p>
          <p class="m-l-35">
            {{
              chosen_internal_transfer.depositCommissionPercent
                  ? chosen_internal_transfer.depositCommissionPercent
                  : 0
            }}%
          </p>
          <p>Note:</p>
          <p class="m-l-35">{{ chosen_internal_transfer.description }}</p>
          <p>Modified on:</p>
          <p class="m-l-35">{{ chosen_internal_transfer.modifiedDate }}</p>
        </div>
        <v-row justify="end">
          <v-btn class="mr-4 top-20" color="button_color" @click="generatePDF">
            PDF
          </v-btn>
          <div
              class="d-flex top-20"
              v-if="chosen_internal_transfer.status === 'WAITING_FOR_APPROVAL'"
          >
            <v-btn
                color="button_color"
                style="margin-right: 10px"
                @click="approveRequest">
              {{ $t("confirm") }}
            </v-btn>
            <v-btn color="red-lighten-1" variant="tonal" @click="cancelRequest">
              Reject
            </v-btn>
          </div>
        </v-row>
      </div>
    </div>
  </v-sheet>

  <Error_Modal
      :error="error"
      :message="message"
      @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import {formatNumber, messageResultHandler} from "@/utils/helpers.js";
import internal_transferService from "@/services/internal_transfer.service.js";
import {mapGetters} from "vuex";
import emitter from "@/plugins/mitt.js";
import html2pdf from "html2pdf.js";
import getStatusDisplay from "@/utils/NewFunctions/getStatusDisplay.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";

export default {
  name: "request_modal_v01",
  components: {
    Error_Modal,
  },
  data() {
    return {
      request: null,
      currency: null,
      customer: null,
      amount: null,
      createDate: null,
      modifiedDate: null,
      status: null,
      requestType: null,
      trusteeCommissionAmount: null,
      trusteeCommissionPercent: null,
      ellingtonCommissionPercent: null,
      ellingtonCommissionAmount: null,
      message: null,
      error: false,
    };
  },
  methods: {
    getStatusDisplay,
    formatNumber,
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: "internalTransferSummary.pdf",
        image: {type: "jpeg", quality: 0.98},
        html2canvas: {scale: 2},
        jsPDF: {unit: "mm", format: "a4", orientation: "portrait"},
      };

      html2pdf(element, options);
    },
    async cancelRequest() {
      let response = messageResultHandler(
          await internal_transferService.changeRequestStatusToCanceled(
              this.chosen_internal_transfer.id
          )
      );
      this.message = response.message;
      if (response.success) {
        emitter.emit("internal_transfer_modal_close", this.message);
      } else {
        this.error = true;
      }
    },
    async approveRequest() {
      let response = messageResultHandler(
          await internal_transferService.changeRequestStatusToApproved(
              this.chosen_internal_transfer.id
          )
      );
      this.message = response.message;
      if (response.success) {
        emitter.emit("internal_transfer_modal_close", this.message);
      } else {
        this.error = true;
      }
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["chosen_internal_transfer", "page"]),
  },
};
</script>
