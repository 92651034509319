<script>
import {mapGetters} from "vuex";
import emitter from "@/plugins/mitt";
import {
  formatCurrencyInfo,
  formatNumber,
  handleKeyDown,
  httpResponseHandler,
  messageResultHandler
} from "@/utils/helpers";
import third_partyService from "@/services/third_party.service";
import {requiredRule} from "@/utils/rules";
import accountService from "@/services/account.service";
import CurrencyService from "@/services/currency.service";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import {toCamelCase} from "@/utils/NewFunctions/utils";
import Account_Type_List_Modal_V01 from "@/components/Modals/Account_Type_List_Modal_V01.vue";

export default {
  name: "AccountForm.vue",
  components: {Account_Type_List_Modal_V01, Error_Modal, Success_Modal},
  data() {
    return {
      success: false,
      error: false,
      message: "",
      thirdPartyAccounts: [],
      accountTypeListModal: false,
      individualListModal: false,
      cofferName: null,
      accountType: null,
      bankAccountName: null,
      bankAccountNumber: null,
      bankAccountOwnerFullName: null,
      selectedCurrency: null,
      currencies: [],
      iban: null,
      bicOrSwiftCode: null,
      loading: false,
    };
  },
  methods: {
    formatNumber,
    handleKeyDown,
    requiredRule() {
      return requiredRule
    },
    async getThirdPartyAccounts(id) {
      let response = messageResultHandler(
          await third_partyService.getThirdPartyAccounts(id)
      );
      if (response.success) {
        this.thirdPartyAccounts = response.data;
      } else {
        if (response.message !== "No Account is created for this Third Party") {
          this.error = true;
          console.log("error");
        }
      }
    },
    async getCurrencies() {
      let response = httpResponseHandler(await CurrencyService.getCurrencies());
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    clearAccountType() {
      this.accountType = null;
    },
    async addTrusteeAccount() {
      let {valid} = await this.$refs.add_trustee_account_form.validate();
      if (valid) {
        let form = {
          thirdPartyId: this.chosen_third_party.id,
          currencyId: this.selectedCurrency.id,
          cofferName: this.cofferName,
          bankName: this.bankAccountName,
          bankAccountNumber: this.bankAccountNumber,
          iban: this.iban,
          bicOrSwiftCode: this.bicOrSwiftCode,
          bankAccountOwnerFullName: this.bankAccountOwnerFullName,
          accountTypeId: this.accountType.id,
        };

        console.log("form", form);
        let response = messageResultHandler(
            await accountService.addAccount(form)
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          await this.getThirdPartyAccounts(this.chosen_third_party.id);
          this.cofferName = null;
          this.accountTypeName = null;
          this.accountType = null;
          this.bankAccountName = null;
          this.bankAccountNumber = null;
          this.iban = null;
          this.bicOrSwiftCode = null;
          this.bankAccountOwnerFullName = null;
          this.accountType = null;
          this.selectedCurrency = null;
        } else {
          this.error = true;
        }
      }
    },


  },

  async mounted() {
    this.loading = true;
    emitter.on("new_account_type", (value) => {
      this.accountType = value;
    });
    await this.getCurrencies();
    if (this.is_editing_third_party)
      await this.getThirdPartyAccounts(this.chosen_third_party.id);
    this.loading = false;
  },
  computed: {
    ...mapGetters(["chosen_third_party", "is_editing_third_party"]),
    accountTypeName() {
      if (this.accountType) {
        return toCamelCase(this.accountType.accountGroup) + " " + this.accountType.ledgerCode;
      } else {
        return null;
      }
    },
  }
}
</script>

<template>

  <v-container fluid>
    <v-form class="adjust-label" ref="add_trustee_account_form" :disabled="loading">
      <v-row>
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-text-field
              label="Coffer Name (Third Party + ... + Currency)"
              v-model="cofferName"
              :rules="requiredRule()"
          ></v-text-field>


          <v-text-field
              readonly
              variant="outlined"
              label="Account Type"
              v-model="accountTypeName"
              @keyup.backspace="clearAccountType"
              @keydown="handleKeyDown"
          ></v-text-field>

          <v-text-field
              label="Bank Name"
              v-model="bankAccountName"
          ></v-text-field>
          <v-text-field
              label="Bank Account Number"
              v-model="bankAccountNumber"
          ></v-text-field>

        </v-col>
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-text-field
              label="Bank Account Owner Full Name"
              v-model="bankAccountOwnerFullName"
          ></v-text-field>
          <v-autocomplete
              label="Select Currency"
              v-model="selectedCurrency"
              :items="currencies"
              item-title="name"
              item-value="name"
              filled
              return-object
              :rules="requiredRule()"
          ></v-autocomplete>
          <v-text-field label="IBAN" v-model="iban"></v-text-field>
          <v-text-field
              label="BIC Or Swift Code"
              v-model="bicOrSwiftCode"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="end">
        <div class="d-flex top-20">
          <v-btn color="button_color"> Cancel</v-btn>
          <v-btn
              class="ml-4 mb-10"
              color="button_color"
              @click="addTrusteeAccount"
          >
            Add Account
          </v-btn>
        </div>
      </v-row>
    </v-form>

    <Account_Type_List_Modal_V01
        v-if="this.selectedCurrency!=null"
        :third-party-type="chosen_third_party.thirdPartyType"
        :display="false"
        :currency-symbol="selectedCurrency.symbol"
    ></Account_Type_List_Modal_V01>


    <template v-if="thirdPartyAccounts.length === 0 && this.loading===false ">
      <v-card variant="outlined">
        <v-card-title class="text-h5 py-3 text-center">
          No Accounts Added yet.
        </v-card-title>
      </v-card>
    </template>

    <template v-if="thirdPartyAccounts && thirdPartyAccounts.length > 0">
      <v-card variant="outlined">
        <v-card-title class="text-h5 py-3 text-center">
          Accounts
        </v-card-title>
        <v-table height="300px">
          <thead>
          <tr>
            <th class="text-left">Coffer Name</th>
            <th class="text-left">Third Party Full Name</th>
            <th class="text-left">Currency</th>
            <th class="text-left">Ledger Code</th>
            <th class="text-left">In Transit Credit</th>
            <th class="text-left">In Transit Debit</th>
            <th class="text-left">Actual Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(account, index) in thirdPartyAccounts" :key="index">
            <td>{{ account.cofferName }}</td>
            <td>{{ account.thirdParty.fullName }}</td>
            <td>{{ account.currency.symbol }}</td>
            <td>{{ account.accountType.generalLedger.code }}</td>
            <td>{{ formatNumber(account.inTransitCreditAmount) }}</td>
            <td>{{ formatNumber(account.inTransitDebitAmount) }}</td>
            <td>{{ formatNumber(account.actualAmount) }}</td>
          </tr>
          </tbody>
        </v-table>

      </v-card>
    </template>

  </v-container>

  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>

</template>

<style scoped>

</style>