<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium">Money Transfers Report</h3>
      <v-row class="mb-2">
        <v-col cols="12" md="6" sm="8">
          <div class="search-bar">
            <v-text-field
                class="adjust-label search-field"
                label="Search"
                v-model="searchValue"
                @keyup.enter="searchForItem(searchValue)"
            >
              <template v-slot:prepend-inner>
                <v-icon
                    icon="mdi-magnify"
                    variant="tonal"
                    color="button_color"
                    class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>

            <v-checkbox
                class="ml-5"
                v-model="openRequests"
                color="button_color"
                label="Open Requests"
                @change="getRequests"
            ></v-checkbox>
          </div>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
              color="button_color"
              @click="QMoneyTransfersModal = true"
              icon="mdi-help"
              class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="requestsDetails">
              <v-icon>mdi-export</v-icon>
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
          :headers="headers"
          :items="tableItems"
          v-model:serverOptions="serverOptions"
          :server-items-length="totalItems"
          :loading="loading"
          body-text-direction="center"
          header-text-direction="center"
          class="adjust-label custom-data-table"
          border-cell
          :body-row-class-name="bodyRowClassNameFunction"
          no-hover
      >
        <template #pagination="{}">
          <v-btn
              density="compact"
              class="ma-4"
              :disabled="serverOptions.page === 1"
              @click="goToPrevPage"
          >prev page
          </v-btn>
          <v-btn
              density="compact"
              class="ma-4"
              :disabled="serverOptions.page === numberOfPages"
              @click="goToNextPage"
          >next page
          </v-btn>
        </template>

        <template #item-id="item">
          <v-icon @click="goToRequest(item)"
          ><span class="modal-icon">{{ item.id }}</span></v-icon
          >
        </template>

        <template #item-amount="{ amount }">
          <div class="currency-amount">
            <span class="currency">
              {{ splitCurrencyValue(amount).currency }}
            </span>
            <span class="text-right">
              {{ splitCurrencyValue(amount).value }}
            </span>
          </div>
        </template>

        <template #item-update="item">
          <Toggle_Confirmation
              @editTransfer="goToEdit(item)"
              @acceptTransfer="acceptTransfer(item)"
              @denyTransfer="denyTransfer(item)"
              @reconcileTransfer="goToEdit(item)"
              :item="item"
              :action="action"
              :reconciliation-possible="item.editable"
          >
          </Toggle_Confirmation>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="requestModal" width="auto">
      <money_transfer_request_modal></money_transfer_request_modal>
    </v-dialog>
    <v-dialog v-model="QMoneyTransfersModal" width="auto">
      <Q_MoneyTransfers_Modal_V01></Q_MoneyTransfers_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import Q_MoneyTransfers_Modal_V01 from "@/components/ModalQ/Q_MoneyTransfers_Modal_V01.vue";
import money_transfer_requestService from "@/services/money_transfer_request.service";
import money_transfer_request_modal from "../../Modals/transfer/Money_Transfer_Request_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import {
  setPage,
  messageResultHandler,
  pageResponseHandler,
  formatNumber,
} from "@/utils/helpers";
import {mapGetters} from "vuex";
import Toggle_Confirmation from "@/components/MoneyTransfer/Toggle_Confirmation.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import {getMoneyTransferRequestType} from "@/utils/NewFunctions/getEventTypeDisplay";
import getStatusDisplay from "@/utils/NewFunctions/getStatusDisplay";
import {shortenStringForTable} from "@/utils/NewFunctions/utils.js";

export default {
  name: "all_requests_v01",
  components: {
    Error_Modal,
    Success_Modal,
    money_transfer_request_modal,
    Q_MoneyTransfers_Modal_V01,
    Broken_Page_V01,
    Toggle_Confirmation,
  },
  data() {
    return {
      headers: [
        {text: "Event", value: "id"},
        {text: "Ref.", value: "referenceNo"},
        {text: "CU Acc", value: "customerCoffer"},
        {text: "TR Acc", value: "trusteeCoffer"},
        {text: "PA Acc", value: "payerPayeeCoffer"},
        {text: "Amount", value: "amount"},
        {text: "Type", value: "requestTypeDisplay"},
        {text: "Status", value: "status"},
        {text: "Create Date", value: "createDate"},
        {text: "Modified", value: "modifiedDate"},
        {text: "User", value: "user"},
        {text: "Update", value: "update"},
      ],
      tableItems: [],
      message: null,
      broken_page: false,
      success: false,
      action: false,
      error: false,
      requests: [],
      currentPage: 0,
      totalItems: 0,
      accountCofferName: null,
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
      openRequests: false,
      loading: false,
      requestModal: false,
      statusCriteria: "ALL",
      showStatusFilter: false,
      QMoneyTransfersModal: false,
      searchValue: "",
      debounceTimer: null,
    };
  },

  methods: {
    bodyRowClassNameFunction(item) {
      switch (item.status) {
        case "Completed":
          return "row-completed";
        case "Canceled":
        case "Deleted":
          return "row-canceled";
        case "Initiated":
          return "row-process";
        case "Admin Approved":
        case "Waiting for Approval":
        case "Trustee Confirmed":
        case "Transaction Requested":
          return "row-other-status";
        case "Approved":
          return "row-approved";
        default:
          return "";
      }
    },
    splitCurrencyValue(currencyString) {
      const parts = currencyString.split(" ");
      return {
        currency: parts[0],
        value: parts[1],
      };
    },
    goToPrevPage() {
      this.serverOptions.page = this.serverOptions.page - 1;
    },
    goToNextPage() {
      this.serverOptions.page = this.serverOptions.page + 1;
    },
    async searchForItem(searchValue) {
      if (this.isOnlySpaces(searchValue)) {
        this.searchValue = '';
        return;
      }
      searchValue = searchValue.replace(/^\s+|\s+$/g, '');
      const {page, rowsPerPage} = this.serverOptions
      const isNumber = /^\d+(\.\d+)?$/.test(searchValue);
      const isString = /^[a-zA-Z ]+$/.test(searchValue);
      let numericalSearchField = isNumber ? searchValue : null;
      let searchField = isString ? searchValue : null;
      this.loading = true;
      let response = pageResponseHandler(
          await money_transfer_requestService.searchForMoneyTransfers(page - 1, rowsPerPage, searchField, numericalSearchField)
      );
      this.loading = false;
      if (response.success) {
        this.totalItems = response.totalItems;
        this.numberOfPages = response.numberOfPages;
        this.requests = response.data;
        this.tableItems = this.requestsDetails;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    async goToEdit(request) {
      let response = messageResultHandler(
          await money_transfer_requestService.getById(request.id)
      );
      if (response.success) {
        let requestToSet = response.data;
        this.$store.dispatch("setChosenRequest", requestToSet);
        this.$store.dispatch("setIsEditingRequest", true);
        switch (request.requestType) {
          case "CASH_RECEIVE":
            setPage("cash receive");
            break;
          case "CASH_SEND":
            setPage("cash send");
            break;
          case "BANK_RECEIVE":
            setPage("bank receive");
            break;
          case "BANK_SEND":
            setPage("bank send");
            break;
          default:
            // code block
        }
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async goToRequest(request) {
      let response = messageResultHandler(
          await money_transfer_requestService.getById(request.id)
      );
      if (response.success) {
        let requestToSet = response.data;
        this.$store.dispatch("setChosenRequest", requestToSet);
        this.requestModal = true;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async getRequests() {
      let response;
      this.loading = true;
      if (!this.openRequests) {
        response = pageResponseHandler(
            await money_transfer_requestService.getAll(
                this.serverOptions.page - 1,
                this.serverOptions.rowsPerPage,
                this.accountCofferName
            )
        );
      } else {
        response = pageResponseHandler(
            await money_transfer_requestService.getAllIncomplete(
                this.serverOptions.page - 1,
                this.serverOptions.rowsPerPage,
                this.accountCofferName
            )
        );
      }
      if (response.success) {
        this.totalItems = response.totalItems;
        this.numberOfPages = response.numberOfPages;
        this.requests = response.data;
        this.tableItems = this.requestsDetails;
        this.loading = false;
      } else {
        this.broken_page = true;
      }
    },
    async acceptTransfer(item) {
      this.action = true;
      let response = messageResultHandler(
          await money_transfer_requestService.requestForAccountsUpdate(item.id)
      );
      this.action = false;
      if (response.success) {
        item.status = "Acc Updated";
        let index = this.tableItems.findIndex(
            (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async denyTransfer(item) {
      this.action = true;
      let response = messageResultHandler(
          await money_transfer_requestService.changeRequestStatusToCanceled(
              item.id
          )
      );
      this.action = false;
      if (response.success) {
        item.status = "Canceled";
        let index = this.tableItems.findIndex(
            (lookingForItem) => lookingForItem.id === item.id
        );
        this.tableItems[index] = item;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    isOnlySpaces(str) {
      return str.trim().length === 0;
    }
  },
  async mounted() {
    emitter.on("request_modal_close", (value) => {
      this.requestModal = false;
      this.message = value;
      this.success = true;
      this.getRequests();
    });
    await this.getRequests();
  },
  computed: {
    ...mapGetters(["page"]),

    requestsDetails() {
      return this.requests.map((request) => {
        let transactionTypeDisplay = getMoneyTransferRequestType(
            request.requestType
        );
        let transactionStatus = getStatusDisplay(request.status);
        return {
          id: request.id,
          description: request.description,
          amount: request.currency.symbol + " " + formatNumber(request.amount),
          requestType: request.requestType,
          referenceNo: request.referenceNo,
          customerCoffer: request.customerAccount?.cofferName || null,
          trusteeCoffer: request.trusteeAccount?.cofferName || null,
          payerPayeeCoffer: request.secondaryThirdPartyAccount ?
              shortenStringForTable(request.secondaryThirdPartyAccount.cofferName) : null,
          modifiedDate: new Date(request.modifiedDate).toISOString().split("T")[0],
          createDate: new Date(request.createDate).toISOString().split("T")[0],
          user: request.createdBy,
          status: transactionStatus,
          requestTypeDisplay: transactionTypeDisplay,
          editable: request.editable
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
  },
  watch: {
    searchValue(newVal) {
      if (newVal === "") {
        this.accountCofferName = "";
        this.serverOptions.page = 1;
        this.getRequests();
      } else {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.searchForItem(newVal);
        }, 500);
      }
    },
    serverOptions: {
      handler() {
        if (this.searchValue !== null && this.searchValue !== "")
          this.searchForItem(this.searchValue)
        else
          this.getRequests();
      },
      deep: true,
    },
    openRequests() {
      this.getRequests();
    },
  },
};
</script>
<style>
</style>