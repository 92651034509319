<template>
  <div>
    <v-container fluid>
      <Vue3MultiStepper
          v-if="currentStatus !== 'CANCELED'"
          style="margin-top: -30px"
          v-model:step="step"
          :tabs="tabs"
          :primaryColor1="primaryColor1"
          :primaryColor2="primaryColor2"
          showNavigation="false"
          doneText="Finish"
      >
      </Vue3MultiStepper>
      <h3 class="font-weight-medium" :style="{ top: '-50px', position: 'relative' }">{{ internalTransferTypeFormat(internalTransferType) }}</h3>

      <internal-transfer-form
          :current-status="this.currentStatus"
          :internal-transfer-type="this.internalTransferType"
          :is_editing_internal_transfer="this.is_editing_internal_transfer"
          :chosen_internal_transfer="this.chosen_internal_transfer"
          :request-id="requestId"
          @returnFormData="captureForms"
      >
      </internal-transfer-form>

      <v-form>
        <v-row class="mt-4">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4" sm="4" xs="12">
                <v-form ref="file_upload_form">
                  <v-file-input
                      v-model="selectedFiles"
                      label="Select File"
                      multiple
                      class="adjust-label"
                      :accept="acceptedFileTypes"
                      :rules="multipleFileRequiredRule"
                  >
                  </v-file-input>
                </v-form>
              </v-col>
              <v-col cols="auto">
                <v-btn color="button_color" class="mt-4">Upload</v-btn>
              </v-col>
            </v-row>
            <v-list>
              <v-list-item v-for="(file, index) in selectedFiles" :key="index">
                <v-icon @click="remove(file)">mdi-delete</v-icon>
                {{ file.name }}
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-toolbar-title class="ml-4"> all files:</v-toolbar-title>
            <v-list class="mt-4">
              <v-list-item v-for="(file, index) in files" :key="index">
                <v-icon>mdi-download</v-icon>
                {{ file.name }}
              </v-list-item>
            </v-list>
          </v-container>
        </v-row>
      </v-form>

      <actions-bar
          @generateTransaction="generateTransaction"
          @firstPartyConfirmed="firstPartyConfirmed"
          @requestForCompletion="requestForCompletion"
          @register="addRequestFromActionBar"
          @reconcile="doReconciliation"
          @delete="this.requestForDeletion=true"
          :currentStatus="currentStatus"
          :error="error"
          :step="step">
      </actions-bar>


      <v-dialog v-model="requestForApproveModal">
        <accept-or-decline-with-info-modal
            :screenWidth="screenWidth"
            :color="primaryColor1"
            title="Not enough balance"
            @decline="cancelRequest"
            @accept="requestForApprove"
            accept-text="Request For Approval"
            decline-text="Cancel Request"
        ></accept-or-decline-with-info-modal>
      </v-dialog>

      <v-dialog v-model="requestForDeletion">
        <accept-or-decline-with-info-modal
            :screenWidth="screenWidth"
            :color="primaryColor1"
            @decline="requestForDeletion = false"
            @accept="deleteRequest"
            title="Are you sure you want to delete this request?"
            accept-text="Delete Request"
            decline-text="Close"
        ></accept-or-decline-with-info-modal>
      </v-dialog>
    </v-container>
  </div>
  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import {
  formatNumber,
  formatNumberInternal,
  getIndex,
  handleKeyDown,
  messageResultHandler,
  setPage,
} from "@/utils/helpers";
import {
  multipleFileRequiredRule,
} from "@/utils/rules";
import {ACCEPTED_FILE_TYPES, INTERNAL_TRANSFER_TABS,} from "@/config/constants";
import {mapGetters} from "vuex";
import {Vue3MultiStepper} from "vue3-multi-stepper";
import internal_transferService from "@/services/internal_transfer.service";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import {useTheme} from "vuetify";
import AcceptOrDeclineWithInfoModal from "@/components/Utils/Modals/accept-or-decline-with-info-modal.vue";
import ActionsBar from "@/components/MoneyTransfer/transfer-forms/InternalTransfer/actions-bar.vue";
import InternalTransferForm
  from "@/components/MoneyTransfer/transfer-forms/InternalTransfer/internal-transfer-form.vue";

export default {
  name: "internal_transfer",
  props: ["internalTransferType"],
  components: {
    InternalTransferForm,
    ActionsBar,
    AcceptOrDeclineWithInfoModal,
    Error_Modal,
    Success_Modal,
    Vue3MultiStepper,
  },

  data() {
    return {
      tabs: INTERNAL_TRANSFER_TABS,
      error: false,
      success: false,
      message: null,
      step: 1,
      currentStatus: "NOT INITIATED",
      requestId: null,
      amount: null,
      selectedFiles: [],
      files: [],
      requestForApproveModal: false,
      requestForDeletion: false,
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      multipleFileRequiredRule: multipleFileRequiredRule,
      screenWidth: window.innerWidth,
      registerForm: null,
      reconcileForm: null,
    };
  },
  methods: {
    formatNumber,
    captureForms({internalTransferRegistrationForm, internalTransferReconciliationForm}) {
      this.registerForm = internalTransferRegistrationForm
      this.reconcileForm = internalTransferReconciliationForm
    },
    internalTransferTypeFormat(internalTransferType) {
      let transactionTypeDisplay = "";
      if (internalTransferType === "TRUSTEE_TO_TRUSTEE") {
        transactionTypeDisplay = "Trustee to Trustee";
      } else if (internalTransferType === "CUSTOMER_TO_CUSTOMER") {
        transactionTypeDisplay = "Customer to Customer";
      }
      return transactionTypeDisplay;
    },
    formatNumberInternal,
    remove(file) {
      this.selectedFiles = this.selectedFiles.filter((item) => item !== file);
    },
    async addRequestFromActionBar({exit, showSuccess, generateTransaction}) {
      await this.addRequest(exit, showSuccess);
      if (!exit && !this.error && generateTransaction) {
        await this.generateTransaction();
      }
    },
    async doReconciliation() {
      let form = this.reconcileForm;
      if (!form) return
      let response = messageResultHandler(await internal_transferService.requestForReconciliation(form));
      this.message = response.message;
      if (response.success) {
        this.success = true;
        setTimeout(() => {
          setPage("internal report");
        }, 900);
      } else {
        this.error = true;
      }
    },
    async addRequest(exit = false, showSuccess = true) {
      let form = this.registerForm;
      if (!form) return
      let response = messageResultHandler(
          await internal_transferService.createInternalTransfer(form)
      );
      this.message = response.message;
      if (response.success) {
        if (showSuccess)
          this.success = true;
        setTimeout(() => {
          if (exit) {
            setPage("internal report");
          }
        }, 1000);
        this.requestId = response.data.id;
        this.currentStatus = "INITIATED";
        this.step = 2;
      } else {
        this.error = true;
      }
    },
    async generateTransaction(exit = false) {
      let response = messageResultHandler(await internal_transferService.generateTransaction(this.requestId));
      this.message = response.message;
      if (response.success) {
        setTimeout(() => {
          if (exit) {
            setPage("internal report");
          }
        }, 1000);
        this.success = true;
        this.currentStatus = "TRANSACTION_REQUESTED";
        this.step = 5;
      } else if (
          response.message === "Payer Party balance is not enough" ||
          response.message === "Payee Party balance is not enough"
      ) {
        this.requestForApproveModal = true;
      } else {
        this.error = true;
      }
    },
    async requestForApprove() {
      let response = messageResultHandler(await internal_transferService.changeRequestStatusToWaitingForApprove(this.requestId));
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.requestForApproveModal = false;
        this.currentStatus = "WAITING_FOR_APPROVAL";
        this.step = 3;
      } else {
        this.error = true;
      }
    },
    async cancelRequest() {
      let response = messageResultHandler(
          await internal_transferService.changeRequestStatusToCanceled(
              this.requestId
          )
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.requestForApproveModal = false;
        this.currentStatus = "CANCELED";
      } else {
        this.error = true;
      }
    },
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    async firstPartyConfirmed() {
      let response = messageResultHandler(
          await internal_transferService.firstPartyConfirmed(this.requestId)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.currentStatus = "FIRST_PARTY_CONFIRMED";
        this.step = 6;
      } else {
        this.error = true;
      }
    },
    async requestForCompletion() {
      let response = messageResultHandler(
          await internal_transferService.requestForCompletion(this.requestId)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.currentStatus = "COMPLETED";
        this.step = 7;
      } else {
        this.error = true;
      }
    },
    async deleteRequest() {
      this.requestForDeletion = false;
      let response = messageResultHandler(
          await internal_transferService.deleteById(this.requestId)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        setTimeout(() => {
          setPage("internal report");
        }, 1000);
      } else {
        this.error = true;
      }
    },
  },
  async mounted() {
    if (this.is_editing_internal_transfer) {
      this.requestId = this.chosen_internal_transfer.id;
      this.currentStatus = this.chosen_internal_transfer.status;
      this.step = getIndex(this.tabs, this.currentStatus, 1);
    }
  },

  computed: {
    ...mapGetters([
      "chosen_internal_transfer",
      "is_editing_internal_transfer",
      "page",
    ]),

    primaryColor1() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor1;
    },
    primaryColor2() {
      const theme = useTheme();
      return theme.current.value.colors.primaryColor2;
    },
  },
  watch: {},
  beforeUnmount() {
    this.$store.dispatch("setChosenInternalTransfer", null);
    this.$store.dispatch("setIsEditingInternalTransfer", false);
  },
};
</script>
<style>
.controls {
  display: none !important;
}
</style>


