<template>
  <v-icon @click="toggleTheme">mdi-white-balance-sunny</v-icon>
</template>

<script>
import { useTheme } from "vuetify";

export default {
  setup() {
    const theme = useTheme();

    return {
      theme,
      toggleTheme: () =>
        (theme.global.name.value = theme.global.current.value.light_mode
          ? "dark_mode"
          : "light_mode"),
    };
  },
};
</script>
