<template>
  <div>
    <v-container v-if="!broken_page" fluid style="background-color: white">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
              class="adjust-label"
              label="Search"
              v-model="searchValue"
          ></v-text-field>
        </v-col>
      </v-row>
      <download-excel :data="thirdPartyAccounts">
        <v-icon>mdi-export</v-icon>
      </download-excel>
      <EasyDataTable
          class="datatable-container"
          :headers="headers"
          :items="thirdPartyAccountsDetails"
          :search-field="searchField"
          :search-value="searchValue"
          alternating
          border-cell
      >
        <template #item-choose="item">
          <v-icon @click="chooseAccount(item)">mdi-check-circle</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>
  </div>
</template>
<script>
import accountService from "@/services/account.service";
import {httpResponseHandler} from "@/utils/helpers";
import emitter from "@/plugins/mitt";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";

export default {
  name: "list_of_accounts_modal_v01",
  components: {
    Error_Modal,
    Broken_Page_V01,
  },
  props: ["thirdPartyId", "currencyId", "dir", "thirdPartyType"],
  data() {
    return {
      headers: [
        {text: "Coffer Name", value: "cofferName"},
        {text: "Third Party", value: "thirdPartyName"},
        {text: "Currency", value: "currencySymbol"},
        {text: "Account Group", value: "accountGroup"},
        {text: "Account Ledger", value: "accountLedgerCode"},
        {text: "Choose", value: "choose"},
      ],
      searchField: "",
      searchValue: "",
      message: null,
      broken_page: false,
      success: false,
      error: false,
      thirdPartyAccounts: [],
    };
  },

  methods: {
    async getAccounts() {
      let response = null;
      if (this.currencyId && this.thirdPartyId) {
        response = httpResponseHandler(
            await accountService.getThirdPartyCurrencyAccounts(
                this.currencyId,
                this.thirdPartyId
            )
        );
      } else if (this.thirdPartyId) {
        response = httpResponseHandler(
            await accountService.getThirdPartyAccountsById(this.thirdPartyId)
        );
      } else {
        response = httpResponseHandler(
            await accountService.getAllThirdPartiesAccountByThirdPartyType(this.thirdPartyType,this.currencyId)
        );
      }

      console.log("list of accounts", response);
      if (response.success) {
        this.thirdPartyAccounts = response.data;
        if (this.thirdPartyAccounts.length === 0 && this.currencyId != null) {
          this.message = "The selected third party does not have any account for this currency, you should first add account";
          this.error = true;
        }
      } else {
        this.broken_page = true;
      }
    },
    chooseAccount(account) {
      emitter.emit("chosenAccount", account);
    },
  },

  async mounted() {
    await this.getAccounts();
  },
  computed: {
    thirdPartyAccountsDetails() {
      return this.thirdPartyAccounts.map((account) => ({
        id: account.id,
        thirdPartyName: account.thirdParty.fullName,
        thirdParty: account.thirdParty,
        currency: account.currency,
        fullName: account.fullName,
        cofferName: account.cofferName,
        currencySymbol: account.currency.symbol,
        currencyObject: account.currency,
        accountGroup: account.accountType.accountGroup,
        accountLedgerCode: account.accountType.generalLedger.code,
        actualAmount: account.actualAmount,
        dir: this.dir,
      }));
    },
  },
  watch: {
    error(newValue) {
      if (!newValue) {
        emitter.emit("close_t_c_account");
      }
    },
  },
};
</script>
