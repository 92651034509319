import axios from "axios";
import {authHeader, authFormDataHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";

class moneyTransferRequestService {
    async createMoneyTransferRequest(transfer_request) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/create",
                transfer_request,
                {
                    headers: authHeader(),
                }
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async doReconciliation(form) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/doReconciliation",
                form,
                {
                    headers: authHeader(),
                }
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }


    async getAll(page, size) {
        try {
            return await axios.get(BASE_URL + "transferrequest/getAll", {
                params: {
                    page: page,
                    size: size,
                },
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getAllIncomplete(page, size) {
        try {
            return await axios.get(BASE_URL + "transferrequest/getAllIncomplete", {
                params: {
                    page: page,
                    size: size,
                },
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getById(id) {
        try {
            return await axios.get(BASE_URL + "transferrequest/get", {
                headers: authHeader(),
                params: {requestId: id},
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async deleteById(id) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/delete",
                {requestId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async changeRequestStatusToWaitingForApprove(id) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/status/requestForApprove",

                {requestId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async changeRequestStatusToApproved(id) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/status/approve",

                {requestId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async changeRequestStatusToCanceled(id) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/status/cancel",

                {requestId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async generateTransaction(id) {
        console.log("request id, requesting transactions", id);
        console.log(BASE_URL + "transferrequest/status/transactionrequest");
        try {
            let response = await axios.post(
                BASE_URL + "transferrequest/status/transactionrequest",

                {requestId: id},
                {headers: authFormDataHeader()}
            );
            console.log("the response is here", response);
            return response;
        } catch (error) {
            console.log("the exception is here", error);
            return {
                status: 500,
                data: null,
                message: error.response.data.message,
            };
        }
    }

    async trusteeConfirmed(id) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/status/trusteeConfirmed",

                {requestId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async customerConfirmed(id) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/status/complete",

                {requestId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.response.data.message};
        }
    }

    async requestForAccountsUpdate(id) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/status/accountsUpdate",

                {requestId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.response.data.message};
        }
    }


    async createPastMoneyTransferRequest(transfer_request) {
        try {
            return await axios.post(
                BASE_URL + "transferrequest/pastMoneyTransfer",
                transfer_request,
                {
                    headers: authHeader(),
                }
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }
    async searchForMoneyTransfers(page,size,searchField,numericalSearchField){
        try {
            return await axios.get(BASE_URL + "transferrequest/search", {
                params: {
                    page: page,
                    size: size,
                    searchField: searchField,
                    numericalSearchField: numericalSearchField
                },
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }
}

export default new moneyTransferRequestService();
