import axios from "axios";
import {authFormDataHeader, authHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";

class SafeBoxOperationService {
    async executeSafeBoxOperation(safeBoxOperationForm) {
        try {
            return await axios.post(
                BASE_URL + "safeBoxOperation/execute",
                safeBoxOperationForm,
                {
                    headers: authHeader(),
                }
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async requestForAccountUpdate(safeBoxOperationId) {
        try {
            return await axios.post(
                BASE_URL + "safeBoxOperation/accountsUpdate",
                {
                    safeBoxOperationId: safeBoxOperationId,
                },
                {
                    headers: authFormDataHeader(),
                }
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async cancelSafeBoxOperation(safeBoxOperationId) {
        try {
            return await axios.post(
                BASE_URL + "safeBoxOperation/cancel",
                {
                    safeBoxOperationId: safeBoxOperationId,
                },
                {
                    headers: authFormDataHeader(),
                }
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getAllSafeBoxOperation(page, size) {
        try {
            return await axios.get(BASE_URL + "safeBoxOperation/getAll", {
                params: {
                    page: page,
                    size: size,
                },
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getSafeBoxOperationById(id) {
        try {
            const response = await axios.get(BASE_URL + "safeBoxOperation/get", {
                params: {
                    safeBoxOperationId: id,
                },
                headers: authHeader(),
            });
            return {
                status: response.status,
                data: response.data,
                message: response.message,
            };
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }
}

export default new SafeBoxOperationService();
