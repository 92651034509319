<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-card class="mx-auto mt-10" max-width="344" color="side_color" @click="setPage('customer_acc')">
          <v-card-text>
            <div>{{ $t("Bank and Cash") }}</div>
            <p class="text-h5 text--primary">{{ $t('Customer Accounts') }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
                variant="text"
                color="button_color"
                @click="setPage('customer_acc')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card class="mx-auto mt-15" max-width="344" color="side_color" @click="setPage('customer_entries')">
          <v-card-text>
            <div>{{ $t("Bank and Cash") }}</div>
            <p class="text-h5 text--primary">{{ $t("Customer Entries") }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
                variant="text"
                color="button_color"
                @click="setPage('customer_entries')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-col>
      <v-col cols="12" md="6">
        <div style="
              display: flex;
              align-items: center; 
              flex-direction: column; 
              margin-top: 2rem;">

          <h1 class="font-weight-medium">Bank And Cash</h1>
          <v-img
              :width="600"
              aspect-ratio="1"
              cover
              :src="getImageUrl('Money-o', 'svg')"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="12" md="3">
        <v-card class="mx-auto mt-10" max-width="344" color="side_color" @click="setPage('trustee_acc')">
          <v-card-text color="grey-lighten-4">
            <div>{{ $t("Bank and Cash") }}</div>
            <p class="text-h5 text--primary">{{ $t("Trustee Accounts") }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
                variant="text"
                color="button_color"
                @click="setPage('trustee_acc')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card color="side_color mt-15" class="mx-auto" max-width="344" @click="setPage('trustee_entries')">
          <v-card-text>
            <div>{{ $t("Bank and Cash") }}</div>
            <p class="text-h5 text--primary">{{ $t("Trustee Entries") }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
                variant="text"
                color="button_color"
                @click="setPage('trustee_entries')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>


      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {setPage} from "@/utils/helpers";
import {getImageUrl} from "@/utils/NewFunctions/utils.js";

export default {
  name: "money_transfer_home_page_v01",
  methods: {
    getImageUrl,
    setPage(page) {
      setPage(page);
    },
  },
  data() {
  }
};
</script>
  