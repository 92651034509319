<template>
  <v-sheet max-width="1200" min-width="900">
    <div style="padding: 20px">
      <div class="border-1">
        <h4 class="border-h4">Group Deal Summary</h4>
        <div
          class="grid-card-2"
          ref="pdfContent"
          style="text-align: left; direction: ltr"
        >
          <div class="grid-card-2 mt-10">
            <p class="bold">Event:</p>
            <p class="m-l-35">{{ chosen_group_deal.id }}</p>
            <p class="bold">Status:</p>
            <p class="m-l-35">
              {{ chosen_group_deal.status? getStatusDisplay(chosen_group_deal.status) : "-" }}
            </p>
            <p class="bold">Customer Name:</p>
            <p class="m-l-35">{{ chosen_group_deal.customer.fullName }}</p>
            <p class="bold">Receive Amount:</p>
            <p class="m-l-35">
              {{
                formatNumber(chosen_group_deal.receiveAmount) +
                " " +
                chosen_group_deal.receiveCurrency.symbol
              }}
            </p>
            <p class="bold">Primary Send Amount:</p>
            <p class="m-l-35">
              {{
                formatNumber(chosen_group_deal.primarySendAmount) +
                " " +
                chosen_group_deal.sendCurrency.symbol
              }}
            </p>
            <p class="bold">Secondary Send Amount:</p>
            <p class="m-l-35">
              {{
                formatNumber(chosen_group_deal.secondarySendAmount)
                  ? formatNumber(chosen_group_deal.secondarySendAmount) +
                    " " +
                    chosen_group_deal.sendCurrency.symbol
                  : "-"
              }}
            </p>
            <p class="bold" >Our Receiving:</p>
            <p class="m-l-35">
              {{ chosen_group_deal.ourReceivingAccountCofferName }}
            </p>
            <p class="bold" >Customer Sending:</p>
            <p class="m-l-35">
              {{ chosen_group_deal.customerSendingAccountCofferName }}
            </p>
            <p class="bold" >Our Primary Sending:</p>
            <p class="m-l-35">
              {{ chosen_group_deal.ourPrimarySendingAccountCofferName }}
            </p>
            <p class="bold">Our Secondary Sending:</p>
            <p class="m-l-35">
              {{
                chosen_group_deal.ourSecondarySendingAccountCofferName
                  ? chosen_group_deal.ourSecondarySendingAccountCofferName
                  : "-"
              }}
            </p>
            <p class="bold">Customer Primary Receiving:</p>
            <p class="m-l-35">
              {{ chosen_group_deal.customerPrimaryReceivingAccountCofferName }}
            </p>
          </div>
          <div class="grid-card-2 ml-10 mt-10">
            <p class="bold">Customer Secondary Receiving:</p>
            <p class="m-l-35">
              {{
                chosen_group_deal.customerSecondaryReceivingAccountCofferName
                  ? chosen_group_deal.customerSecondaryReceivingAccountCofferName
                  : "-"
              }}
            </p>

            <p class="bold">Trustee TET Incoming:</p>
            <p class="m-l-35">
              {{ chosen_group_deal.trusteeTETIncomingAccountCofferName }}
            </p>
            <p class="bold">Trustee TET Outgoing:</p>
            <p class="m-l-35">
              {{ chosen_group_deal.trusteeTETOutgoingAccountCofferName }}
            </p>
            <p class="bold">TET Bought Amount:</p>
            <p class="m-l-35">{{ formatNumber(chosen_group_deal.tetBoughtAmount) + " TET" }}</p>
            <p class="bold">TET Sold Amount:</p>
            <p class="m-l-35">{{ formatNumber(chosen_group_deal.tetSoldAmount) + " TET" }}</p>
            <p class="bold">TET Buy Rate:</p>
            <p class="m-l-35">{{ chosen_group_deal.tetBuyRate }}</p>
            <p class="bold">TET Sell Rate:</p>
            <p class="m-l-35">{{ chosen_group_deal.tetSellRate }}</p>
            <p class="bold">Total Send Amount:</p>
            <p class="m-l-35">
              {{
                formatNumber(chosen_group_deal.totalSendAmount) +
                " " +
                chosen_group_deal.sendCurrency.symbol
              }}
            </p>
            
            
         
            
            <p class="bold">TET Into Our Wallet:</p>
            <p class="m-l-35">{{ formatNumber(chosen_group_deal.tetIntoOurWallet) }}</p>
            <p class="bold">Create Date:</p>
            <p class="m-l-35">
              {{
                new Date(chosen_group_deal.createDate)
                  .toISOString()
                  .split("T")[0]
              }}
            </p>
            <p class="bold">Modfied Date:</p>
            <p class="m-l-35">
              {{
                new Date(chosen_group_deal.modifiedDate)
                  .toISOString()
                  .split("T")[0]
              }}
            </p>
          </div>
        </div>
        <v-row justify="end">
          <v-btn class="mr-4 top-20" color="button_color" @click="generatePDF"
            >PDF</v-btn
          >
        </v-row>
      </div>
    </div>
  </v-sheet>

  <Error_Modal
    :error="error"
    :message="message"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import { formatNumber } from "@/utils/helpers.js";
import getStatusDisplay from "@/utils/NewFunctions/getStatusDisplay.js";

export default {
  name: "request_modal_v01",
  components: {
    Error_Modal,
  },
  data() {
    return {
      request: null,
      currency: null,
      customer: null,
      amount: null,
      createDate: null,
      modifiedDate: null,
      status: null,
      requestType: null,
      trusteeCommissionAmount: null,
      trusteeCommissionPercent: null,
      ellingtonCommissionPercent: null,
      ellingtonCommissionAmount: null,
      message: null,
      error: false,
    };
  },
  methods: {
    formatNumber,
    getStatusDisplay,

    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: "groupdealSummary.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf(element, options);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["chosen_group_deal", "page"]),
  },
};
</script>
<style>
.bold{
  font-weight: 600;
}
</style>