<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium">Crypto Deal</h3>
      <v-form
          class="adjust-label mt-11"
          ref="third_crypto_form"
          :disabled="!(currentStatus === 'NOT START')"
      >
        <v-row>
          <v-col cols="12" md="3" sm="12" xs="12">
            <v-tab
                :disabled="!(currentStatus === 'NOT START')"
                @click="thirdPartyListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field
                label="Third Party Name"
                v-model="thirdPartyFullName"
                @keyup.backspace="clearThirdParty"
                @keydown="handleKeyDown"
                :rules="requiredRule"
            ></v-text-field>
            <v-autocomplete
                label="How Exchange rate should calc"
                v-model="exchangeRateCalc"
                :items="exchangeRateCalcTypes"
                item-title="name"
                item-value="id"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" sm="12" xs="12" class="mt-25px">
            <v-text-field
                :disabled="!(currentStatus === 'NOT START')"
                label="Reference No."
                v-model="referenceNo"
            ></v-text-field>
            <v-text-field
                v-maska:[exchangeRateOption]
                :label="$t('exchange rate')"
                v-model="exchangeRateFormat"
                readonly
                :disabled="!(currentStatus === 'NOT START')"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="12" xs="12" class="mt-25px">
            <v-autocomplete
                label="Receive Currency"
                v-model="selectedFirstCurrency"
                :items="currencies"
                item-title="name"
                item-value="name"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>
            <v-autocomplete
                label="Send Currency"
                v-model="selectedSecondCurrency"
                :items="currencies"
                item-title="name"
                item-value="name"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" md="3" sm="12" xs="12" class="mt-25px">
            <v-text-field
                v-maska:[moneyOption]
                label="We bought (Amount)"
                v-model="WeBought"
                :rules="requiredRule"
            ></v-text-field>

            <v-text-field
                v-maska:[moneyOption2]
                label="We sold (Amount)"
                v-model="WeSold"
                :rules="requiredRule"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-4" justify="end">
          <v-btn
              class="mr-1"
              color="button_color"
              @click="addThirdPartyBtn"
              :disabled="!(currentStatus === 'NOT START')"
          >
            register
          </v-btn>
        </v-row>
      </v-form>
      <p style="border: 0.2px solid #f7f5f5"></p>
      <v-form
          elevation="3"
          class="adjust-label"
          ref="receive_side_form"
          :disabled="!(currentStatus === 'INITIATED')"
      >
        <h4 class="font-weight-medium mb-6 mt-2" >Receive Side</h4>
        <v-row>
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
                :disabled="!(currentStatus === 'INITIATED')"
                @click="ourAccountReceiveSideListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field
                label="Our Account"
                v-model="ourAccountFullNameReceiveSide"
            ></v-text-field>
            <v-tab
                :disabled="!(currentStatus === 'INITIATED')"
                @click="thirdPartyAccountReceiveSideListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field
                label="Third Party Account"
                v-model="thirdPartyAccountFullNameReceiveSide"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
                :disabled="!(currentStatus === 'INITIATED')"
                @click="ourPAReceiveSideListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field label="Our PA" v-model="ourPAFullName">
            </v-text-field>
            <v-tab
                :disabled="!(currentStatus === 'INITIATED')"
                @click="targetPAReceiveSideListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field label="Target PA" v-model="targetPAFullName">
            </v-text-field>
          </v-col>

          <v-col cols="3" md="3" sm="12" xs="12" class="mt-25px">
            <v-text-field
                readonly
                label="We Bought (Amount)"
                v-model="WeBought"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-4" justify="end">
          <v-btn
              class="mr-1"
              color="button_color"
              @click="addReceiveSideBtn"
              :disabled="!(currentStatus === 'INITIATED')"
          >
            Confirm
          </v-btn>
        </v-row>
      </v-form>
      <p style="border: 0.2px solid #f7f5f5"></p>
      <v-form
          elevation="3"
          class="adjust-label"
          ref="send_side_form"
          :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
      >
        <h4 class="font-weight-medium mb-6 mt-2" >Send Side</h4>
        <v-row>
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
                :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
                @click="ourAccountSendSideListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field
                label="Our Account"
                v-model="ourAccountFullNameSendSide"
            ></v-text-field>
            <v-tab
                :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
                @click="thirdPartyAccountSendSideListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field
                label="Third Party Account"
                v-model="thirdPartyAccountFullNameSendSide"
            ></v-text-field>
          </v-col>
          <v-col cols="3" md="3" sm="12" xs="12">
            <v-tab
                :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
                @click="ourPASendSideListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field label="Our PA" v-model="ourPASendSideFullName">
            </v-text-field>
            <v-tab
                :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
                @click="targetPASendSideListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field label="Target PA" v-model="targetPASendSideFullName">
            </v-text-field>
          </v-col>

          <v-col cols="3" md="3" sm="12" xs="12" class="mt-25px">
            <v-text-field readonly label="We Sold (Amount)" v-model="WeSold">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-4" justify="end">
          <v-btn
              class="mr-1"
              color="button_color"
              @click="addSendSideBtn"
              :disabled="!(currentStatus === 'RECEIVE_CONFIRMED')"
          >
            Confirm
          </v-btn>
        </v-row>
      </v-form>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
  </div>
  <v-dialog v-model="thirdPartyListModal" width="auto">
    <Third_Party_List_Modal_V01
        thirdPartyType="ALL"
    ></Third_Party_List_Modal_V01>
  </v-dialog>
  <v-dialog v-model="ourAccountReceiveSideListModal" width="auto">
    <Third_Party_List_Modal_V01
        thirdPartyType="ACCOUNT"
    ></Third_Party_List_Modal_V01>
  </v-dialog>
  <v-dialog v-model="thirdPartyAccountReceiveSideListModal" width="auto">
    <list_of_accounts_modal_v01
        v-if="this.thirdParty.thirdPartyType === 'CUSTOMER'"
        :thirdPartyId="this.thirdParty.id"
        third-party-type="CUSTOMER"
    >
    </list_of_accounts_modal_v01>
    <list_of_accounts_modal_v01
        v-if="this.thirdParty.thirdPartyType === 'TRUSTEE'"
        :thirdPartyId="this.thirdParty.id"
        third-party-type="TRUSTEE"
    >
    </list_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog v-model="ourAccountSendSideListModal" width="auto">
    <Third_Party_List_Modal_V01
        thirdPartyType="ACCOUNT"
    ></Third_Party_List_Modal_V01>
  </v-dialog>
  <v-dialog v-model="thirdPartyAccountSendSideListModal" width="auto">
    <list_of_accounts_modal_v01
        v-if="this.thirdParty.thirdPartyType === 'TRUSTEE'"
        :thirdPartyId="this.thirdParty.id"
        third-party-type="TRUSTEE"
    >
    </list_of_accounts_modal_v01>
    <list_of_accounts_modal_v01
        v-if="this.thirdParty.thirdPartyType === 'CUSTOMER'"
        :thirdPartyId="this.thirdParty.id"
        third-party-type="CUSTOMER"
    >
    </list_of_accounts_modal_v01>
  </v-dialog>
  <v-dialog v-model="ourPAReceiveSideListModal" width="auto">
    <Third_Party_List_Modal_V01
        thirdPartyType="SECONDARY_THIRDPARTY"
    ></Third_Party_List_Modal_V01>
  </v-dialog>
  <v-dialog v-model="targetPAReceiveSideListModal" width="auto">
    <Third_Party_List_Modal_V01
        thirdPartyType="SECONDARY_THIRDPARTY"
    ></Third_Party_List_Modal_V01>
  </v-dialog>
  <v-dialog v-model="ourPASendSideListModal" width="auto">
    <Third_Party_List_Modal_V01
        thirdPartyType="SECONDARY_THIRDPARTY"
    ></Third_Party_List_Modal_V01>
  </v-dialog>
  <v-dialog v-model="targetPASendSideListModal" width="auto">
    <Third_Party_List_Modal_V01
        thirdPartyType="SECONDARY_THIRDPARTY"
    ></Third_Party_List_Modal_V01>
  </v-dialog>

  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>


</template>
<script>
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import Third_Party_List_Modal_V01 from "../../Modals/Third_Party_List_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import {ExchangeRateOption, MoneyOption,} from "@/utils/maska_options";
import currencyService from "@/services/currency.service";
import {
  extractNumber,
  formatCurrencyInfo, formatNumberInternal,
  handleKeyDown,
  httpResponseHandler,
  messageResultHandler,
  setPage,
} from "@/utils/helpers";
import cryptoService from "@/services/crypto.service";
import {mapGetters} from "vuex";
import list_of_accounts_modal_v01 from "@/components/Modals/List_Of_Accounts_Modal_V01.vue";
import {requiredRule} from "@/utils/rules";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";

export default {
  name: "proforma_invoice_v01",
  components: {
    Success_Modal, Error_Modal,
    list_of_accounts_modal_v01,
    Broken_Page_V01,
    Third_Party_List_Modal_V01,
  },
  data() {
    return {
      message: null,
      broken_page: false,
      success: false,
      error: false,
      thirdPartyListModal: false,
      thirdParty: null,
      WeBought: null,
      currencies: [],
      selectedFirstCurrency: null,
      selectedSecondCurrency: null,
      referenceNo: null,
      exchangeRateOption: ExchangeRateOption,
      requiredRule: requiredRule,
      exchangeRate: null,
      WeSold: null,
      currentStatus: "NOT START",
      ourAccount: null,
      ourPA: null,
      targetPA: null,
      ourPASendSide: null,
      targetPASendSide: null,
      ourAccountReceiveSideListModal: false,
      thirdPartyAccountReceiveSideListModal: false,
      ourAccountSendSideListModal: false,
      thirdPartyAccountSendSideListModal: false,
      ourPAReceiveSideListModal: false,
      targetPAReceiveSideListModal: false,
      ourPASendSideListModal: false,
      targetPASendSideListModal: false,
      thirdPartyAccount: null,
      thirdPartyAccountSendSide: null,
      ourAccountSendSide: null,
      exchangeRateCalc: null,
      exchangeRateEdit: null,
      cryptoDealId: null,
      exchangeRateCalcTypes: [
        {name: "We sold / We Bought", id: 0},
        {name: "We Bough / We sold", id: 1},
      ],
    };
  },
  methods: {
    handleKeyDown,
    async getCurrencies() {
      let response = httpResponseHandler(await currencyService.getCurrencies());
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    clearThirdParty() {
      this.thirdParty = null;
    },
    async addThirdPartyBtn() {
      let {valid} = await this.$refs.third_crypto_form.validate();
      if (valid) {
        let form = {
          thirdPartyId: this.thirdPartyId,
          referenceNo: this.referenceNo,
          receiveAmount: extractNumber(this.WeBought),
          sendAmount: extractNumber(this.WeSold),
          receiveCurrencyId: this.selectedFirstCurrencyId,
          sendCurrencyId: this.selectedSecondCurrencyId,
          exchangeRate: extractNumber(this.exchangeRateFormat),
        };
        let response = messageResultHandler(
            await cryptoService.createCryptoDeal(form)
        );
        console.log("form", form);
        console.log("response", response);
        if (response.success) {
          this.success = true;
          this.message = response.message;
          this.currentStatus = "INITIATED";
          this.cryptoDealId = response.data.id;
          console.log("cryptoDealId", this.cryptoDealId);
        } else {
          this.error = true;
          this.message = response.message;
        }
      }
    },
    async addReceiveSideBtn() {
      if (
          this.selectedFirstCurrency.id === this.thirdPartyAccount.currency.id
      ) {
        let {valid} = await this.$refs.receive_side_form.validate();
        if (valid) {
          let response = messageResultHandler(
              await cryptoService.setCryptoDealReceiveSide(
                  this.cryptoDealId,
                  this.ourAccountIdReceiveSide,
                  this.thirdPartyAccountIdReceiveSide,
                  this.ourPAId,
                  this.targetPAId
              )
          );
          console.log(
              "response3",
              response,
              " this.thirdPartyAccount",
              this.thirdPartyAccount
          );
          this.message = response.message;
          if (response.success) {
            this.currentStatus = "RECEIVE_CONFIRMED";
            this.success = true;
            console.log("response", response);
          } else {
            this.error = true;
          }
        }
      } else {
        this.error = true;
        this.message =
            "The currency selected in Receive Currency is different from the currency of the account";
      }
    },
    async addSendSideBtn() {
      if (
          this.selectedSecondCurrency.id ===
          this.thirdPartyAccountSendSide.currency.id
      ) {
        let {valid} = await this.$refs.send_side_form.validate();
        if (valid) {
          let response = messageResultHandler(
              await cryptoService.setCryptoDealSendSide(
                  this.cryptoDealId,
                  this.ourAccountIdSendSide,
                  this.thirdPartyAccountIdSendSide,
                  this.ourPASendSideId,
                  this.targetPASendSideId
              )
          );
          console.log("setCryptoDealSendSide response:", response);
          this.message = response.message;
          if (response.success) {
            this.currentStatus = "COMPLETED";
            this.success = true;
            setPage("crypto report");
          } else {
            this.error = true;
          }
        }
      } else {
        this.error = true;
        this.message =
            "The currency selected in Send Currency is different from the currency of the account";
      }
    },
  },
  computed: {
    ...mapGetters(["chosen_crypto_deal", "is_editing_crypto_deal", "page"]),

    targetPASendSideFullName() {
      if (this.targetPASendSide) {
        return this.targetPASendSide.fullName;
      } else {
        return null;
      }
    },
    targetPASendSideId() {
      if (this.targetPASendSide) {
        return this.targetPASendSide.id;
      } else {
        return null;
      }
    },
    ourPASendSideFullName() {
      if (this.ourPASendSide) {
        return this.ourPASendSide.fullName;
      } else {
        return null;
      }
    },
    ourPASendSideId() {
      if (this.ourPASendSide) {
        return this.ourPASendSide.id;
      } else {
        return null;
      }
    },
    ourAccountFullNameReceiveSide() {
      if (this.ourAccount) {
        return this.ourAccount.cofferName;
      } else {
        return null;
      }
    },
    ourAccountIdReceiveSide() {
      if (this.ourAccount) {
        return this.ourAccount.id;
      } else {
        return null;
      }
    },
    ourPAFullName() {
      if (this.ourPA) {
        return this.ourPA.fullName;
      } else {
        return null;
      }
    },
    ourPAId() {
      if (this.ourPA) {
        return this.ourPA.id;
      } else {
        return null;
      }
    },
    targetPAFullName() {
      if (this.targetPA) {
        return this.targetPA.fullName;
      } else {
        return null;
      }
    },
    targetPAId() {
      if (this.targetPA) {
        return this.targetPA.id;
      } else {
        return null;
      }
    },
    ourAccountFullNameSendSide() {
      if (this.ourAccountSendSide) {
        return this.ourAccountSendSide.cofferName;
      } else {
        return null;
      }
    },
    ourAccountIdSendSide() {
      if (this.ourAccountSendSide) {
        return this.ourAccountSendSide.id;
      } else {
        return null;
      }
    },
    thirdPartyAccountFullNameSendSide() {
      if (this.thirdPartyAccountSendSide) {
        return this.thirdPartyAccountSendSide.cofferName;
      } else {
        return null;
      }
    },
    thirdPartyAccountIdSendSide() {
      if (this.thirdPartyAccountSendSide) {
        return this.thirdPartyAccountSendSide.id;
      } else {
        return null;
      }
    },
    thirdPartyAccountFullNameReceiveSide() {
      if (this.thirdPartyAccount) {
        return this.thirdPartyAccount.cofferName;
      } else {
        return null;
      }
    },
    thirdPartyAccountIdReceiveSide() {
      if (this.thirdPartyAccount) {
        return this.thirdPartyAccount.id;
      } else {
        return null;
      }
    },

    thirdPartyFullName() {
      if (this.thirdParty) {
        return this.thirdParty.fullName;
      } else {
        return null;
      }
    },

    thirdPartyId() {
      if (this.thirdParty) {
        return this.thirdParty.id;
      } else {
        return null;
      }
    },
    moneyOption() {
      if (this.selectedFirstCurrency) {
        return MoneyOption(this.selectedFirstCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    moneyOption2() {
      if (this.selectedSecondCurrency) {
        return MoneyOption(this.selectedSecondCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    selectedSecondCurrencyId() {
      if (this.selectedSecondCurrency && this.is_editing_crypto_deal) {
        return this.selectedSecondCurrency.symbol;
      } else if (!this.is_editing_crypto_deal && this.selectedSecondCurrency) {
        return this.selectedSecondCurrency.id;
      } else {
        return null;
      }
    },
    selectedFirstCurrencyId() {
      if (this.selectedFirstCurrency && this.is_editing_crypto_deal) {
        return this.selectedFirstCurrency.symbol;
      } else if (!this.is_editing_crypto_deal && this.selectedFirstCurrency) {
        return this.selectedFirstCurrency.id;
      } else {
        return null;
      }
    },
    exchangeRateFormat() {
      if (this.is_editing_crypto_deal) {
        return this.exchangeRateEdit;
      } else if (
          this.WeSold &&
          this.exchangeRateCalc &&
          this.WeBought &&
          !this.is_editing_crypto_deal
      ) {
        if (this.exchangeRateCalc.id === 0) {
          const rate =
              extractNumber(this.WeSold) / extractNumber(this.WeBought);
          return formatNumberInternal(rate);
        } else {
          const rate =
              extractNumber(this.WeBought) / extractNumber(this.WeSold);
          return formatNumberInternal(rate);
        }
      } else {
        return null;
      }
    },
  },
  async mounted() {
    emitter.on("new_third_party", (value) => {
      if (this.currentStatus === "NOT START") {
        this.thirdParty = value;
        this.thirdPartyListModal = false;
        console.log("thirdParty", this.thirdParty);
      } else if (this.ourAccountReceiveSideListModal) {
        this.ourAccount = value;
        this.ourAccountReceiveSideListModal = false;
        console.log("ourAccount", this.ourAccount);
      } else if (this.ourAccountSendSideListModal) {
        this.ourAccountSendSide = value;
        this.ourAccountSendSideListModal = false;
        console.log("ourAccountSendSide", this.ourAccountSendSide);
      } else if (this.ourPAReceiveSideListModal) {
        this.ourPA = value;
        this.ourPAReceiveSideListModal = false;
        console.log("ourPA", this.ourPA);
      } else if (this.targetPAReceiveSideListModal) {
        this.targetPA = value;
        this.targetPAReceiveSideListModal = false;
        console.log("targetPA", this.targetPA);
      } else if (this.ourPASendSideListModal) {
        this.ourPASendSide = value;
        this.ourPASendSideListModal = false;
        console.log("ourPASendSide", this.ourPASendSide);
      } else if (this.targetPASendSideListModal) {
        this.targetPASendSide = value;
        this.targetPASendSideListModal = false;
        console.log("targetPASendSide", this.targetPASendSide);
      }
    });

    emitter.on("chosenAccount", (value) => {
      if (this.thirdPartyAccountReceiveSideListModal) {
        this.thirdPartyAccount = value;
        this.thirdPartyAccountReceiveSideListModal = false;
        console.log("thirdPartyAccount", this.thirdPartyAccount);
      } else if (this.thirdPartyAccountSendSideListModal) {
        this.thirdPartyAccountSendSide = value;
        this.thirdPartyAccountSendSideListModal = false;
        console.log(
            "thirdPartyAccountSendSide",
            this.thirdPartyAccountSendSide
        );
      }
    });

    if (this.is_editing_crypto_deal) {
      this.cryptoDealId = this.chosen_crypto_deal.id;
      this.receiveAmount = this.chosen_crypto_deal.receiveAmount;
      this.currentStatus = this.chosen_crypto_deal.status;
      this.thirdParty = this.chosen_crypto_deal.thirdParty;
      this.selectedSecondCurrency = this.chosen_crypto_deal.sendCurrency;
      this.selectedFirstCurrency = this.chosen_crypto_deal.receiveCurrency;
      this.exchangeRateEdit = this.chosen_crypto_deal.exchangeRate;
      this.WeBought = this.chosen_crypto_deal.receiveAmount;
      this.WeSold = this.chosen_crypto_deal.sendAmount;
      this.referenceNo = this.chosen_crypto_deal.referenceNo;
      // send Receive is ok
      this.thirdPartyAccount = this.chosen_crypto_deal.counterPartyPayerAccount;
      this.ourPA = this.chosen_crypto_deal.ellingtonReceivePA;
      this.ourAccount = this.chosen_crypto_deal.ellingtonReceiveAccount;
      this.targetPA = this.chosen_crypto_deal.counterPartyReceivePA;
      // send side is ok
      this.ourAccountSendSide = this.chosen_crypto_deal.ellingtonSendAccount;
      this.ourPASendSide = this.chosen_crypto_deal.ellingtonSendPA;
      this.targetPASendSide = this.chosen_crypto_deal.counterPartySendPA;
      this.thirdPartyAccountSendSide =
          this.chosen_crypto_deal.counterPartyPayeeAccount;
      console.log("chosen_crypto_deal", this.chosen_crypto_deal);
      if (this.exchangeRateEdit === this.WeSold / this.WeBought) {
        console.log("hi");
        this.exchangeRateCalc = "WeSold / WeBought";
      } else {
        console.log("buy");
        this.exchangeRateCalc = "WeBought / WeSold";
      }
    }

    await this.getCurrencies();
  },
  // watch: {
  //   // exchangeRate: "WeSoldFormat",
  //   // WeSold: "WeSoldFormat",
  //   // WeBought: "WeSoldFormat",
  // },
  beforeUnmount() {
    this.$store.dispatch("setIsEditingCryptoDeal", false);
    this.$store.dispatch("setChosenCryptoDeal", null);
  },
};
</script>
