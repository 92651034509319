<script>

import {authRole} from "@/services/auth-header";


export default {
  name: "Toggle_Confirmation.vue",
  props: {
    item: {
      type: Object
    },
    action: {
      type: Boolean
    },
    editable: {
      type: Boolean,
      default: true,
    },
    reconciliationPossible: {
      type: Boolean
    }
  },
  data() {
    return {
      userRole: authRole()
    }
  },
  mounted() {
    console.log(this.userRole);
  },
  computed: {
    reconciliationAllowed() {
      return this.reconciliationPossible && (this.userRole === 'ADMIN' || this.userRole === 'FINANCE_MANAGER');
    }
  }
}
</script>

<template>
  <div v-if="!action">
    <div v-if="item.status==='Acc Updated'">
      <v-icon class="disabled-icon" :class="reconciliationAllowed ? 'mr-4' : ''" color="green"
              icon="mdi-check-circle"></v-icon>
      <v-icon v-if="reconciliationAllowed" @click="this.$emit('reconcileTransfer',item)" class="ml-4"
              icon="mdi-invoice-edit-outline"></v-icon>
    </div>
    <div v-else-if="item.status==='Canceled' || item.status==='Deleted'">
      <v-icon class="disabled-icon" color="red" icon="mdi-cancel"></v-icon>
    </div>
    <div v-else-if="item.status==='Completed' && userRole === 'ADMIN' ">
      <v-icon class="mr-4 accept-icon" @click="this.$emit('acceptTransfer',item)"
              icon="mdi-check-circle-outline"></v-icon>
      <v-icon class="ml-4 deny-icon" icon="mdi-cancel" @click="this.$emit('denyTransfer',item)"></v-icon>
    </div>
    <div v-else-if="!(editable===false || item.status==='Completed')">
      <v-icon class="ml-2" @click="this.$emit('editTransfer', item)">mdi-pencil</v-icon>
    </div>
  </div>
  <div v-if="action">
    <v-progress-circular
        model-value="100"
        color="blue-grey"
        indeterminate
    ></v-progress-circular>
  </div>
</template>

<style scoped>
.accept-icon:hover {
  transform: scale(1.4);
  color: limegreen;
}

.deny-icon:hover {
  transform: scale(1.4);
  color: red;
}

.deny-icon, .accept-icon {
  transition: transform 0.3s ease;
}

.disabled-icon {
  opacity: 0.5;
}

</style>