<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium">Transaction Type Configs</h3>
      <v-row>
        <v-col>
          <v-text-field
              class="adjust-label search-field"
              label="Search"
              v-model="searchValue"
          >
          </v-text-field>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
              color="button_color"
              @click="newTransactionTypeConfigModal = true"
              icon="mdi-plus"
              class="ml-2"
          >
          </v-btn>
          <v-btn
              color="button_color"
              @click="QTransactionTypeConfigsModal = true"
              icon="mdi-help"
              class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="transactionTypeConfigsDetails">
              <v-icon>mdi-export</v-icon>
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
          style=""
          :headers="headers"
          :items="transactionTypeConfigsDetails"
          :search-field="searchField"
          :search-value="searchValue"
          :loading="loading"
          body-text-direction="center"
          header-text-direction="center"
          alternating
          border-cell
          class="custom-data-table"
      >
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="deleteTransactionTypeConfig(item)">mdi-delete</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>

    <v-dialog v-model="newTransactionTypeConfigModal" width="auto">
      <New_Transaction_Type_Config_Modal_V01></New_Transaction_Type_Config_Modal_V01>
    </v-dialog>
    <v-dialog v-model="QTransactionTypeConfigsModal" width="auto">
      <Q_TransactionTypeConfigs_Modal_V01></Q_TransactionTypeConfigs_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import Q_TransactionTypeConfigs_Modal_V01 from "@/components/ModalQ/Q_TransactionTypeConfigs_Modal_V01.vue";
import {httpResponseHandler, messageResultHandler} from "@/utils/helpers";
import transactionService from "@/services/transaction.service";
import New_Transaction_Type_Config_Modal_V01 from "../../Modals/New_Transaction_Type_Config_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";

export default {
  name: "transaction_type_list_v01",
  components: {
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    New_Transaction_Type_Config_Modal_V01,
    Q_TransactionTypeConfigs_Modal_V01,
  },
  data() {
    return {
      headers: [
        {text: "Transfer or Transaction Type", value: "requestType"},
        {text: "Mnemonic", value: "mnemonic"},
        {text: "Ledger Direction", value: "ledgerDirection"},
        {text: "Account Direction", value: "accountDirection"},
        {text: "Transaction type description", value: "description"},
        {text: "Edit", value: "edit"},
        {text: "Delete", value: "delete"},
      ],
      searchField: "",
      searchValue: "",
      message: null,
      broken_page: false,
      success: false,
      error: false,
      loading: false,
      QTransactionTypeConfigsModal: false,
      transactionTypeConfigs: [],
      newTransactionTypeConfigModal: false,
    };
  },

  methods: {
    async getTransactionTypeConfigs() {
      this.loading = true;
      let response = httpResponseHandler(
          await transactionService.getTransactionTypeConfigs()
      );
      if (response.success) {
        this.loading = false;
        this.transactionTypeConfigs = response.data;
      } else {
        this.broken_page = true;
      }
    },
    goToEdit(item) {
      this.$store.dispatch("setChosenTransactionTypeConfig", item);
      this.$store.dispatch("setIsEditingTransactionTypeConfig", true);
      this.newTransactionTypeConfigModal = true;
    },

    async deleteTransactionTypeConfig(item) {
      let response = messageResultHandler(
          await transactionService.deleteTransactionTypeConfig(item.id)
      );
      this.message = response.message;
      if (response.success) {
        this.success = true;
        await this.getTransactionTypeConfigs();
      } else {
        this.error = true;
      }
    },
  },

  async mounted() {
    this.loading = true;
    await this.getTransactionTypeConfigs();
    this.loading = false;
    emitter.on("new_transaction_type_config", (value) => {
      this.getTransactionTypeConfigs();
      this.newTransactionTypeConfigModal = false;
      this.message = value.message;
      this.success = true;
    });
  },
  computed: {
    transactionTypeConfigsDetails() {
      return this.transactionTypeConfigs
          .map((transactionTypeConfig) => {

            let transactionTypeDisplay;
            if (transactionTypeConfig.manualTransactionType) {
              switch (transactionTypeConfig.manualTransactionType) {
                case "MONEY_INTERNAL_CORRECTION":
                  transactionTypeDisplay = "Manual Money / Internal Transfer";
                  break;
                case "NO_EVENT":
                  transactionTypeDisplay = "Manual No Event Transaction";
                  break;
                case "BILL_PAYMENT_CORRECTION":
                  transactionTypeDisplay = "Manual Bill Payment";
              }
            } else if (transactionTypeConfig.moneyTransferRequestType) {
              switch (transactionTypeConfig.moneyTransferRequestType) {
                case "CASH_RECEIVE":
                  transactionTypeDisplay = "Money Transfer Cash Receive";
                  break;
                case "CASH_SEND":
                  transactionTypeDisplay = "Money Transfer Cash Send";
                  break;
                case "BANK_RECEIVE":
                  transactionTypeDisplay = "Money Transfer Bank Receive";
                  break;
                case "BANK_SEND":
                  transactionTypeDisplay = "Money Transfer Bank Send";
              }
            } else {
              switch (true) {
                case transactionTypeConfig.internalTransferType === "CUSTOMER_TO_CUSTOMER":
                  transactionTypeDisplay = "Internal Transfer Customer to Customer";
                  break;
                case transactionTypeConfig.internalTransferType === "TRUSTEE_TO_TRUSTEE":
                  transactionTypeDisplay = "Internal Transfer Trustee to Trustee";
                  break;
                case !!transactionTypeConfig.cryptoDeal:
                  transactionTypeDisplay = "Crypto Deal";
                  break;
                case !!transactionTypeConfig.groupDeal:
                  transactionTypeDisplay = "Group Deal";
                  break;
                case !!transactionTypeConfig.safeBoxOperation:
                  transactionTypeDisplay = "Safe Box Operation";
                  break;
              }
            }
            return {
              id: transactionTypeConfig.id,
              requestType: transactionTypeDisplay,
              mnemonic: transactionTypeConfig.mnemonic,
              description:
                  transactionTypeConfig.transactionType?.description || "",
              transactionType: transactionTypeConfig.transactionType,
              currency: transactionTypeConfig.currency,
              ledgerDirection:
                  transactionTypeConfig.transactionType?.ledgerDirection || "",
              accountDirection:
                  transactionTypeConfig.transactionType?.accountDirection || "",
            };
          })
          .sort((a, b) => b.id - a.id);
    },
  },
};
</script>
