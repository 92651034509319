<script>
export default {
  name: "Confirmation_Modal.vue",
  props: {
    confirmation: {
      type: Boolean
    }
  },
  computed: {
    getConfirmation() {
      return this.confirmation
    }
  }
}
</script>

<template>
  <v-dialog v-model="getConfirmation">
    <div class="warning-dialog">
      <div class="warning-left">
        <div class="warning-icon"></div>
      </div>
      <div class="warning-right">
        <span
            class="close-icon"
            @click="this.$emit('closeConfirmationModal')"
            style="text-align: right"
        >✖</span
        >
        <h4>Warning</h4>
        <p class="error-message">Are you sure? Be careful!</p>
        <button class="warning-close-button" @click="this.$emit('DoConfirmation')">
          Yes
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped>

</style>