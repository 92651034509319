import axios from "axios";
import {BASE_URL} from "../config/endpoints.js";

class AuthService {
    async verifyOTP(otpVerificationForm) {
      const response = await axios.post(BASE_URL + "auth/verifyotp", otpVerificationForm);
      console.log(response.data);

      if (response.status === 200 && response.data.code === 200) {
        localStorage.setItem("auth/user", JSON.stringify(response.data.data));
        return response.data.data;
      } else {
        throw new Error("Incorrect OTP, please try again");
      }
    }

    logout() {
        localStorage.removeItem("auth/user");
    }
}

export default new AuthService();
