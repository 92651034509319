import axios from "axios";
import {authFormDataHeader, authHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";

class internalTransferService {
    async createInternalTransfer(form) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/create", form,
                {headers: authHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async changeRequestStatusToWaitingForApprove(id) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/status/requestForApprove",

                {internalTransferId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async changeRequestStatusToApproved(id) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/status/approve",

                {internalTransferId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async generateTransaction(id) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/status/transactionrequest",
                {internalTransferId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            console.log("the exception is here", error);
            return {
                status: 500,
                data: null,
                message: error.response.data.message,
            };
        }
    }

    async firstPartyConfirmed(id) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/status/firstPartyConfirmed",

                {internalTransferId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async requestForCompletion(id) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/status/complete",

                {internalTransferId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.response.data.message};
        }
    }

    async getInternalTransfers(page, size) {
        try {
            const response = await axios.get(BASE_URL + "internaltransfer/getAll", {
                params: {
                    page: page,
                    size: size,
                },
                headers: authHeader(),
            });
            return {status: response.status, data: response.data, message: null};
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getInternalTransferById(id) {
        try {
            const response = await axios.get(BASE_URL + "internaltransfer/get", {
                params: {
                    internalTransferId: id,
                },
                headers: authHeader(),
            });
            return {
                status: response.status,
                data: response.data,
                message: response.message,
            };
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async pastInternalTransfer(form) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/pastInternalTransfer",
                form,
                {
                    headers: authHeader(),
                }
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async getAllIncomplete(page, size) {
        try {
            return await axios.get(BASE_URL + "internaltransfer/getAllIncomplete", {
                params: {
                    page: page,
                    size: size,
                },
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async requestForAccountsUpdate(id) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/status/accountsUpdate",

                {internalTransferId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.response.data.message};
        }
    }

    async changeRequestStatusToCanceled(id) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/status/cancel",

                {internalTransferId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async requestForReconciliation(form) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/doReconciliation", form,
                {headers: authHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async deleteById(id) {
        try {
            return await axios.post(
                BASE_URL + "internaltransfer/delete",
                {internalTransferId: id},
                {headers: authFormDataHeader()}
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }

    async searchForInternalTransfers(page,size,searchField,numericalSearchField){
        try {
            return await axios.get(BASE_URL + "internaltransfer/search", {
                params: {
                    page: page,
                    size: size,
                    searchField: searchField,
                    numericalSearchField: numericalSearchField
                },
                headers: authHeader(),
            });
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }
}

export default new internalTransferService();
