<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium">Manual Report</h3>
      <v-row>
        <v-col cols="12" md="3" sm="8">
          <div class="search-bar">
            <v-text-field
                class="adjust-label search-field"
                label="Search"
                v-model="searchValue"
            >
              <template v-slot:prepend-inner>
                <v-icon
                    icon="mdi-magnify"
                    variant="tonal"
                    color="button_color"
                    class="mr-3"
                >
                </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn color="button_color" icon="mdi-help" class="ml-2"> </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel><v-icon>mdi-export</v-icon></download-excel>
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
        :headers="headers"
        :items="manualTransactionDetails"
        :search-field="searchField"
        :search-value="searchValue"
        body-text-direction="center"
        header-text-direction="center"
        alternating
        border-cell
        class="custom-data-table"
        :loading="loading"
        v-model:serverOptions="serverOptions"
        :server-items-length="totalItems"
      >
        <template #pagination="{}">
          <v-btn
            class="ma-4"
            density="compact"
            :disabled="serverOptions.page === 1"
            @click="goToPrevPage"
            >prev page</v-btn
          >
          <v-btn
            class="ma-4"
            density="compact"
            :disabled="serverOptions.page === numberOfPages"
            @click="goToNextPage"
            >next page</v-btn
          >
        </template>
        <template #item-id="item">
          <v-icon @click="gotToRequest(item)"
            ><span class="modal-icon">{{ item.id }}</span></v-icon
          >
        </template>

        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
  </div>
  <v-dialog v-model="manualTransactionModal" width="auto">
    <Manual_Transaction_Modal_V01></Manual_Transaction_Modal_V01>
  </v-dialog>

  <Success_Modal
    :message="message"
    :success="success"
    @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
    :message="message"
    :error="error"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import {formatNumber, messageResultHandler, pageResponseHandler} from "@/utils/helpers";
import manual_transferService from "@/services/manual_transfer.service";
import emitter from "@/plugins/mitt";

import Broken_Page_V01 from "../Broken_Page_V01.vue";
import Manual_Transaction_Modal_V01 from "@/components/Modals/transfer/Manual_Transaction_Modal_V01.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
export default {
  name: "manual_report_v01",
  components: {
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    Manual_Transaction_Modal_V01,
  },
  data() {
    return {
      headers: [
        { text: "Event", value: "id" },
        { text: "Trustee Acc", value: "trustee" },
        { text: "Customer Acc", value: "customer" },
        { text: "Past Event", value: "pastEvent" },
        { text: "Note", value: "description" },
        { text: "Type", value: "manualTransactionType" },
        { text: "Modified", value: "modifiedAt" },
      ],
      searchField: "",
      searchValue: "",
      totalItems: 0,
      message: null,
      broken_page: false,
      success: false,
      error: false,
      manualTransactions: [],
      manualTransactionModal: false,
      loading: false,serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
    };
  },
  methods: {
    goToPrevPage() {
      this.serverOptions.page = this.serverOptions.page - 1;
      this.getManualTransaction();
    },
    goToNextPage() {
      this.serverOptions.page = this.serverOptions.page + 1;
      this.getManualTransaction();
    },
    async getManualTransaction() {
      this.loading = true;
      let response = pageResponseHandler(
        await manual_transferService.getAllManualTransaction(
          this.serverOptions.page - 1,
          this.serverOptions.rowsPerPage
        )
      );
      if (response.success) {
        this.loading = false;
        this.totalItems = response.totalItems;
        this.numberOfPages = response.numberOfPages;
        this.manualTransactions = response.data.sort((a, b) => {
          if (a.id > b.id) return -1;
          if (a.id < b.id) return 1;
          return 0;
        });
        
      } else {
        this.broken_page = true;
      }
    },
    async gotToRequest(item) {
      let response = messageResultHandler(
        await manual_transferService.getManualTransactionById(item.id)
      );
      if (response.success) {
        let requestToSet = response.data;
        this.$store.dispatch("setChosenManualTransaction", requestToSet);
        this.manualTransactionModal = true;
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },
  async mounted() {
    emitter.on("manual_transaction_modal_close", (value) => {
      this.manualTransactionModal = false;
      this.message = value;
      this.success = true;
      this.getManualTransaction();
    });
    await this.getManualTransaction();
  },
  computed: {
    manualTransactionDetails() {
      return this.manualTransactions.map((manualTransaction) => {
        let requestTypeDisplay = "";
        if (
          manualTransaction.manualTransactionType ===
          "MONEY_INTERNAL_CORRECTION"
        ) {
          requestTypeDisplay = "Money/Internal";
        } else if (
          manualTransaction.manualTransactionType === "BILL_PAYMENT_CORRECTION"
        ) {
          requestTypeDisplay = "Bill Payment";
        } else {
          requestTypeDisplay = "No Event";
        }
        return {
          id: manualTransaction.id,
          pastEvent: manualTransaction.eventId
            ? manualTransaction.eventId
            : " ",
          modifiedAt: new Date(manualTransaction.createDate)
            .toISOString()
            .split("T")[0],
          customer: manualTransaction.customerAccount
            ? manualTransaction.customerAccount.cofferName
            : " ",
          trustee: manualTransaction.trusteeAccount
            ? manualTransaction.trusteeAccount.cofferName
            : " ",
          description: manualTransaction.description,
          manualTransactionType: requestTypeDisplay,
          trusteeCreditAmount: manualTransaction.trusteeAccount
            ? formatNumber(manualTransaction.trusteeCreditAmount) +
              " " +
              manualTransaction.trusteeAccount.currency.symbol
            : "",
          trusteeCreditCommissionAmount: manualTransaction.trusteeAccount
            ? formatNumber(manualTransaction.trusteeCreditCommissionAmount) +
              " " +
              manualTransaction.trusteeAccount.currency.symbol
            : " ",
          trusteeDebitAmount: manualTransaction.trusteeAccount
            ? formatNumber(manualTransaction.trusteeDebitAmount) +
              " " +
              manualTransaction.trusteeAccount.currency.symbol
            : "",
          trusteeDebitCommissionAmount: manualTransaction.trusteeAccount
            ? formatNumber(manualTransaction.trusteeDebitCommissionAmount) +
              " " +
              manualTransaction.trusteeAccount.currency.symbol
            : "",

          customerCreditAmount: manualTransaction.customerAccount
            ? formatNumber(manualTransaction.customerCreditAmount) +
              " " +
              manualTransaction.customerAccount.currency.symbol
            : " ",
          customerCreditCommissionAmount: manualTransaction.customerAccount
            ? formatNumber(manualTransaction.customerCreditCommissionAmount) +
              " " +
              manualTransaction.customerAccount.currency.symbol
            : " ",
          customerDebitAmount: manualTransaction.customerAccount
            ? formatNumber(manualTransaction.customerDebitAmount) +
              " " +
              manualTransaction.customerAccount.currency.symbol
            : " ",
          customerDebitCommissionAmount: manualTransaction.customerAccount
            ? formatNumber(manualTransaction.customerDebitCommissionAmount) +
              " " +
              manualTransaction.customerAccount.currency.symbol
            : " ",
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
  },
  watch: {
    serverOptions: {
      handler() {
        this.getManualTransaction();
      },
      deep: true,
    },
  },
};
</script>
