<template>
  <div>
    <v-container fluid>
      <h3 class="mb-2 font-weight-medium">{{ formatRequestType(requestType) }}</h3>
      <v-form class="adjust-label" ref="add_form" style="margin-top: 30px">
        <v-row class="mt-4">
          <v-col cols="12" md="3" sm="3" xs="12">
            <v-tab
                @click="accountListModal = true; listOfAccountsModalThirdPartyTypeProp='CUSTOMER'"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field
                label="Customer Account"
                v-model="customerAccountCofferName"
                @keyup.backspace="clearCustomer"
                @keydown="handleKeyDown"
                :rules="requiredRule"
            ></v-text-field>
            <v-text-field
                :rules="requiredRule"
                v-model="amount"
                label="Amount"
                v-maska:[moneyOption]
                class="mt-25px"
            >
            </v-text-field>
            <v-tab
                @click="accountListModal = true; listOfAccountsModalThirdPartyTypeProp='TRUSTEE'"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>

            <v-text-field
                label="Trustee Account"
                v-model="tAccountCofferName"
                @keyup.backspace="clearTAccount"
                @keydown="handleKeyDown"
                :rules="requiredRule"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" xs="12">
            <v-text-field
                readonly
                label="Customer Balance"
                v-model="customerActualAmount"
                variant="outlined"
                :disabled="!customerAccount"
                class="mt-25px"
            ></v-text-field>
            <v-tab
                @click="accountListModal = true; listOfAccountsModalThirdPartyTypeProp='SECONDARY_THIRDPARTY'"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field
                label="Payer/Payee Account"
                v-model="secondaryThirdPartyCofferName"
                @keyup.backspace="clearSecondaryThirdPartyAccount"
                @keydown="handleKeyDown"
                :rules="requiredRule"
            ></v-text-field>
            <v-text-field
                class="mt-25px"
                readonly
                label="Trustee Balance Amount Display"
                v-model="tAccountActualAmountCurrencySymbol"
                variant="outlined"
                :disabled="!tAccount"
            >
            </v-text-field>
          </v-col>

          <v-col cols="12" md="3" sm="3" xs="12">
            <v-text-field
                class="mt-25px"
                label="Reference No."
                v-model="referenceNo"
            ></v-text-field>
            <v-text-field
                class="mt-25px"
                label="Customer Com Amt"
                v-model="ellingtonCommissionAmount"
                :rules="numericRule()"
                v-maska:[moneyOption]
            ></v-text-field>
            <v-text-field
                class="mt-25px"
                label="Trustee Com Amt"
                v-model="trusteeCommissionAmount"
                :rules="numericRule()"
                v-maska:[moneyOption]
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" sm="3" xs="12">
            <div class="mt-5"><label for="date-picker" style="color: black;">Date</label>
              <VueDatePicker
                  auto-apply="true"
                  id="date-picker"
                  v-model="date"
                  :show-timepicker="false"
              ></VueDatePicker>
            </div>
            <v-text-field
                style="margin-top: 46px"
                label="Customer Com %"
                v-model="ellingtonCommissionPercent"
                :rules="numericRule()"
                v-maska:[percentageOption]
            ></v-text-field>
            <v-text-field
                class="mt-25px"
                label="Trustee Com %"
                v-model="trusteeCommissionPercent"
                :rules="numericRule()"
                v-maska:[percentageOption]
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="description" label="Description">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row justify="end">
          <div class="d-flex top-20">
            <v-btn class="ml-4 mr-15" color="button_color" @click="handleShowReport">
              Review
            </v-btn>
          </div>
        </v-row>
      </v-form>
      <v-dialog v-model="secondaryThirdPartyListModal" width="auto">
        <Third_Party_List_Modal_V01
            thirdPartyType="SECONDARY_THIRDPARTY"
        ></Third_Party_List_Modal_V01>
      </v-dialog>
      <v-dialog v-model="accountListModal" width="auto">
        <list_of_accounts_modal_v01
            :third-party-type="listOfAccountsModalThirdPartyTypeProp"
        ></list_of_accounts_modal_v01>
      </v-dialog>
      <Success_Modal
          :message="message"
          :success="success"
          @closeSuccessModal="success = false"
      ></Success_Modal>

      <Error_Modal
          :error="error"
          :message="message"
          @closeErrorModal="error = false"
      ></Error_Modal>

      <v-dialog v-model="showReport" max-width="720">
        <v-card style="padding: 20px">
          <div class="border-1">
            <h5 style="text-align: center">
              {{ formatRequestType(requestType) }}
            </h5>
            <div class="grid-card-2">
              <p>Date:</p>
              <p class="m-l-35">{{ date }}</p>
              <p>Amount:</p>
              <p class="m-l-35">{{ amount }}</p>
              <p>Description:</p>
              <p class="m-l-35">{{ description }}</p>
              <p>Customer:</p>
              <p class="m-l-35">{{ customerAccountCofferName }}</p>
              <p>Reference No:</p>
              <p class="m-l-35">{{ referenceNo }}</p>
              <p>Payer/Payee Account:</p>
              <p class="m-l-35">{{ secondaryThirdPartyCofferName }}</p>
              <p>Trustee Account:</p>
              <p class="m-l-35">{{ tAccountCofferName }}</p>
              <p>Trustee Com Amt:</p>
              <p class="m-l-35">{{ trusteeCommissionAmount }}</p>
              <p>Trustee Com %:</p>
              <p class="m-l-35">{{ trusteeCommissionPercent }}</p>
              <p>Customer Com Amt:</p>
              <p class="m-l-35">{{ ellingtonCommissionAmount }}</p>
              <p>Customer Com %:</p>
              <p class="m-l-35">{{ ellingtonCommissionPercent }}</p>
            </div>
            <v-row justify="end">
              <div class="d-flex top-20">
                <v-btn
                    color="button_color"
                    style="margin-right: 10px"
                    @click="createPastMoneyTransferRequest"
                >{{ $t("confirm") }}
                </v-btn>
                <v-btn color="button_color" @click="showReport = false"
                >Cancel
                </v-btn>
              </div>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import {
  extractNumber,
  formatCurrencyInfo,
  formatNumber,
  handleKeyDown,
  httpResponseHandler,
  messageResultHandler,
} from "@/utils/helpers";
import emitter from "@/plugins/mitt";
import money_transfer_requestService from "@/services/money_transfer_request.service";
import Third_Party_List_Modal_V01 from "../Modals/Third_Party_List_Modal_V01.vue";
import {numericRequiredRule, numericRule, requiredRule} from "@/utils/rules";
import currencyService from "@/services/currency.service";
import list_of_accounts_modal_v01 from "../Modals/List_Of_Accounts_Modal_V01.vue";
import {MoneyOption, PercentageOption} from "@/utils/maska_options";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";

export default {
  name: "p_money_transfer_v01",
  components: {
    Success_Modal,
    Error_Modal,
    Third_Party_List_Modal_V01,
    list_of_accounts_modal_v01,
  },
  props: ["requestType"],
  data() {
    return {
      error: false,
      success: false,
      message: null,
      amount: null,
      currencies: [],
      description: null,
      requiredRule: requiredRule,
      numericRequiredRule: numericRequiredRule,
      customerAccount: null,
      date: null,
      secondaryThirdPartyListModal: false,
      secondaryThirdPartyAccount: null,
      trusteeCommissionAmount: null,
      trusteeCommissionPercent: null,
      ellingtonCommissionAmount: null,
      ellingtonCommissionPercent: null,
      tAccount: null,
      selectedFiles: [],
      files: [],
      percentageOption: PercentageOption,
      showReport: false,
      referenceNo: null,
      listOfAccountsModalThirdPartyTypeProp: null,
      accountListModal: false,
    };
  },
  methods: {
    numericRule() {
      return numericRule
    },
    formatRequestType(requestType) {
      let transactionTypeDisplay = "";
      if (requestType === "CASH_RECEIVE") {
        transactionTypeDisplay = "Past Cash Receive";
      } else if (requestType === "CASH_SEND") {
        transactionTypeDisplay = "Past Cash Send";
      } else if (requestType === "BANK_RECEIVE") {
        transactionTypeDisplay = "Past Bank Receive";
      } else if (requestType === "BANK_SEND") {
        transactionTypeDisplay = "Past Bank Send";
      }
      return transactionTypeDisplay;
    },
    clearFormFields() {
      this.selectedCurrency = null;
      this.amount = null;
      this.description = null;
      this.customerAccount = null;
      this.date = null;
      this.secondaryThirdPartyAccount = null;
      this.trusteeCommissionAmount = null;
      this.trusteeCommissionPercent = null;
      this.ellingtonCommissionAmount = null;
      this.ellingtonCommissionPercent = null;
      this.tAccount = null;
      this.referenceNo = null;
    },
    async getCurrencies() {
      let response = httpResponseHandler(await currencyService.getCurrencies());
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    clearCustomer() {
      this.customerAccount = null;
    },
    clearTAccount() {
      this.tAccount = null;
    },
    clearSecondaryThirdPartyAccount() {
      this.secondaryThirdPartyAccount = null;
    },
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    async handleShowReport() {
      let {valid} = await this.$refs.add_form.validate();
      if (valid) {
        this.showReport = true;
      }
    },
    async createPastMoneyTransferRequest() {
      let {valid} = await this.$refs.add_form.validate();
      if (valid) {
        let form = {
          currencyId: this.selectedCurrency.id,
          customerId: this.customerAccountThirdPartyId,
          secondaryThirdPartyAccountId: this.secondaryThirdPartyAccountId,
          amount: extractNumber(this.amount),
          trusteeAccountId: this.tAccountId,
          trusteeCommissionAmount: extractNumber(this.trusteeCommissionAmount),
          trusteeCommissionPercent: extractNumber(this.trusteeCommissionPercent),
          ellingtonCommissionPercent: extractNumber(this.ellingtonCommissionPercent),
          ellingtonCommissionAmount: extractNumber(this.ellingtonCommissionAmount),
          description: this.description,
          createDate: this.date,
          requestType: this.requestType,
          referenceNo: this.referenceNo,
        };
        this.showReport = false;
        console.log("valid", form);
        let response = messageResultHandler(
            await money_transfer_requestService.createPastMoneyTransferRequest(
                form
            )
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          this.clearFormFields();
        } else {
          this.error = true;
        }
      } else {
        console.log("not valid");
      }
    },
  },
  computed: {
    moneyOption() {
      if (this.selectedCurrency) {
        return MoneyOption(this.selectedCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    selectedCurrency() {
      return this.customerAccount ? this.customerAccount.currency :
          this.tAccount ? this.tAccount.currency :
              this.secondaryThirdPartyAccount ? this.secondaryThirdPartyAccount.currency : null
    },
    customerAccountCofferName() {
      return this.customerAccount ? this.customerAccount.cofferName : null;
    },
    customerAccountThirdPartyId() {
      return this.customerAccount ? this.customerAccount.thirdParty.id : null;
    },
    customerActualAmount() {
      return this.customerAccount ? formatNumber(this.customerAccount.actualAmount) + " " + this.customerAccount.currency.symbol : null
    },
    secondaryThirdPartyCofferName() {
      return this.secondaryThirdPartyAccount ? this.secondaryThirdPartyAccount.cofferName : null;
    },
    secondaryThirdPartyAccountId() {
      return this.secondaryThirdPartyAccount ? this.secondaryThirdPartyAccount.id : null;
    },
    tAccountCofferName() {
      return this.tAccount ? this.tAccount.cofferName : null;
    },
    tAccountId() {
      return this.tAccount ? this.tAccount.id : null;
    },

    tAccountActualAmountCurrencySymbol() {
      console.log(this.tAccount);
      if (this.tAccount) {
        return (
            formatNumber(this.tAccount.actualAmount) +
            " " +
            this.tAccount.currencySymbol
        );
      } else {
        return null;
      }
    },
  },

  async mounted() {
    await this.getCurrencies();
    emitter.on("chosenAccount", (value) => {
      this.accountListModal = false;
      if (value.thirdParty.thirdPartyType === "CUSTOMER") {
        this.customerAccount = value;
      }
      if (value.thirdParty.thirdPartyType === "TRUSTEE") {
        this.tAccount = value;
      }
      if (value.thirdParty.thirdPartyType === "SECONDARY_THIRDPARTY") {
        this.secondaryThirdPartyAccount = value;
      }
    });

  },
};
</script>
