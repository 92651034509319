import {createI18n} from 'vue-i18n'

import en from './locales/en.json';
import ja from './locales/fa.json';

const messages = {en, ja};

export default createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: messages
})






