<template>
    <div class="paading_info">
      <v-container class="info-box" v-if="!broken_page" fluid>
        <h3>Welcome to the Trustees Page!</h3>
        <p style="margin-left: 15px;">This page allows you to manage trustee information easily. Here's what you can do:</p>
        <ul>
          <li>
            <h6>Search & Add Trustees:</h6>
            <p>Search for specific trustees by entering search criteria in the "Search" field and pressing Enter. Click on the plus icon (+) to create a new trustee record.</p>
          </li>
          <li>
            <h6>Edit Trustee Information:</h6>
            <p>By clicking the edit icon, you can edit or add trustee account details, attach files, and enter trustee contact or introducer information. You can also perform these tasks when adding a new trustee.</p>
          </li>
          <li>
            <h6>Export Trustee Data:</h6>
            <p>Download trustee data in Excel format by clicking the export icon.</p>
          </li>
          <li>
            <h6>Delete Trustee:</h6>
            <p> You can delete a trustee by clicking the "Delete" button. Please be cautious as this action is irreversible.</p>
          </li>
        </ul>

      </v-container>
      <div v-else>
        <Broken_Page_V01></Broken_Page_V01>
      </div>
      <v-dialog v-model="success" width="auto">
        <v-alert type="success">success</v-alert>
      </v-dialog>
  
      <v-dialog v-model="error" width="auto">
        <v-alert type="error">error</v-alert>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import Broken_Page_V01 from "../Broken_Page_V01.vue";
  
  export default {
    name: "Q-Trustee-Modal",
    components: {
      Broken_Page_V01,
    },
  };
  </script>

  