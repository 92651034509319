<!-- Bar_Chart.vue -->
<template>
  <div>
    <Bar
        id="my-chart-id"
        :options="chartOptions"
        :data="chartData"
    />
  </div>
</template>

<script>
import {Bar} from 'vue-chartjs'

export default {
  name: 'BarChart',
  props: {
    labels: Array,
    datasets: Array,
  },
  data() {
    return {
      chartData: {
        labels: this.labels,
        datasets: this.datasets,
      },
      chartOptions: {
        responsive: true
      }
    }
  },
  components: {
    Bar, // Add this line to indicate that you are using the Bar component
  }
}
</script>
  