<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h5>Third Parties:</h5>
      <v-row>
        <v-col>
          <v-text-field
              class="adjust-label search-field"
              label="Search"
              v-model="searchValue"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn color="button_color" @click="newThirdPartyList()" icon="mdi-plus" class="ml-2">
          </v-btn>
          <v-btn color="button_color" @click="QThirdParty = true" icon="mdi-help" class=" ml-2">
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class=" ml-2">
            <download-excel :data="thirdParties">
              <v-icon>mdi-export</v-icon>
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>
      <EasyDataTable
          style=""
          :headers="headers"
          :items="thirdParties"
          :search-field="searchField"
          :search-value="searchValue"
          body-text-direction="center"
          header-text-direction="center"
          alternating
          border-cell
          class="custom-data-table"
          :loading="loading"
      >
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="deleteThirdParty(item)">mdi-delete</v-icon>
        </template>
        <template #item-userDetails="item">
          <v-icon @click="showThirdPartyDetails(item)">mdi-chevron-down</v-icon>
        </template>

        <template #item-creditLimit="item">
          <div style="display: flex; justify-content: right">
            {{ item.creditLimit }}
          </div>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>

    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>

    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>

    <Confirmation_Modal
        :confirmation="confirmation"
        @DoConfirmation="getConfirmation()"
        @closeConfirmationModal="confirmation = false"
    ></Confirmation_Modal>
    <v-dialog v-model="QThirdParty" width="auto">
      <Q_ThirdParty_Modal_V01></Q_ThirdParty_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import Q_ThirdParty_Modal_V01 from "@/components/ModalQ/Q_ThirdParty_Modal_V01.vue"
import third_partyService from "@/services/third_party.service";
import {
  setPage,
  httpResponseHandler,
  messageResultHandler, formatNumber,
} from "@/utils/helpers";
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Confirmation_Modal from "@/components/Utils/Confirmation_Modal.vue";

export default {
  name: "third_party_list",
  components: {
    Confirmation_Modal,
    Error_Modal, Success_Modal,
    Broken_Page_V01,
    Q_ThirdParty_Modal_V01,
  },
  data() {
    return {
      headers: [
        {text: "Account Number", value: "accountNumber"},
        {text: "Full Name", value: "fullName"},
        {text: "Credit Limit", value: "creditLimit"},
        {text: "Account Email", value: "email"},
        {text: "Account Phone Number", value: "phoneNumber"},
        {text: "Edit/Details", value: "edit"},
        {text: "Delete", value: "delete"},
      ],
      searchField: "",
      searchValue: "",
      itemsSelected: [],
      message: null,
      fullName: null,
      AccountNumber: null,
      AccountEmail: null,
      AccountPhoneNumber: null,
      broken_page: false,
      success: false,
      error: false,
      thirdParties: [],
      confirmation: false,
      confirmed: false,
      QThirdParty: false,
      loading: false,
    };
  },

  methods: {
    formatNumber,
    goToEdit(third_party) {
      this.$store.dispatch("setChosenThirdParty", third_party);
      this.$store.dispatch("setIsEditingThirdParty", true);
      setPage("new_third_party");
    },
    newThirdPartyList() {
      setPage("new_third_party");
      this.$store.dispatch("setIsEditingThirdParty", false);
    },
    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },
    async getThirdParties() {
      this.loading = true;
      let response = httpResponseHandler(
          await third_partyService.getThirdParties()
      );
      if (response.success) {
        this.loading = false;
        this.thirdParties = response.data;
        this.thirdParties = response.data.map((item) => ({
          ...item,
          creditLimit: this.formatNumber(item.creditLimit), // Assuming 'amount' is the key
        }));
      } else {
        this.broken_page = true;
      }
    },
    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },
    async deleteThirdParty(third_party) {
      this.confirmation = true;
      await this.waitForConfirmation();
      if (this.confirmed) {
        this.confirmed = false;
        let response = messageResultHandler(
            await third_partyService.deleteThirdParty(third_party.id)
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          this.getThirdParties();
        } else {
          this.error = true;
        }
      }
    },
  },
  async mounted() {
    await this.getThirdParties();
  },
};
</script>
