<script>
export default {
  name: "accept-or-decline-with-info-modal",
  props: {
    screenWidth: Number,
    color: String,
    declineText: String,
    acceptText: String,
    title: String,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    returnDeclineButtonColor(text) {
      if (text.toLowerCase().includes('cancel') || text.toLowerCase().includes('delete'))
        return 'red'
      else
        return 'gray'
    },

    returnAcceptButtonColor(text) {
      if (text.toLowerCase().includes('cancel') || text.toLowerCase().includes('delete'))
        return 'red'
      else
        return 'button_color'
    },
  }

}
</script>

<template>
  <div class="centered-container">
    <v-card class="approval-modal">
      <v-alert
          type="warning"
          :color="color"
          variant="tonal"
          :text="title"
          size="large"
      >
        <div class="approval-modal-buttons mt-6">
          <v-btn
              variant="tonal"
              :color="returnDeclineButtonColor(declineText)"
              class="mr-2"
              @click="this.$emit('decline')"
              :size="screenWidth < 1500 ? 'small' : undefined"
          >
            {{ declineText }}
          </v-btn>
          <v-btn :color="returnAcceptButtonColor(acceptText)" variant="flat" @click="this.$emit('accept')"
                 :size="screenWidth < 1500 ? 'small' : undefined">
            {{ acceptText }}
          </v-btn>
        </div>
      </v-alert>
    </v-card>
  </div>

</template>

<style scoped>
.approval-modal-buttons {
  display: flex;
  justify-content: flex-end;
}

.approval-modal {
  width: 35vw;
}

.approval-modal :deep(.v-alert__content) {
  font-size: 18px;
}

.approval-modal:deep(.v-alert__prepend i) {
  font-size: 36px !important;
}

.centered-container {
  display: flex;
  justify-content: center;
}
</style>