<template>
  <div>
    <v-container fluid>
      <h5>Third Party:</h5>
      <v-tabs
          fixed-tabs
          bg-color=""
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          class="mb-5"
      >
        <v-tab @click="step = 'info'"> third party info</v-tab>
        <v-tab v-if="is_editing_third_party" @click="step = 'contacts'"> {{ $t("contacts") }}</v-tab>
        <v-tab v-if="is_editing_third_party" @click="step = 'files'"> linked files</v-tab>
        <v-tab v-if="is_editing_third_party" @click="step = 'account'">
          accounts
        </v-tab>
      </v-tabs>

      <ThirdPartyInfoForm
          v-if="step === 'info' "
          @addThirdParty="addThirdParty"
      >
      </ThirdPartyInfoForm>

      <ThirdPartyContactsForm v-if="step === 'contacts'">
      </ThirdPartyContactsForm>

      <ThirdPartyFiles v-if="step === 'files'">
      </ThirdPartyFiles>

      <SimpleAccountForm v-if="step === 'account' && (isCustomer  || isSafeBox) "></SimpleAccountForm>

      <AccountForm v-if="step === 'account' && !isCustomer && !isSafeBox "></AccountForm>

    </v-container>

    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>

    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>

    <v-dialog v-model="individualListModal" width="auto">
      <Individual_List_Modal_V01></Individual_List_Modal_V01>
    </v-dialog>
    <v-dialog v-model="accountTypeListModal" width="auto">
      <Account_Type_List_Modal_V01
          :thirdPartyType="this.chosen_third_party.thirdPartyType"></Account_Type_List_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import ThirdPartyService from "@/services/third_party.service";
import Account_Type_List_Modal_V01 from "../../Modals/Account_Type_List_Modal_V01.vue";
import { handleKeyDown,  messageResultHandler,} from "@/utils/helpers";
import {emailNotRequiredRule, emailRule, lengthRule, numericRule, requiredRule,} from "@/utils/rules.js";
import Individual_List_Modal_V01 from "../../Modals/Individual_List_Modal_V01.vue";
import {mapGetters} from "vuex";
import "@vuepic/vue-datepicker/dist/main.css";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import ThirdPartyInfoForm from "@/components/HomeManagement/ThirdParty/third-party-info-form.vue";
import ThirdPartyContactsForm from "@/components/HomeManagement/ThirdParty/third-party-contacts-form.vue";
import ThirdPartyFiles from "@/components/HomeManagement/ThirdParty/third-party-files.vue";
import SimpleAccountForm from "@/components/HomeManagement/ThirdParty/simple-account-form.vue";
import AccountForm from "@/components/HomeManagement/ThirdParty/account-form.vue";

export default {
  name: "new_third_party_v01",
  components: {
    AccountForm,
    SimpleAccountForm,
    ThirdPartyFiles,
    ThirdPartyContactsForm,
    ThirdPartyInfoForm,
    Error_Modal, Success_Modal,
    Individual_List_Modal_V01,
    Account_Type_List_Modal_V01,
  },
  data() {
    return {
      Id: null,
      selectedType: null,
      currencies: [],
      step: "info",
      success: false,
      error: false,
      emailNotRequiredRule: emailNotRequiredRule,
      message: null,
      emailRule: emailRule,
      requiredRule: requiredRule,
      individualListModal: false,
      accountTypeListModal: false,
      thirdPartyTypes: [
        {name: "CUSTOMER", id: 0},
        {name: "TRUSTEE", id: 1},
        {name: "PAYER/PAYEE", id: 2},
      ],
      lengthRule: lengthRule,
      numericRule: numericRule,
    };
  },
  methods: {
    isSelectedTypePayerPayee() {
      if (!this.selectedType) {
        return false;
      } else if (this.selectedType === "SECONDARY_THIRDPARTY") {
        return true;
      }
    },
    isSelectedTypeCTS() {
      if (!this.selectedType) {
        return false;
      } else if (
          this.selectedType === "TRUSTEE" ||
          this.selectedType === "CUSTOMER" ||
          this.selectedType === "SAFE_BOX"
      ) {
        return true;
      }
    },
    handleKeyDown(event) {
      handleKeyDown(event);
    },

    async addThirdParty(form) {
      let response = null;
      if (!this.is_editing_third_party) {
        response = messageResultHandler(
            await ThirdPartyService.addThirdParty(form)
        );
      } else {
        response = messageResultHandler(
            await ThirdPartyService.editThirdParty(form)
        );
      }
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.$store.dispatch("setChosenThirdParty", response.data);
        this.$store.dispatch("setIsEditingThirdParty", true);
        this.selectedType = response.data.thirdPartyType;
      } else {
        this.error = true;
      }
    },

  },
  async mounted() {
    if (this.is_editing_third_party) {
      this.selectedType = this.chosen_third_party.thirdPartyType;
    }
    console.log(this.selectedType)
  },

  watch: {
    step() {
      this.message = "";
    },

    error(newValue) {
      if (!newValue) {
        this.message = "";
      }
    },
    success(newValue) {
      if (!newValue) {
        this.message = "";
      }
    },
  },

  computed: {
    ...mapGetters(["chosen_third_party", "is_editing_third_party"]),


    isCustomer() {
      if (!this.chosen_third_party) {
        return false;
      } else {
        return this.chosen_third_party.thirdPartyType === "CUSTOMER";
      }
    },

    isSafeBox() {
      if (!this.chosen_third_party) {
        return false;
      } else {
        return this.chosen_third_party.thirdPartyType === "SAFE_BOX";
      }
    },
  },
};
</script>
