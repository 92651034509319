<script>
export default {
  name: "Error_Modal.vue",
  props: {
    message: {
      type: String,
    },
    error: {
      type: Boolean,
    },
  },
  computed:{
    getFailure(){
      return this.error
    }
  }
}
</script>

<template>
  <v-dialog v-model="getFailure">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>
      </div>
      <div class="error-right">
          <span
              class="close-icon"
              @click="this.$emit('closeErrorModal')"
              style="text-align: right"
          >✖</span>
        <h3 class="font-weight-bold">Error</h3>
        <p class="error-message">{{ message }}</p>
        <button class="close-button" @click="this.$emit('closeErrorModal')">Close</button>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped>

</style>