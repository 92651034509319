<template>
  <div>
    <v-container v-if="!broken_page" fluid="false">
      <h3 class="font-weight-medium">Users</h3>
      <v-row>
        <v-col>
          <v-text-field
              style="display: inline-block"
              class="adjust-label search-field"
              label="Search"
              v-model="searchValue"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn color="button_color" @click="NewUserList()" icon="mdi-plus" class="ml-2">
          </v-btn>
          <v-btn color="button_color" @click="QUserModal = true" icon="mdi-help" class=" ml-2">
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class=" ml-2">
            <download-excel :data="users">
              <v-icon>mdi-export</v-icon>
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
          :headers="headers"
          :items="users"
          :search-field="searchField"
          :search-value="searchValue"
          alternating
          border-cell
          class="custom-data-table"
      >
        <!--              // It means when you get to the enabled header, display this customized template.-->
        <template #item-enabled="item">
          <v-icon v-if="!item.enabled" @click="enableUser(item.email)"
          >mdi-account-off
          </v-icon
          >
          <v-icon v-else @click="disableUser(item.email)"
          >mdi-account-check
          </v-icon
          >
        </template>
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="deleteUser(item.email)">mdi-delete</v-icon>
        </template>
        <template #item-changePassword="item">
          <v-icon @click="changePassword(item)">mdi-key-change</v-icon>
        </template>
        <template #item-setRoles="item">
          <v-icon @click="showEditRole(item)">mdi-account-group</v-icon>
        </template>
        <template #item-userDetails="item">
          <v-icon @click="showUserDetails(item)"> mdi-chevron-down</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <v-dialog v-model="edit_roles" width="auto">
      <v-sheet width="400" class="mx-auto adjust-label">
        <v-form fast-fail class="pa-md-12" refs="set_roles_form">
          <v-radio-group v-model="userRole">
            <v-radio label="ADMIN" value="ADMIN"></v-radio>
            <v-radio label="OPERATION_MANAGER" value="OPERATION_MANAGER"></v-radio>
            <v-radio label="OPERATION_TEAM" value="OPERATION_TEAM"></v-radio>
            <v-radio label="FINANCE_MANAGER" value="FINANCE_MANAGER"></v-radio>
            <v-radio label="FINANCE_TEAM" value="FINANCE_TEAM"></v-radio>
            <v-radio label="OFFICE_MANAGER" value="OFFICE_MANAGER"></v-radio>
            <v-radio label="OFFICE_TEAM" value="OFFICE_TEAM"></v-radio>

          </v-radio-group>
          <v-btn color="deep-orange-accent-2" @click="confirmNewRoles" block="" class="mt-2">{{
              $t("confirm")
            }}
          </v-btn>
        </v-form>
      </v-sheet>
    </v-dialog>
    <!-- <v-dialog v-model="success" width="auto">
      <v-alert type="success"> {{ message }} </v-alert>
    </v-dialog> -->
    <!-- <v-dialog v-model="error" width="auto">
      <v-alert type="error"> {{ message }} </v-alert>
    </v-dialog> -->
    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>

    <Confirmation_Modal
        :confirmation="confirmation"
        @DoConfirmation="getConfirmation()"
        @closeConfirmationModal="confirmation = false"
    ></Confirmation_Modal>

    <!-- <v-dialog v-model="confirmation" width="auto">
      <v-alert type="warning">
        <v-btn color="button_color" @click="getConfirmation"
          >are you sure</v-btn
        >
      </v-alert>
    </v-dialog> -->
    <v-dialog v-model="QUserModal" width="auto">
      <Q_user_Modal_V01></Q_user_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import userService from "@/services/user.service";
import emitter from "@/plugins/mitt";
import {authEmail} from "@/services/auth-header";
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import Q_user_Modal_V01 from "@/components/ModalQ/Q_user_Modal_V01.vue";
import {
  setPage,
  httpResponseHandler,
  messageResultHandler,
} from "@/utils/helpers";
import Confirmation_Modal from "@/components/Utils/Confirmation_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";

let edited_user_email = "";
export default {
  name: "users_list_v01",
  components: {
    Success_Modal, Error_Modal,
    Confirmation_Modal,
    Broken_Page_V01,
    Q_user_Modal_V01
  },
  data() {
    return {
      components: {
        Q_user_Modal_V01,
      },
      headers: [
        // { text: "Id", value: "identificationCode" },
        {text: "Last Name", value: "lastName"},
        {text: "First Name", value: "firstName"},
        {text: "Email:", value: "email"},
        {text: "Cell Phone", value: "cellPhoneNumber"},
        {text: "Edit", value: "edit"},
        {text: "Status", value: "enabled"},
        {text: "Delete", value: "delete"},
        {text: "Pass", value: "changePassword"},
        {text: "Roles", value: "setRoles"},
        {text: "Details", value: "userDetails"},
      ],
      users: [],
      searchField: "",
      searchValue: "",
      itemsSelected: [],
      message: null,
      edit_roles: false,
      email_to_assign_role: null,
      userRole: null,
      broken_page: false,
      success: false,
      error: false,
      authEmail: null,
      confirmation: false,
      confirmed: false,
      QUserModal: false,
    };
  },
  methods: {
    backToList() {
      setPage("");
      this.$nextTick(() => {
        setPage("users_list");
      });
    },
    NewUserList() {
      setPage("new_user");
    },
    async showEditRole(item) {
      await this.getuserRole(item.email);
    },
    goToEdit(user) {
      this.$store.dispatch("setChosenUser", user);
      this.$store.dispatch("setIsEditingUser", true);
      setPage("new_user");
    },
    async confirmNewRoles() {
      let edit_roles_form = {
        email: this.email_to_assign_role,
        userRole: this.userRole,
      };

      console.log({
        email: this.email_to_assign_role,
        userRole: this.userRole,
      }, this.userRole);
      let response = messageResultHandler(
          await userService.setNewRules(edit_roles_form)
      );
      this.message = response.message;
      if (response.success) {
        this.edit_roles = false;
        this.success = true;
      } else {
        this.error = true;
      }
      if (this.email_to_assign_role === this.authEmail) {
        this.$router.push("/login_v01");
      }
    },
    changePassword() {
      console.log("change password");
    },
    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;

      return true;
    },

    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },

    async deleteUser(email) {
      if (email === this.authEmail) {
        alert("you cant delete yourself");
      } else {
        this.confirmation = true;
        await this.waitForConfirmation();
        if (this.confirmed) {
          this.confirmed = false;
          let response = messageResultHandler(
              await userService.deleteUser(email)
          );
          if (response.success) {
            this.success = true;
            this.getUsersList();
          } else {
            this.error = true;
            this.message = "You can not delete this user.";
            // console.log(response)
          }
        }
      }
    },
    async enableUser(email) {
      let response = messageResultHandler(await userService.enableUser(email));
      this.message = response.message;
      if (response.success) {
        this.success = true;
        this.getUsersList();
      } else {
        this.error = true;
      }
    },
    async disableUser(email) {
      if (email === this.authEmail) {
        alert("you cant disable yourself");
      } else {
        let response = messageResultHandler(
            await userService.disableUser(email)
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          await this.getUsersList();
        } else {
          this.error = true;
        }
      }
    },
    async getUsersList() {
      let response = httpResponseHandler(await userService.getUsers());
      if (response.success) {
        this.users = response.data;
      } else {
        this.broken_page = true;
      }
    },
    async getuserRole(email) {
      let response = messageResultHandler(
          await userService.getUserRole(email)
      );
      if (response.success) {
        this.userRole = response.data;
        // for (let role in response.data) {
        //     this.userRole.push(response.data[role]["name"]);
        // }
        this.email_to_assign_role = email;
        this.edit_roles = true;
      } else {
        this.message = response.message;
      }
      if (this.email_to_assign_role === authEmail) {
        alert("you cant change your own roles");
      }
    },
    showUserDetails(user) {
      this.$store.dispatch("setChosenUser", user);
      setPage("user_details");
    },
  },
  async mounted() {
    emitter.on("edited_user_email", (value) => {
      edited_user_email = value;
    });
    this.searchValue = edited_user_email;
    await this.getUsersList();
    this.authEmail = authEmail();
  },
  beforeUnmount() {
    edited_user_email = "";
  },
  watch: {
    edit_roles(newValue) { //It is a function that clears the userRole.
      if (!newValue) {
        this.userRole = null;
      }
    },
  },
};
</script>
<style></style>
