import axios from "axios";
import { authHeader } from "./auth-header";
import { BASE_URL } from "@/config/endpoints";

class TrusteesListService {
  async getTrusteesList() {
    try {
      return await axios.get(BASE_URL + "thirdparty/trustees", {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }
}

export default new TrusteesListService();
