import axios from "axios";
import { authFormDataHeader,authHeader} from "./auth-header";
import {BASE_URL} from "@/config/endpoints";

class GroupDealService {
    async GroupDealForm(groupDealForm) {
        try {
            return await axios.post(
                BASE_URL + "groupDeal/execute",
                groupDealForm,
                {
                    headers: authHeader(),
                }
            );
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }
    async getGroupDeal(page, size) {
        try {
          const response = await axios.get(BASE_URL + "groupDeal/getAll", {
            params: {
              page: page,
              size: size,
            },
            headers: authHeader(),
          });
          return { status: response.status, data: response.data, message: null };
        } catch (error) {
          return { status: 500, data: null, message: error.message };
        }}
        async cancelGroupDeal(groupDealId) {
          try {
              return await axios.post(
                  BASE_URL + "groupDeal/cancel",
                  {
                    groupDealId: groupDealId,
                  },
                  {
                      headers: authFormDataHeader(),
                  }
              );
          } catch (error) {
              return {status: 500, data: null, message: error.message};
          }
      }
      async getGroupDealById(id) {
        try {
            const response = await axios.get(BASE_URL + "groupDeal/get", {
                params: {
                  groupDealId: id,
                },
                headers: authHeader(),
            });
            return {
                status: response.status,
                data: response.data,
                message: response.message,
            };
        } catch (error) {
            return {status: 500, data: null, message: error.message};
        }
    }
    async requestForAccountUpdate(groupDealId) {
      try {
          return await axios.post(
              BASE_URL + "groupDeal/accountsUpdate",
              {
                groupDealId: groupDealId,
              },
              {
                  headers: authFormDataHeader(),
              }
          );
      } catch (error) {
          return {status: 500, data: null, message: error.message};
      }
  }
 
}

export default new GroupDealService();