<template>
    <div class="paading_info">
      <v-container class="info-box" v-if="!broken_page" fluid>
        <h3>Welcome to the Payers/Payees Page!</h3>
        <p style="margin-left: 15px;">This page allows you to manage payers/payees information easily. Here's what you can do:</p>
        <ul>
          <li>
            <h6>Search & Add Payers/Payees:</h6>
            <p>Search for specific payers/payees by entering search criteria in the "Search" field and pressing Enter. Click on the plus icon (+) to create a new payers/payees record.</p>
          </li>
          <li>
            <h6>Edit Payers/Payees Information:</h6>
            <p>By clicking the edit icon, you can edit or add payers/payees account details, attach files, and enter payers/payees contact or introducer information. You can also perform these tasks when adding a new payers/payees.</p>
          </li>
          <li>
            <h6>Export Payers/Payees Data:</h6>
            <p>Download payers/payees data in Excel format by clicking the export icon.</p>
          </li>
          <li>
            <h6>Delete Payers/Payees:</h6>
            <p> You can delete a payers/payees by clicking the "Delete" button. Please be cautious as this action is irreversible.</p>
          </li>
        </ul>

      </v-container>
      <div v-else>
        <Broken_Page_V01></Broken_Page_V01>
      </div>
      <v-dialog v-model="success" width="auto">
        <v-alert type="success">success</v-alert>
      </v-dialog>
  
      <v-dialog v-model="error" width="auto">
        <v-alert type="error">error</v-alert>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import Broken_Page_V01 from "../Broken_Page_V01.vue";
  
  export default {
    name: "Q-SecondaryThirdParty-Modal",
    components: {
      Broken_Page_V01,
    },
  };
  </script>

  