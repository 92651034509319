export const ACCEPTED_FILE_TYPES = ".pdf,image/*,.xls,.txt,.doc,.docx";

export const MONEY_TRANSFER_TABS = [
  {
    title: "NOT INITIATED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "INITIATED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "WAITING FOR APPROVAL",
    iconSuccess: null,
    isValid: true,
  },

  {
    title: "APPROVED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "TRANSACTION REQUESTED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "TRUSTEE CONFIRMED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "COMPLETED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "ACCOUNTS UPDATED",
    iconSuccess: null,
    isValid: true,
  },
];

export const INTERNAL_TRANSFER_TABS = [
  {
    title: "NOT INITIATED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "INITIATED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "WAITING FOR APPROVAL",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "ADMIN APPROVED",
    iconSuccess: null,
    isValid: true,
  },

  {
    title: "TRANSACTION REQ",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "FIRST PARTY CONFIRMED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "COMPLETED",
    iconSuccess: null,
    isValid: true,
  },
  {
    title: "ACCOUNTS UPDATED",
    iconSuccess: null,
    isValid: true,
  },
];

export const ACCEPTABLE_CURRENCY_PAIR = [
  "EURAED",
  "EURUSD",
  "EURCNY",
  "EURIRR",
  "AEDCNY",
  "AEDRUB",
  "AEDTRY",
  "AEDIRR",
  "USDAED",
  "USDCNY",
  "USDRUB",
  "USDTRY",
  "USDIRR",
  "GBPAED",
  "GBPIRR",
  "CADAED",
  "CADIRR",
  "CHFAED",
  "USDTIRR",
  "USDTAED",
];

// export const PAGE_SIZE = 25;
export const PAGE_SIZE = 25;
