import axios from "axios";
import { authHeader } from "./auth-header";
import { BASE_URL } from "@/config/endpoints";
class CheckbooksService {

  async createCheckbooks(form) {
    try {
      return await axios.post(BASE_URL + "checkBook/create", form, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }
  
  async getCheckbooks(page, size) {
    try {
      return await axios.get(BASE_URL + "checkBook", {
        params: {
          page: page,
          size: size,
        },
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }
  async getCheckByCheckBookId(checkBookId) {
    try {
      return await axios.get(BASE_URL + `checkBook/${checkBookId}/paymentChecks`, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

  // http://localhost:9000/api/checkBook/{{checkBookId}}/checks/{{paymentCheckId}}

  async updatePaymentCheck(checkBookId,paymentCheckId,form) {
    try {
      return await axios.put(BASE_URL +`checkBook/${checkBookId}/paymentChecks/${paymentCheckId}`, form, {
        headers: authHeader(),
      });
    } catch (error) {
      return { status: 500, data: null, message: error.message };
    }
  }

}

export default new CheckbooksService();
