<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium">Account Types</h3>
      <v-row>
        <v-col>
          <v-text-field
            class="adjust-label search-field"
            label="Search"
            v-model="searchValue"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            color="button_color"
            @click="NewAccountTypes()"
            icon="mdi-plus"
            class="ml-2"
          >
          </v-btn>
          <v-btn
            color="button_color"
            @click="QAccountTypesModal = true"
            icon="mdi-help"
            class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="accountTypesDetails"
              ><v-icon>mdi-export</v-icon></download-excel
            >
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
        style=""
        :headers="headers"
        :items="accountTypesDetails"
        :search-field="searchField"
        :search-value="searchValue"
        body-text-direction="center"
        header-text-direction="center"
        alternating
        border-cell
        class="custom-data-table"
        :loading="loading"
      >
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="deleteAccountTypes(item)">mdi-delete</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="QAccountTypesModal" width="auto">
      <Q_AccountTypes_Modal_V01></Q_AccountTypes_Modal_V01>
    </v-dialog>
  </div>
</template>
<script>
import {
  setPage,
  httpResponseHandler,
  messageResultHandler,
} from "@/utils/helpers";
import account_typeService from "@/services/account_type.service";
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import Q_AccountTypes_Modal_V01 from "@/components/ModalQ/Q_AccountTypes_Modal_V01.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
export default {
  name: "list_of_t_accounts_v01",
  components: {
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    Q_AccountTypes_Modal_V01,
  },
  data() {
    return {
      headers: [
        { text: "Ledger Code", value: "ledgerCode" },
        { text: "Description", value: "description" },
        { text: "Currency", value: "currency" },
        { text: "Account Group", value: "accountGroup" },
        { text: "Edit/Details", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      searchField: "",
      searchValue: "",
      message: null,
      broken_page: false,
      success: false,
      error: false,
      QAccountTypesModal: false,
      accountTypes: [],
      loading: false,
    };
  },

  methods: {
    goToEdit(add_account_type) {
      this.$store.dispatch("setChosenAccountType", add_account_type);
      this.$store.dispatch("setIsEditingAccountType", true);
      setPage("new_account_type");
    },
    NewAccountTypes() {
      setPage("new_account_type");
    },
    async deleteAccountTypes(account_type) {
      let response = messageResultHandler(
        await account_typeService.deleteAccountType(account_type.id)
      );
      this.message = response.message;
      if (response.success) {
        await this.getAccountTypes();
        this.success = true;
      } else {
        this.error = true;
      }
    },
    async getAccountTypes() {
      this.loading = true;
      let response = httpResponseHandler(
        await account_typeService.getAccountTypes()
      );
      if (response.success) {
        // this.accountTypes = response.data.sort((a, b) => {
        //   if (a.generalLedger.code < b.generalLedger.code) return -1;
        //   if (a.generalLedger.code > b.generalLedger.code) return 1;
        //   return 0;
        // });
        this.loading = false;
        this.accountTypes = response.data;
        console.log("hi:", response.data);
      } else {
        this.broken_page = true;
      }
    },
  },

  async mounted() {
    await this.getAccountTypes();
  },
  computed: {
    accountTypesDetails() {
      return this.accountTypes.map((accountType) => ({
        id: accountType.id,
        accountGroup: accountType.accountGroup,
        description: accountType.description,
        currency: accountType.currency.symbol,
        currencyId: accountType.currency,
        ledgerCode: accountType.generalLedger.code,
        ledgerId: accountType.generalLedger.id,
      }));
    },
  },
};
</script>
