<template>
  <div>
    <v-form ref="form" class="adjust-label">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="6" sm="12" xs="12">
            <v-text-field
                v-model="firstName"
                :rules="requiredRule"
                label="First Name"
            ></v-text-field>
            <v-text-field
                v-model="lastName"
                :rules="requiredRule"
                label="Last Name"
            ></v-text-field>
            <v-text-field v-model="nationalId" label="ID"></v-text-field>
            <v-text-field
                v-model="email"
                :rules="emailNotRequiredRule"
                label="Email Address"
                required
            ></v-text-field>
            <v-text-field v-model="url" label="URL" required></v-text-field>
            <v-textarea v-model="note" label="Note"></v-textarea>
          </v-col>
          <v-col cols="12" md="6" sm="12" xs="12">
            <v-col cols="3" md="3" sm="3" xs="3" style="display: inline-block">
              <v-autocomplete
                  label="Intl. Code"
                  v-model="internationalCodePhoneNumber"
                  :items="countries"
                  filled
                  return-object
                  item-title="name"
                  item-value="internationalCode"
              >
                <template v-slot:item="{ props,item }">
                  <v-list-item v-bind="props">
                    <template v-slot:append>
                      (+{{ item.raw.internationalCode }})
                      <country-flag
                          :country="item.raw.code"
                          style="margin-left: 5px"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="9" md="9" sm="9" xs="9" style="display: inline-block">
              <v-text-field
                  v-model="phoneNumber"
                  :rules="phoneCellNotRequired"
                  label="Phone Number"
              ></v-text-field>
            </v-col>
            <v-col cols="3" md="3" sm="3" xs="3" style="display: inline-block">
              <v-autocomplete
                  label="Intl. Code"
                  v-model="internationalCodeCellPhoneNumber"
                  :items="countries"
                  filled
                  return-object
                  item-title="name"
                  item-value="internationalCode"
              >
                <template v-slot:item="{ props,item }">
                  <v-list-item v-bind="props">
                    <template v-slot:append>
                      (+{{ item.raw.internationalCode }})
                      <country-flag
                          :country="item.raw.code"
                          style="margin-left: 5px"
                      />
                    </template>
                  </v-list-item>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="9" md="9" sm="9" xs="9" style="display: inline-block">
              <v-text-field
                  v-model="cellPhoneNumber"
                  :rules="phoneCellNotRequired"
                  label="Cellphone Number"
              >
              </v-text-field>
            </v-col>
            <v-text-field v-model="activity" label="Activity"></v-text-field>

            <v-autocomplete
                label="Select Country"
                v-model="selectedCountry"
                :items="countries"
                item-title="name"
                item-value="name"
                filled
                return-object
            >
              <template v-slot:item="{ props,item }">
                <v-list-item v-bind="props">
                  <template v-slot:append>
                    <country-flag :country="item.raw.code"/>
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
            <label for="date-picker" style="color: black">Birth Day</label>
            <VueDatePicker
                id="date-picker"
                v-model="birthDate"
                model-type="yyyy-MM-dd"
                auto-apply="true"
            ></VueDatePicker>
          </v-col>
        </v-row>

        <v-row justify="end" class="mt-4">
          <v-btn color="button_color" class="mr-4" @click="cancel"
          >Cancel
          </v-btn
          >
          <v-btn
              class="mr-4"
              color="button_color"
              v-if="!is_editing_individual"
              @click="addIndividual"
          >Create Individual
          </v-btn
          >
          <v-btn class="mr-4" v-else @click="addIndividual" color="button_color"
          >Edit Individual
          </v-btn
          >
        </v-row>
      </v-container>
    </v-form>
    <!-- <v-dialog v-model="error" width="auto">
      <v-alert type="error">
        {{ message }}
      </v-alert>
    </v-dialog>

    <v-dialog v-model="success" width="auto">
      <v-alert type="success">
        {{ message }}
      </v-alert>
    </v-dialog> -->
    <v-dialog v-model="success">
      <div class="success-dialog">
        <div class="success-left">
          <div class="green-circle">
            <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M50 100 L80 130 L150 50"
                  stroke="white"
                  stroke-width="25"
                  fill="transparent"
                  class="success-tick"
              />
            </svg>
          </div>
        </div>
        <div class="success-right">
          <span
              class="close-icon"
              @click="success = false"
              style="text-align: right"
          >✖</span
          >
          <h4>Success</h4>
          <p class="success-message">{{ message }}</p>
          <button class="success-close-button" @click="success = false">
            Close
          </button>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="error">
      <div class="error-dialog">
        <div class="error-left">
          <div class="red-icon"></div>
        </div>
        <div class="error-right">
          <span
              class="close-icon"
              @click="error = false"
              style="text-align: right"
          >✖</span
          >
          <h4>Error</h4>
          <p class="error-message">{{ message }}</p>
          <button class="close-button" @click="error = false">Close</button>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import individualService from "@/services/individual.service";
import CountryService from "@/services/country.service";
import CountryFlag from "vue-country-flag-next";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import {
  combineCodeAndNumber,
  extractCodeAndPhone,
  findCountryByCode,
  httpResponseHandler,
  messageResultHandler,
  refreshEditIndividual,
  setPage,
} from "@/utils/helpers";
import {
  cellPhoneRule,
  emailNotRequiredRule,
  emailRule,
  numericRule,
  phoneCellNotRequired,
  phoneCellRequired,
  phoneRule,
  requiredRule,
} from "@/utils/rules.js";
import {mapGetters} from "vuex";

export default {
  name: "new_individual_v01",
  components: {
    VueDatePicker,
    CountryFlag,
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      nationalId: null,
      email: null,
      phoneNumber: null,
      cellPhoneNumber: null,
      internationalCodePhoneNumber: null,
      internationalCodeCellPhoneNumber: null,
      phoneCellNotRequired: phoneCellNotRequired,
      emailNotRequiredRule: emailNotRequiredRule,
      activity: null,
      selectedCountry: null,
      note: null,
      birthDate: null,
      message: null,
      phoneRule: phoneRule,
      numericRule: numericRule,
      emailRule: emailRule,
      cellPhoneRule: cellPhoneRule,
      requiredRule: requiredRule,
      phoneCellRequired: phoneCellRequired,
      error: false,
      success: false,
      countries: [],
      broken_page: false,
      url: null,
    };
  },
  methods: {
    async addIndividual() {
      const {valid} = await this.$refs.form.validate();
      let response = null;
      if (valid) {
        let cName = null;
        if (this.selectedCountry) {
          cName = this.selectedCountry.name;
        }
        let form = {
          id: this.id,
          firstName: this.firstName,
          lastName: this.lastName,
          nationalId: this.nationalId,
          email: this.email,
          phoneNumber: this.phoneNumberWithCode,
          cellPhoneNumber: this.cellPhoneNumberWithCode,
          activity: this.activity,
          countryName: cName,
          note: this.note,
          birthDate: this.birthDate,
          url: this.url,
        };
        console.log(form);
        if (!this.is_editing_individual) {
          response = messageResultHandler(
              await individualService.addIndividual(form)
          );
        } else {
          response = messageResultHandler(
              await individualService.editIndividual(form)
          );
        }
        this.message = response.message;
        if (response.success) {
          this.success = true;
        } else {
          this.error = true;
        }
      }
    },
    async cancel() {
      await this.$refs.form.reset();
      setPage("individual_list");
    },
    async getCountries() {
      let response = httpResponseHandler(await CountryService.getCountries());
      if (response.success) {
        this.countries = response.data;
      } else {
        this.broken_page = true;
      }
    },
  },
  async mounted() {
    await this.getCountries();
    if (this.is_editing_individual) {
      this.id = this.chosen_individual.id;
      this.firstName = this.chosen_individual.firstName;
      this.lastName = this.chosen_individual.lastName;
      this.nationalId = this.chosen_individual.nationalId;
      this.email = this.chosen_individual.email;
      // this.url = this.chosen_third_party.url;
      if (this.chosen_individual.phoneNumber) {
        this.phoneNumber = extractCodeAndPhone(
            this.chosen_individual.phoneNumber
        ).phoneNumber;
        this.internationalCodePhoneNumber = findCountryByCode(
            this.countries,
            extractCodeAndPhone(this.chosen_individual.phoneNumber).countryCode
        );
      }
      if (this.chosen_individual.cellPhoneNumber) {
        this.cellPhoneNumber = extractCodeAndPhone(
            this.chosen_individual.cellPhoneNumber
        ).phoneNumber;
        this.internationalCodeCellPhoneNumber = findCountryByCode(
            this.countries,
            extractCodeAndPhone(this.chosen_individual.cellPhoneNumber)
                .countryCode
        );
      }

      this.activity = this.chosen_individual.activity;
      this.note = this.chosen_individual.note;
      this.birthDate = this.chosen_individual.birthDate;
      if (this.chosen_individual.country) {
        this.selectedCountry = {
          id: this.chosen_individual.country.id,
          name: this.chosen_individual.country.name,
        };
      }
    }
  },
  computed: {
    ...mapGetters([
      "chosen_user",
      "is_editing_individual",
      "chosen_individual",
    ]),

    phoneNumberWithCode() {
      return combineCodeAndNumber(
          this.internationalCodePhoneNumber,
          this.phoneNumber
      );
    },

    cellPhoneNumberWithCode() {
      return combineCodeAndNumber(
          this.internationalCodeCellPhoneNumber,
          this.cellPhoneNumber
      );
    },
  },
  beforeUnmount() {
    refreshEditIndividual();
  },

  watch: {
    success(newValue) {
      if (!newValue) {
        setPage("contacts");
      }
    },
  },
};
</script>
