<script>
import {requiredRule} from "@/utils/rules";
import Account_Type_List_Modal_V01 from "@/components/Modals/Account_Type_List_Modal_V01.vue";
import {
  formatCurrencyInfo,
  formatNumber,
  handleKeyDown,
  httpResponseHandler,
  messageResultHandler
} from "@/utils/helpers";
import accountService from "@/services/account.service";
import third_partyService from "@/services/third_party.service";
import {mapGetters} from "vuex";
import CurrencyService from "@/services/currency.service";
import emitter from "@/plugins/mitt";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import {toCamelCase} from "@/utils/NewFunctions/utils";


export default {
  name: "SimpleAccountForm",
  components: {Error_Modal, Success_Modal, Account_Type_List_Modal_V01},
  methods: {
    formatNumber,
    handleKeyDown,
    requiredRule() {
      return requiredRule
    },
    clearAccountType() {
      this.accountType = null;
    },
    async addAccount() {
      let {valid} = await this.$refs.add_account_form.validate();
      if (!valid) return;

      let form = {
        thirdPartyId: this.chosen_third_party.id,
        currencyId: this.selectedCurrency.id,
        accountTypeId: this.accountTypeId,
      };
      console.log(form);

      let response = messageResultHandler(await accountService.addAccount(form));
      this.message = response.message;

      if (response.success) {
        this.success = true;
        this.thirdPartyAccounts.push(response.data);
      } else {
        this.error = true;
      }
    },
    async getThirdPartyAccounts(id) {
      let response = messageResultHandler(
          await third_partyService.getThirdPartyAccounts(id)
      );
      if (response.success) {
        this.thirdPartyAccounts = response.data;
      } else {
        if (response.message !== "No Account is created for this Third Party") {
          this.error = true;
          console.log("error");
        }
      }
    },
    async getCurrencies() {
      let response = httpResponseHandler(await CurrencyService.getCurrencies());
      if (response.success) {
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
  },
  data() {
    return {
      selectedCurrency: null,
      currencies: [],
      message: null,
      error: false,
      success: false,
      thirdPartyAccounts: [],
      accountType: null,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    emitter.on("new_account_type", (value) => {
      this.accountType = value;
    });
    await this.getCurrencies();
    if (this.is_editing_third_party)
      await this.getThirdPartyAccounts(this.chosen_third_party.id);
    this.loading = false;
  },

  computed: {
    ...mapGetters(["chosen_third_party", "is_editing_third_party"]),
    accountTypeName() {
      if (this.accountType) {
        return toCamelCase(this.accountType.accountGroup) + " " + this.accountType.ledgerCode;
      } else {
        return null;
      }
    },
    accountTypeId() {
      if (this.accountType) {
        return this.accountType.id;
      } else {
        return null;
      }
    },
    thirdPartyType() {
      switch (this.chosen_third_party.thirdPartyType) {
        case "CUSTOMER":
          return "Customer"
        case "TRUSTEE":
          return "Trustee"
        case "SAFE_BOX":
          return "Safe Box"
        default:
          return null
      }
    },
  },
}
</script>

<template>
  <v-container fluid>
    <v-form class="adjust-label" ref="add_account_form" :disabled="loading">
      <v-row>
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-autocomplete
              label="Select Currency"
              v-model="selectedCurrency"
              :items="currencies"
              item-title="name"
              item-value="name"
              filled
              return-object
              :rules="requiredRule()"
          ></v-autocomplete>

          <v-text-field
              class="mt-4"
              readonly
              label="Account Type"
              v-model="accountTypeName"
              @keyup.backspace="clearAccountType"
              @keydown="handleKeyDown"
              :rules="requiredRule()"
              variant="outlined"
          ></v-text-field>
          <v-btn class="mb-5" color="button_color" @click="addAccount"
          >Add {{ this.thirdPartyType }} Account
          </v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <template v-if="thirdPartyAccounts.length === 0 && this.loading===false ">
      <v-card variant="outlined">
        <v-card-title class="text-h5 py-3 text-center">
          No Accounts Added yet.
        </v-card-title>
      </v-card>
    </template>

    <template v-if="thirdPartyAccounts && thirdPartyAccounts.length > 0">
      <v-card variant="outlined">
        <v-card-title class="text-h5 py-3 text-center">
          Accounts
        </v-card-title>
        <v-table height="300px">
          <thead>
          <tr>
            <th class="text-left">Coffer Name</th>
            <th class="text-left">Third Party Full Name</th>
            <th class="text-left">Currency</th>
            <th class="text-left">Ledger Code</th>
            <th class="text-left">In Transit Credit</th>
            <th class="text-left">In Transit Debit</th>
            <th class="text-left">Actual Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(account, index) in thirdPartyAccounts" :key="index">
            <td>{{ account.cofferName }}</td>
            <td>{{ account.thirdParty.fullName }}</td>
            <td>{{ account.currency.symbol }}</td>
            <td>{{ account.accountType.generalLedger.code }}</td>
            <td>{{ formatNumber(account.inTransitCreditAmount) }}</td>
            <td>{{ formatNumber(account.inTransitDebitAmount) }}</td>
            <td>{{ formatNumber(account.actualAmount) }}</td>
          </tr>
          </tbody>
        </v-table>
      </v-card>
    </template>

  </v-container>

  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>

  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>


  <Account_Type_List_Modal_V01
      v-if="this.selectedCurrency!=null"
      :third-party-type="chosen_third_party.thirdPartyType"
      :display="false"
      :currency-symbol="selectedCurrency.symbol"
  ></Account_Type_List_Modal_V01>

</template>

<style scoped>

</style>