<template>
  <v-sheet max-width="800" min-width="600">
    <div style="padding: 20px">
      <div class="border-1">
        <h4 class="border-h4">Safe Box Summary</h4>
        <div
          class="grid-card-2"
          ref="pdfContent"
          style="text-align: left; direction: ltr"
        >
          <p>Event:</p>
          <p class="m-l-35">{{ chosen_safe_box.id }}</p>
          <p>CofferName:</p>
          <p class="m-l-35">{{ chosen_safe_box.safeBox.cofferName }}</p>
          <p>Payer/Payee:</p>
          <p class="m-l-35">{{ chosen_safe_box.secondaryThirdPartyFullName }}</p>
          <p>Withdrawal Amt:</p>
          <p class="m-l-35">
            {{ formatNumber(chosen_safe_box.withdrawalAmount) }}
            {{ chosen_safe_box.safeBox.thirdPartyBankInfo.currency.symbol }}
          </p>
          <p>Deposit Amt:</p>
          <p class="m-l-35">
            {{ formatNumber(chosen_safe_box.depositAmount) }}
            {{ chosen_safe_box.safeBox.thirdPartyBankInfo.currency.symbol }}
          </p>
          <p>Create Date:</p>
          <p class="m-l-35">
            {{
              new Date(chosen_safe_box.createDate)
                .toISOString()
                .split("T")[0] || null
            }}
          </p>
        </div>
        <v-row justify="end">
          <v-btn class="mr-4 top-20" color="button_color" @click="generatePDF"
            >PDF</v-btn
          >
        </v-row>
      </div>
    </div>
  </v-sheet>

  <Error_Modal
    :error="error"
    :message="message"
    @closeErrorModal="error = false"
  ></Error_Modal>
</template>
<script>
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import {formatNumber} from "@/utils/helpers.js";

export default {
  name: "request_modal_v01",
  components: {
    Error_Modal,
  },
  data() {
    return {
      request: null,
      currency: null,
      customer: null,
      amount: null,
      createDate: null,
      modifiedDate: null,
      status: null,
      requestType: null,
      trusteeCommissionAmount: null,
      trusteeCommissionPercent: null,
      ellingtonCommissionPercent: null,
      ellingtonCommissionAmount: null,
      message: null,
      error: false,
    };
  },
  methods: {
    formatNumber,
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: "safeboxSummary.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf(element, options);
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["chosen_safe_box", "page"]),
  },
};
</script>
