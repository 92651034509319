<template>
  <div>
    <v-container fluid style="background-color: white; width: 600px">
      <v-form class="adjust-label" ref="add_account_type_form">
        <v-row>
          <v-col cols="12" md="12" sm="12" xs="12">
            <v-text-field
              label="Description"
              v-model="description"
            ></v-text-field>
            <v-autocomplete
              label="Select Ledger Direction"
              v-model="selectedLedgerDirection"
              :items="directions"
              item-title="name"
              item-value="id"
              filled
              return-object
              :rules="requiredRule"
            ></v-autocomplete>
            <v-autocomplete
              label="Select Account Direction"
              v-model="selectedAccountDirection"
              :items="directions"
              item-title="name"
              item-value="id"
              filled
              return-object
            ></v-autocomplete>
            <v-tab
              @click="generalLedgerListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select</v-tab
            >
            <v-text-field
              label="General Ledger"
              v-model="generalLedgerCode"
              @keyup.backspace="clearGeneralLedger"
              @keydown="handleKeyDown"
              :rules="requiredRule"
            ></v-text-field>
            <!-- <v-autocomplete
              label="Update Account"
              v-model="selectedValue"
              :items="trueFalseOptions"
              item-title="name"
              item-value="id"
              filled
              return-object
              :rules="requiredRule"
            ></v-autocomplete> -->
          </v-col>
        </v-row>
        <v-row justify="end">
          <div class="d-flex top-20">
            <v-btn
              v-if="!is_editing_transaction_type"
              class="ml-4"
              color="button_color"
              @click="addTransactionType"
            >
              Create Transaction Type
            </v-btn>
            <v-btn
              class="mr-4"
              v-else
              @click="addTransactionType"
              color="button_color"
              >Edit Account Type</v-btn
            >
          </div>
        </v-row>
      </v-form>
      <!-- <v-dialog @click="success = false" v-model="success" width="auto">
        <v-alert type="success"> {{ message }} </v-alert>
      </v-dialog>
      <v-dialog v-model="error" width="auto">
        <v-alert @click="error = false" type="error"> {{ message }} </v-alert>
      </v-dialog> -->
      <v-dialog v-model="success">
        <div class="success-dialog">
          <div class="success-left">
            <div class="green-circle">
              <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 100 L80 130 L150 50" stroke="white" stroke-width="25" fill="transparent" class="success-tick" />
          </svg>
        </div>      
          </div>
          <div class="success-right">
          <span class="close-icon" @click="success = false" style="text-align: right;">✖</span>
          <h4>Success</h4>
        <p class="success-message"> {{ message }}</p>
        <button class="success-close-button" @click="success = false">Close</button>
          </div>
        </div>
    </v-dialog>
    
    <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>      
      </div>
      <div class="error-right">
      <span class="close-icon" @click="error = false" style="text-align: right;">✖</span>
      <h4>Error</h4>
    <p class="error-message">{{ message }}</p>
    <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
</v-dialog>
      <v-dialog v-model="generalLedgerListModal" width="auto">
        <General_Ledger_List_Modal_V01></General_Ledger_List_Modal_V01>
      </v-dialog>
    </v-container>
  </div>
</template>
<script>
import {
  messageResultHandler,
  handleKeyDown,
  refreshEditTransactionType,
} from "@/utils/helpers";
import { requiredRule } from "@/utils/rules";
import General_Ledger_List_Modal_V01 from "../Modals/General_Ledger_List_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import transactionService from "@/services/transaction.service";
import { mapGetters } from "vuex";
import { findIndexWithName } from "@/utils/helpers";

export default {
  name: "new_transaction_type_modal_v01",
  components: {
    General_Ledger_List_Modal_V01,
  },
  data() {
    return {
      error: false,
      success: false,
      message: null,
      generalLedger: null,
      generalLedgerListModal: false,
      codeGeneralLedger: null,
      selectedLedgerDirection: null,
      selectedAccountDirection: null,
      selectedValue: null,
      // trueFalseOptions:[
      //   { name: 'True', value: true },
      //   { name: 'False', value: false },
      // ],
      directions: [
        { name: "CREDIT", id: 0 },
        { name: "DEBIT", id: 1 },
      ],
      description: null,
      requiredRule: requiredRule,
    };
  },
  methods: {
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearGeneralLedger() {
      this.generalLedger = null;
      this.codeGeneralLedger = null;
    },
    async addTransactionType() {
      let { valid } = await this.$refs.add_account_type_form.validate();
      let response = null;
      let form = null;
      if (valid) {
        if (!this.is_editing_transaction_type) {
          form = {
            id: this.id,
            description: this.description,
            ledgerDirection: this.selectedLedgerDirection.id,
            accountDirection: this.selectedAccountDirection ? this.selectedAccountDirection.id : null,
            generalLedgerId: this.generalLedgerId,
          };
          console.log("sending form",form);
          response = messageResultHandler(
            await transactionService.addTransactionType(form)
          );
        } else {
          form = {
            id: this.id,
            description: this.description,
            ledgerDirection: this.selectedLedgerDirection.id,
            accountDirection: this.selectedAccountDirection ? this.selectedAccountDirection.id : null,
            generalLedgerId: this.generalLedgerId,
          };
          console.log("sending new form (edit)", form);
          response = messageResultHandler(
            await transactionService.editTransactionType(form)
          );
          console.log(form);
        }

        this.message = response.message;
        if (response.success) {
          emitter.emit("new_transaction_type", response);
        } else {
          this.error = true;
        }
      }
    },
    clearThirdParty() {
      this.thirdParty = null;
    },
  },
  async mounted() {
    emitter.on("new_general_ledger", (value) => {
      this.generalLedger = value;
      this.generalLedgerListModal = false;
    });
    if (this.is_editing_transaction_type) {
      this.id = this.chosen_transaction_type.id;
      this.description = this.chosen_transaction_type.description;
      // this.selectedValue = this.chosen_transaction_type.updateAccount;
      this.selectedLedgerDirection =
        this.directions[
          findIndexWithName(
            this.directions,
            this.chosen_transaction_type.ledgerDirection
          )
        ];
      this.selectedAccountDirection =
        this.directions[
          findIndexWithName(
            this.directions,
            this.chosen_transaction_type.accountDirection
          )
        ];
      this.generalLedger = this.chosen_transaction_type.generalLedger;

      console.log("backend response for edit",this.chosen_transaction_type);
    }
  },
  computed: {
    ...mapGetters([
      "chosen_user",
      "chosen_transaction_type",
      "is_editing_transaction_type",
    ]),
    generalLedgerCode() {
      if (this.generalLedger) {
        return this.generalLedger.code;
      } else if (this.codeGeneralLedger) {
        return this.codeGeneralLedger;
      } else {
        return null;
      }
    },
    generalLedgerId() {
      if (this.generalLedger) {
        return this.generalLedger.id;
      } else if (this.codeGeneralLedger) {
        return this.codeGeneralLedger;
      } else {
        return null;
      }
    },
    // directionId(){
    //   if(this.chosen_transaction_type.direction == 'CREDIT'){
    //     return 0;
    //   }else if(this.chosen_transaction_type.direction == 'DEBIT'){
    //     return 1;
    //   }else {
    //     return null;
    //   }
    // }
  },
  beforeUnmount() {
    refreshEditTransactionType();
    this.generalLedgerListModal = false;
  },
};
</script>
