<template>
  <v-icon @click="toggleTheme">mdi-moon-waning-crescent</v-icon>
</template>

<script>
import { useTheme } from "vuetify";

export default {
  setup() {
    const theme = useTheme();

    return {
      theme,
      toggleTheme: () =>
        (theme.global.name.value = theme.global.current.value.dark_mode
          ? "light_mode"
          : "dark_mode"),
    };
  },
};
</script>
